import React, {
  useCallback, useEffect,
  useMemo,
  useState
} from 'react';
import { useHistory } from 'react-router-dom';
import Page from '../../components/page/Page';
import { useTranslation, withTranslation } from 'react-i18next';
import QuestionnaireDefinitionService from '../../services/admin/QuestionnaireDefinitionService';
import './AdminQuestionnaireDefinitionCreatePage.css';
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Input,
  Loader, Icon, Message
} from 'semantic-ui-react';
import LanguageSelectionGeneric from '../../components/languageselection/LanguageSelectionGeneric';
import { getTranslationForLanguageAndDefinition } from '../../services/admin/AdminTranslationService';
import { getContentDefinitions, getDataDefinitions, getEventDefinitions, getProDefinitions } from '../../redux/questionnaires/questionnaireDefinitionsSlice';
import { compose } from 'redux';
import { connect } from 'react-redux';

import getMatchFromCode from '../../components/admin/questionnaireEditor/helpers/getMatchFromCode';
import ConfirmButtonWithFeedback from '../../components/dashboard/ConfirmButtonWithFeedback';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import SimpleTranslationTableRow from './components/translation/TranslationEditRow';
import LocalStorageHelper from '../../helpers/LocalStorageHelper';
import StaffPermissionService from '../../services/StaffPermissionService';
import withPermissionWrapper from '../../security/withPermissionWrapper';

const getSortByQuestionOrder = (questions) => (a, b) => {
  const indexOfA = questions.findIndex(q => q.code === a.code);
  const indexOfB = questions.findIndex(q => q.code === b.code);
  return indexOfA - indexOfB;
};

const TYPES = ['label', 'config', 'notificationTitle', 'notificationMessage', 'answers', 'url', 'videoUrl', 'videoPoster', 'height', 'width', 'validation-required', 'validation-format', 'validation-min', 'validation-max'];
const createGroupedTranslationsByQuestionCode = (translations, focusedQuestions) => {
  const returnValue = [];
  const translationQuestionCodeRegex = /questionnaire_(.+)_questions_(.+)_(.+)/;

  const shouldOnlySortFocusedQuestions = focusedQuestions && Array.isArray(focusedQuestions) && focusedQuestions.length > 0;
  if (shouldOnlySortFocusedQuestions) {
    focusedQuestions.forEach(fQ => returnValue.push({ code: fQ, translations: [] }));
  }

  translations.forEach(t => {
    let questionCode = getMatchFromCode(translationQuestionCodeRegex, t.code, 2)?.split('_')[0];
    if (!questionCode) questionCode = 'QUESTIONNAIRE';

    const index = returnValue.findIndex(rV => rV?.code === questionCode);
    if (index === -1) {
      if (!shouldOnlySortFocusedQuestions) {
        returnValue.push({ code: questionCode, translations: [t] });
      }
    } else {
      returnValue[index].translations.push(t);
    }
  });

  return returnValue;
};

function AdminSimpleTranslationsPage({ definitions }) {
  const { t } = useTranslation();
  const { questionnaireDefinitionCode } = useParams();
  const history = useHistory();

  const baseLanguage = 'en_GB';
  const [selectedLanguage, setSelectedLanguage] = useState('en_GB');
  const questionnaireDefinition = useMemo(() => {
    return definitions.find(d => d.code === questionnaireDefinitionCode);
  }, [definitions, questionnaireDefinitionCode]);

  const handleBack = () => {
    history.goBack();
  };

  const [errorObject, setErrorObject] = useState(null);

  const [baseTranslations, setBaseTranslations] = useState(null);
  const [editingTranslations, setEditingTranslations] = useState(null);
  const [changedTranslations, setChangedTranslations] = useState([]);
  const combinedTranslations = useMemo(() => {
    if (!baseTranslations) return null;
    if (!editingTranslations) return null;

    const changeObject = {};
    changedTranslations.forEach(cT => {
      changeObject[cT.code] ? changeObject[cT.code].push(cT) : changeObject[cT.code] = [cT];
    });
    return baseTranslations.map((base) => {
      return {
        code: base.code,
        base,
        editing: editingTranslations.find(e => e.code === base.code),
        changed: changeObject[base.code] || []
      };
    });
  }, [baseTranslations, editingTranslations, changedTranslations]);

  // Translation searching should be done when the language or search changes so to get this to happen
  // automatically searchTerm has been split into a fast and slow updating variable.
  const [searchTermStore, setSearchTermStore] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearchChange = (_e, v) => {
    setSearchTermStore(v.value);
  };
  const handleSearchRequest = () => {
    setSearchTerm(searchTermStore);
  };

  const [showAdvancedView, setShowAdvancedView] = useState(
    LocalStorageHelper.getBoolean('showAdvancedView') || false
  );
  useEffect(() => {
    LocalStorageHelper.setBoolean('showAdvancedView', showAdvancedView);
  }, [showAdvancedView]);
  const [showSavedState, setShowSavedState] = useState(false);

  // There is a defaultTranslation in the translation that comes back.
  // This may be good if there are missing languages, or if base needs to change at some point
  const definitionCode = questionnaireDefinition?.code;
  const fetchBaseTranslations = useCallback(async () => {
    if (!definitionCode) return [];
    const translations = await getTranslationForLanguageAndDefinition(
      baseLanguage,
      definitionCode,
      ''
    );

    setBaseTranslations(translations);
  }, [definitionCode]);

  const fetchTranslations = useCallback(async () => {
    if (!definitionCode) return [];
    const translations = await getTranslationForLanguageAndDefinition(
      selectedLanguage,
      definitionCode,
      ''
    );

    setEditingTranslations(translations);
  }, [selectedLanguage, definitionCode]);

  const refreshTranslations = useCallback(async () => {
    await setEditingTranslations(null);
    fetchTranslations();
  }, [fetchTranslations]);
  useEffect(() => {
    fetchBaseTranslations();
    refreshTranslations();
  }, [refreshTranslations, fetchBaseTranslations]);

  // Sorting could be done in place or by creating a new array and setting.
  // Make sure that any sorting, past the initial in place sort, sets a new variable
  // in order to prompt useEffect to trigger.
  const definitionQuestions = questionnaireDefinition?.questions;
  const groupedCombinedTranslations = useMemo(() => {
    if (!definitionQuestions) return [];
    if (!combinedTranslations) return [];
    const groupedTranslations = createGroupedTranslationsByQuestionCode(combinedTranslations, []);
    groupedTranslations.sort(getSortByQuestionOrder(definitionQuestions));
    return groupedTranslations;
  }, [combinedTranslations, definitionQuestions]);

  const updateTranslation = useCallback((newTranslationObject) => {
    setChangedTranslations(changedTranslations => {
      const newChangedTranslationArray = [...changedTranslations];

      // Replace or add in the changed translation array
      const presentChangedTranslationIndex = changedTranslations.findIndex(tO => {
        return tO.language === newTranslationObject.language && tO.code === newTranslationObject.code;
      });
      if (presentChangedTranslationIndex === -1) {
        newChangedTranslationArray.push(newTranslationObject);
      } else {
        newChangedTranslationArray.splice(presentChangedTranslationIndex, 1, newTranslationObject);
      }
      return newChangedTranslationArray;
    });
  }, []);

  const handleConfirm = useCallback(async (reason) => {
    await setErrorObject(null);

    try {
      const result = await QuestionnaireDefinitionService.submitTranslationChanges(changedTranslations, reason);
      setErrorObject(result);
      setShowSavedState(Object.keys(result?.errors).length === 0);
    } catch (e) {
      setErrorObject(e);
    }
    setTimeout(() => { setShowSavedState(false); }, [2000]);
  }, [changedTranslations]);

  const hasLoadedTranslations = useMemo(() => {
    if (!questionnaireDefinition) return true;
    return combinedTranslations !== null;
  }, [combinedTranslations, questionnaireDefinition]);

  // Used for alternating background, should not be stateful.
  let displayCount = 0;

  // Used for text area row approximation
  const approxCharPerLine = window.innerWidth / 20;

  if (!questionnaireDefinition) handleBack();
  if (!hasLoadedTranslations) return <Loader active />;

  return (
    <Page
      name='Edit Questionnaire Translations - User'
      header={t(
        'USER_TRANSLATION_EDIT_HEADER',
        'Translation Editor'
      )}
    >
      <Form>
        <div style={{ display: 'flex', paddingBottom: '1rem', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'baseline', padding: '4px', paddingRight: '40px', fontSize: '18px', cursor: 'pointer' }} onClick={handleBack}>
            <Icon style={{ color: '#f8991d' }} name='arrow left' />
            <p style={{ fontSize: '16px', fontWeight: 700 }}>{t('GLOBAL_BACK')}</p>
          </div>
          <div style={{ paddingRight: '8px' }}>
            <LanguageSelectionGeneric
              inline
              useMobileLanguages
              language={selectedLanguage}
              callback={setSelectedLanguage}
            />
          </div>

          <Input
            fluid value={searchTermStore} onChange={handleSearchChange}
            style={{ marginRight: '0.5rem', flexGrow: 1 }}
          />
          <Button primary onClick={handleSearchRequest}>{t('TRANSLATION_EDIT_SEARCH', 'Search')}</Button>
          <ConfirmButtonWithFeedback
            buttonText={showSavedState ? t('GLOBAL_SAVED_BUTTON', 'Saved') : t('GLOBAL_SAVE_BUTTON', 'Save')}
            headerText={t('ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_PLACEHOLDER_TEXT', 'Submit Questionnaire and Translation Changes')}
            contentText={t(
              'ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_PROMPT',
              'Please give a reason why this is being changed and confirm.'
            )}
            confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
            cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
            onConfirm={handleConfirm}
            placeholderText={t(
              'ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_PLACEHOLDER_TEXT',
              'Reason'
            )}
            mandatoryValidationText={t(
              'ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_VALIDATION_TEXT',
              'Please supply a reason for the change.'
            )}
            color={showSavedState ? 'yellow' : 'primary'}
          />
        </div>
        {errorObject?.errors?.TranslationArray?.length > 0 && (
          <Message
            error
            visible
            content={t('TRANSLATION_ERROR_MESSAGE', 'Error Submitting changes, please contact support.')}
            list={errorObject?.errors?.TranslationArray}
          />
        )}
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Checkbox checked={showAdvancedView} onChange={() => setShowAdvancedView(!showAdvancedView)} /> {t('TRANSLATION_ADVANCED_VIEW', 'Show Advanced View?')}
            </Grid.Column>
            <Grid.Column width={8}><b>{t('SIMPLE_TRANSLATION_EDITOR_TTRANSLATION', 'Translation')}</b>{" " + selectedLanguage}</Grid.Column>
          </Grid.Row>
          {hasLoadedTranslations && groupedCombinedTranslations.map((gT) => gT.translations.sort((a, b) => {
            let regex = /questionnaire_(.+)_questions_(.+)_(.+)/;
            let groupIndex = 3;
            if (gT.code === 'QUESTIONNAIRE') {
              regex = /questionnaire_(.+)_(.+)/;
              groupIndex = 2;
            }
            const typeA = getMatchFromCode(regex, a.code, groupIndex);
            const typeB = getMatchFromCode(regex, b.code, groupIndex);

            return TYPES.findIndex(type => type === typeA) - TYPES.findIndex(type => type === typeB);
          }).map((t) => {
            if (!showAdvancedView && t.base.translation.length === 0) return null;
            if (t.code.slice(-6) === 'config') return null;

            let regex = /questionnaire_(.+)_questions_(.+)_(.+)/;
            let groupIndex = 3;
            if (gT.code === 'QUESTIONNAIRE') {
              regex = /questionnaire_(.+)_(.+)/;
              groupIndex = 2;
            }
            const questionCode = getMatchFromCode(regex, t.code, groupIndex - 1);
            const questionType = getMatchFromCode(regex, t.code, groupIndex);

            const existingEdit = t.changed.find(c => c.language === selectedLanguage);
            const editingTranslation = existingEdit || t.editing;

            if (searchTerm.length !== 0) {
              const isInKey = showAdvancedView ? t.code.toLowerCase().indexOf(searchTerm.toLowerCase()) : -1;
              const isInBase = t.base.translation.toLowerCase().indexOf(searchTerm.toLowerCase());
              const isInTranslation = editingTranslation.translation.toLowerCase().indexOf(searchTerm.toLowerCase());
              const isFound = isInKey + isInBase + isInTranslation > -3;

              if (!isFound) return null;
            }

            const i = displayCount++;
            return (
              <SimpleTranslationTableRow
                key={t.code}
                i={i}
                baseTranslation={t.base.translation}
                editingTranslation={editingTranslation}
                approxCharPerLine={approxCharPerLine}
                questionCode={questionCode}
                questionType={questionType}
                showEmptyBase={showAdvancedView}
                updateTranslation={updateTranslation}
              />
            );
          }))}
        </Grid>
      </Form>
    </Page>
  );
}

const mapStateToProps = (state) => {
  return {
    definitions: [
      ...getContentDefinitions(state),
      ...getDataDefinitions(state),
      ...getProDefinitions(state),
      ...getEventDefinitions(state)
    ]
  };
};

const withEnhancements = (options) => compose(
  withPermissionWrapper(options),
  withTranslation(),
  connect(mapStateToProps)
);
export default withEnhancements({ permissionFunctionDelegate: StaffPermissionService.canManageTranslations })(AdminSimpleTranslationsPage);
