import React, {Fragment, useMemo, memo} from "react";
import AccordianReveal from "../../generic/AccordianReveal";
import TranslationDisplay from "./TranslationDisplay";
import {useTranslation} from "react-i18next";

const DISPLAY_TYPES = ["label", "config"]
const HIDDEN_TYPES = ["notificationTitle", "notificationMessage", "answers",  "url", "videoUrl", "videoPoster", "height", "width", "validation"]

const getMatch = (regex, searchString, groupIndex) => {
  const matches = searchString.match(regex)
  if(!matches) return null

  if(matches.length > groupIndex && matches[groupIndex].length > 1){
    return matches[groupIndex]
  }

  return null;
}

function TranslationGroupDisplay({ translationGroupObject, isSearching, dispatch, blockNavigation}) {
  const {t} = useTranslation();

  const displayedTranslations = useMemo(()=>{
    const returnValue = {
      DISPLAY_TYPES: [],
      HIDDEN_TYPES: [],
      ANSWER_TYPES: []
    };

    if(!translationGroupObject.translations) return returnValue;
    let regex = /questionnaire_(.+)_questions_(.+)_(.+)/
    let groupIndex = 3
    if(translationGroupObject.code === "QUESTIONNAIRE"){
      regex = /questionnaire_(.+)_(.+)/
      groupIndex = 2
    }

    translationGroupObject.translations.forEach((t)=>{
      const answerCode = getMatch(regex, t.code, groupIndex - 1)?.split("_")[1];
      const type = getMatch(regex, t.code, groupIndex);

      if (HIDDEN_TYPES.includes(type)) {
        returnValue.HIDDEN_TYPES.push(t)
      }  else if (answerCode) {
        returnValue.ANSWER_TYPES.push(t)
      } else if (DISPLAY_TYPES.includes(type)) {
        returnValue.DISPLAY_TYPES.push(t)
      } else {
        returnValue.HIDDEN_TYPES.push(t)
      }
    })

    returnValue.DISPLAY_TYPES.sort((a,b)=>{
      const typeA = getMatch(regex, a.code, groupIndex);
      const typeB = getMatch(regex, b.code, groupIndex);
      return DISPLAY_TYPES.findIndex(type=> type === typeA) - DISPLAY_TYPES.findIndex(type=> type === typeB)
    })

    returnValue.HIDDEN_TYPES.sort((a,b)=>{
      const typeA = getMatch(regex, a.code, groupIndex);
      const typeB = getMatch(regex, b.code, groupIndex);
      return HIDDEN_TYPES.findIndex(type=> type === typeA) - HIDDEN_TYPES.findIndex(type=> type === typeB)
    })

    return returnValue;
  }, [translationGroupObject.code, translationGroupObject.translations])

  const isEmpty = useMemo(()=>{
    return displayedTranslations.DISPLAY_TYPES.length === 0 &&
      displayedTranslations.HIDDEN_TYPES.length === 0 &&
      displayedTranslations.ANSWER_TYPES.length === 0
  }, [displayedTranslations.ANSWER_TYPES.length, displayedTranslations.DISPLAY_TYPES.length, displayedTranslations.HIDDEN_TYPES.length])

  const shouldNestHidden = useMemo(()=>{
    if(isSearching) return false
    if (displayedTranslations.DISPLAY_TYPES.length === 0) return false
    if (displayedTranslations.HIDDEN_TYPES.length === 0) return false
    return true
  },[displayedTranslations.DISPLAY_TYPES.length, displayedTranslations.HIDDEN_TYPES.length, isSearching])

  const shouldNestAnswers = useMemo(()=>{
    if(isSearching) return false
    if (displayedTranslations.HIDDEN_TYPES.length === 0) return false
    if (displayedTranslations.ANSWER_TYPES.length < 3) return false
    return true
  },[displayedTranslations.ANSWER_TYPES.length, displayedTranslations.HIDDEN_TYPES.length, isSearching])

  const answerDisplay = useMemo(()=>{
    const Wrapper = shouldNestAnswers ? AccordianReveal : Fragment;
    const props = shouldNestAnswers ? {
      displayText: t("TRANSLATION_EDIT_ANSWERS", "Answers"),
      noPadding: true,
    } : {};
    return <Wrapper {...props}>
      {displayedTranslations.ANSWER_TYPES.map(t=><TranslationDisplay key={'translation-'+t.code} translationObject={t} dispatch={dispatch} blockNavigation={blockNavigation} />)}
    </Wrapper>
  }, [dispatch, displayedTranslations.ANSWER_TYPES, shouldNestAnswers, t, blockNavigation])

  const hiddenDisplay = useMemo(()=>{
    const Wrapper = shouldNestHidden ? AccordianReveal : Fragment;
    const props = shouldNestHidden ? {
      displayText: t("TRANSLATION_GROUP_EDIT_SHOW", "Show More"),
      noPadding: true,
    } : {};
    return <Wrapper {...props}>
      {displayedTranslations.HIDDEN_TYPES.map(t=><TranslationDisplay key={'translation-'+t.code} translationObject={t} dispatch={dispatch} blockNavigation={blockNavigation} />)}
      {answerDisplay}
    </Wrapper>
  }, [answerDisplay, dispatch, displayedTranslations.HIDDEN_TYPES, shouldNestHidden, t, blockNavigation])

  const questionDisplay = useMemo(()=>{
    return <div style={{paddingBottom: isSearching ? undefined : '3rem'}}>
      {displayedTranslations.DISPLAY_TYPES.map(t=><TranslationDisplay key={'translation-'+t.code} translationObject={t} dispatch={dispatch} blockNavigation={blockNavigation} />)}
      {hiddenDisplay}
    </div>
  }, [dispatch, displayedTranslations.DISPLAY_TYPES, hiddenDisplay, isSearching, blockNavigation])

  if(isEmpty) return null;

  return (
      questionDisplay
  );
}

export default memo(TranslationGroupDisplay, (prevProps, nextProps)=>{
  if(prevProps.isSearching !== nextProps.isSearching) return false;
  return JSON.stringify(prevProps.translationGroupObject) === JSON.stringify(nextProps.translationGroupObject)
});