import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Page from "../../components/page/Page";
import {Loader, Message, Form, Button} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import InternationalisationService from "../../InternationalisationService";
import LookupService from "../../services/LookupService";
import LookupEditorComponent from "../../components/admin/lookupTool/LookupEditorComponent";
import LookupItemDisplay from "../../components/admin/lookupTool/LookupItemDisplay";
import withPermissionWrapper from '../../security/withPermissionWrapper';
import StaffPermissionService from '../../services/StaffPermissionService';
import { compose } from 'redux';

const AdminLookupTool = () => {
  const {t} = useTranslation();

  // fetch lookup information
  const [lookupArray, setLookupArray] = useState(null);
  const [searchQuery, setSearchQuery] = useState()
  const [language, setLanguage] = useState(InternationalisationService.getLanguage());
  const fetchLookupForLanguage = useCallback(async () => {
    setLookupArray(null)
    const lookupArray = await LookupService.getLookupListByLanguage(language);
    setLookupArray(lookupArray);
  }, [language])
  useEffect(() => {
    fetchLookupForLanguage()
  }, [fetchLookupForLanguage])
  const hasFetchedLookupData = useMemo(() => {
    return lookupArray !== null;
  }, [lookupArray]);

  // get selected lookup
  const [selectedLookup, setSelectedLookup] = useState(null);
  const handleLookupSelection = lookup => setSelectedLookup(lookup);
  const hasSelectedLookup = useMemo(() => {
    return selectedLookup !== null
  }, [selectedLookup]);
  useEffect(()=>{
    if(!hasFetchedLookupData) return;
    if(!lookupArray[0]) return;
    if(selectedLookup === null) {
      setSelectedLookup(lookupArray[0])
      return;
    }
    const foundLookup = lookupArray.find(lookup => lookup.code === selectedLookup.code);
    if(!foundLookup) setSelectedLookup(lookupArray[0])
    if(foundLookup && foundLookup !== selectedLookup) setSelectedLookup(foundLookup)
  }, [hasFetchedLookupData, lookupArray, selectedLookup])

  const [lookupItems, setLookupItems] = useState(null)
  const getLookupItems = useCallback(async () => {
    await setLookupItems(null);
    if (selectedLookup == null) {
      return;
    }
    const selectedLookupItemArray = await LookupService.getLookupItemsByLanguageAndLookupCode(language, selectedLookup?.code, searchQuery);
    setLookupItems(selectedLookupItemArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[language, selectedLookup])
  useEffect(()=>{
    getLookupItems();
  }, [getLookupItems, language, selectedLookup])
  const hasFetchedLookupItemData = useMemo(() => {
    return lookupItems !== null;
  }, [lookupItems]);

  return <Page
      name="Admin Lookup Tool"
      header={t("ADMIN_LOOKUP_HEADER", "Admin Lookup Tool")}
  >
    {!hasFetchedLookupData && hasFetchedLookupItemData && <Loader active={!hasFetchedLookupData}/>}
    {hasSelectedLookup && hasFetchedLookupData &&
      <LookupEditorComponent
          lookupArray={lookupArray}
          lookup={selectedLookup}
          language={language}
          setLanguage={setLanguage}
          refreshLookups={fetchLookupForLanguage}
          onLookupChange={handleLookupSelection}
      />
    }

    {hasSelectedLookup && hasFetchedLookupItemData ?
       <>
         {lookupItems?.length === 50 && (
             <Message
                 header={t("GLOBAL_ERROR_LIMIT", "Limit Reached")}
                 content={t("ADMIN_UTILS_LOOKUP_LIMIT", "Lookup Item limit reached, narrow the search or download the dataset")}
             />
         )}
         <Form onSubmit={()=>{}} style={{maxWidth:"300px", paddingLeft:"40px"}}>
           <Form.Field >
             <label>{t('SEARCH_QUERY', 'Search Query')}</label>
             <input

                 name="query"
                 value={searchQuery}
                 onChange={(e) => {
                   e.preventDefault();
                   setSearchQuery(e.target.value);
                 }}
             />
           </Form.Field>
           <Button primary onClick={()=>{fetchLookupForLanguage()}}>{t("SEARCH_BUTTON", "Search")}</Button>
         </Form>
         <LookupItemDisplay
             lookup={selectedLookup}
             lookupItemArray={lookupItems}
             language={language}
             refreshLookups={fetchLookupForLanguage}
         />
       </> :
      <Loader active />
    }
  </Page>
}

const withEnhancements = (options) => compose(
  withPermissionWrapper(options)
);
export default withEnhancements({ permissionFunctionDelegate: StaffPermissionService.canManageLookups })(AdminLookupTool);
