import { createElement } from 'react';
import GeneralHelpers from '../helpers/GeneralHelpers';
import PermissionWrapper from './PermissionWrapper';
import { Redirect } from 'react-router-dom';
import { Loader } from "semantic-ui-react";

const withPermissionWrapper = (options) =>
  function Extend(WrappedComponent) {
    function WithPermissionControl({ ...rest }) {
      const combinedOptions = {
        ...options,
        componentForUnknown: <Loader active inline={"centered"} />,
        componentForDenied: <Redirect to='/' />
      }
      return (
        <PermissionWrapper options={combinedOptions}>
          {createElement(WrappedComponent, rest)}
        </PermissionWrapper >
      );
    }
    WithPermissionControl.displayName = `withPermissionWrapper(${GeneralHelpers.getDisplayName(WrappedComponent)})`;

    return WithPermissionControl;
  };

export default withPermissionWrapper;
