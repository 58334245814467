import React, { useState, useEffect, useContext, useMemo, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { Button, Icon, Label, Table } from "semantic-ui-react";
import StaffService from "../../StaffService";
import TrialService from "../../TrialService";
import { Link } from "react-router-dom";
import Page from "../../components/page/Page";
import ConfigContext from "../../context/ConfigContext";
import StaffListFilter from "./StaffListFilter";
import StaffCard from "./StaffCard";
import StaffSubjectLinkCard from "./StaffSubjectLinkCard"
import { usePapaParse } from "react-papaparse";
import { saveAs } from "file-saver";
import AuthService from "../../services/AuthService";
import DateTimeService from "../../services/DateTimeService";
import AparitoSwitch from "../../components/questionnaire/AparitoSwitch";
import NameAndEmailSearch from "./NameAndEmailSearch";
import GroupAndRoleSearch from "./GroupAndRoleSearch";
import ConfirmButtonWithFeedback from "../../components/dashboard/ConfirmButtonWithFeedback";
import { typeHelper } from "atom5-branching-questionnaire";
import GroupService from "../../services/GroupService";
import GroupPermission from "../../GroupPermission";
import PermissionsService from "../../services/PermissionsService";

const StaffListPage = (props) => {
  const {t, history} = props;

  const config = useContext(ConfigContext);

  const [isLoading, setIsLoading] = useState(true);
  const [staff, setStaff] = useState([]);
  const [hasEmailResent, setHasEmailResent] = useState([]);

  const [staffDropdownFilter, setStaffDropdownFilter] = useState('ACTIVE');
  const [expandedStaffRows, setExpandedStaffRows] = useState([]);
  const [allExpanded, setAllExpanded] = useState(false);
  const [groupLabels, setGroupLabels] = useState();
  const [roleLabels, setRoleLabels] = useState([]);
  const [showAdmin, setShowAdmin] = useState(false);
  const [canManageStaff, setCanManageStaff] = useState(false);

  const [myProfile, setMyProfile] = useState();

  const [filteredStaffList, setFilteredStaffList] = useState([]);
  const [initialFilterForGroups, setInitialFilterForGroups] = useState([]);

  const [showIsSuperAdmin, setShowIsSuperAdmin] = useState(false);

  const [blinkLinkCard, setBlinkLinkCard] = useState(false);

  const showDrugManager = config?.ui?.showDrugManager
      ? typeHelper.parseBool(config?.ui?.showDrugManager)
      : true;

  const {jsonToCSV} = usePapaParse();

  const [linkedButtonsText, setLinkedButtonsText] = useState([]);
  const [linkedButtonsIcons, setLinkedButtonsIcons] = useState([]);

  const TABLE_COL_FIXED_COUNT = 8; // Without optional ones(e.g. Super Admin / Drug Manager)

  const [displayOnStaffListPage, setDisplayOnStaffListPage] = useState(false);
  const [staffToSubjectLinkingEnabled, setStaffToSubjectLinkingEnabled] = useState(true);

  const initialise = async () => {
    try {
      setIsLoading(true);

      const isSuperAdmin = await AuthService.getIsSuperAdmin();
      setShowIsSuperAdmin(isSuperAdmin);

      const staffToSubjectLinking = await StaffService.isStaffToSubjectLinkingEnabled();
      setStaffToSubjectLinkingEnabled(typeHelper.parseBool(staffToSubjectLinking.enabled));

      const displayOnStaffListPageVal = await StaffService.isDisplayStaffToSubjectLinkingOnStaffListPageEnabled()
      setDisplayOnStaffListPage(typeHelper.parseBool(displayOnStaffListPageVal.enabled));

      let staffData = await StaffService.listStaff();

      let newHasEmailResent = Array(staffData.length).fill(false);
      const profile = await AuthService.getMyProfile();
      setMyProfile(profile);
      const groupsWithCreateStaffPermission =
        GroupService.getGroupsWithPermissionFromProfile(profile,GroupPermission.CREATE_STAFF);
      if (profile.superAdmin || groupsWithCreateStaffPermission.length > 0) {
        setCanManageStaff(true);
      }
      let filteredStaffList = filterStaffListGroupsBasedOnLoggedInUsersGroups(staffData, profile);
      if (!profile.superAdmin) {
        filteredStaffList = removeUsersWithNoMatchingGroups(filteredStaffList);
      }
      setStaff(filteredStaffList);
      const initialList = filteredStaffList.filter(s => {
        return s.state === staffDropdownFilter
            && (showAdmin ? true : s.superAdmin === false);
      });
      populateLinkButtons(initialList);
      setInitialFilterForGroups(initialList);
      setHasEmailResent(newHasEmailResent);
      populateLabels();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initialise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateLabels = () => {
    TrialService.getGroups().then((allGroups) => {
      const newGroupLabels = {};
      for (const group of allGroups) {
        newGroupLabels[group.code] = {
          id: group.id,
          label: group.label
        };
        //update role labels, these are used for searching
        addRoleLabelsForGroup(group.code);
      }
      setGroupLabels(newGroupLabels);
    });
  };

  const addRoleLabelsForGroup = (groupCode) => {
    const newRoleLabels = roleLabels;
    StaffService.getRolesForStaffCreation(groupCode).then((availableRoles) => {
      for (const role of availableRoles) {
        newRoleLabels[role.code] = role.label;
      }
      if (Object.keys(newRoleLabels).length !== 0) {
        setRoleLabels(newRoleLabels);
      }
    });
  };

  const removeUsersWithNoMatchingGroups = (staff) => {
    let staffToRemove = [];
    staff.forEach(s => {
      if (s.groupMappings.length === 0) {
        staffToRemove.push(s);
      }
    });
    let newStaffList = staff.filter(s => {
      return !staffToRemove.includes(s);
    });
    return newStaffList
  };


  const filterStaffListGroupsBasedOnLoggedInUsersGroups = (staff, myProfile) => {
    if (!myProfile.superAdmin && (!myProfile.groupMappings || myProfile.groupMappings.length === 0)) {
      return [];
    }

    let groupCodes = [];
    myProfile.groupMappings.forEach(f => {
      groupCodes.push(f.group.code);
    })

    let groupsToRemove = [];
    let returnStaffList = [];
    staff.forEach(s => {
      if (myProfile.superAdmin) {
        returnStaffList.push(s);
        return;
      }

      if (!s.groupMappings) {
        return;
      }
      s.groupMappings.forEach(gm => {
        if (!groupCodes.includes(gm.group.code)) {
          groupsToRemove.push(gm.group.code);
        }
      });

      let newList = s.groupMappings.filter(g => {
        return !groupsToRemove.includes(g.group.code);
      });

      s.groupMappings = newList;
      returnStaffList.push(s);
    });
    return returnStaffList;
  };

  const buildStaffStateLabel = (s, title) => {
    let colour = "green";
    if (s.state === "AWAITING_ACTIVATION") {
      colour = "orange";
    }
    if (s.state === "SUSPENDED") {
      colour = "red";
    }
    return (
        <Label size={"tiny"} color={colour}>
          {title}
        </Label>
    );
  };

  const sendActivationEmail = (staff, index) => {
    StaffService.sendActivationEmail(staff.email);
    setHasEmailResent((prevState) => {
      let nextState = [...prevState];
      nextState[index] = true;
      return nextState;
    });
  };

  const toggleStaffSuspension = (feedbackReason, staff) => {
    StaffService.toggleStaffSuspension(feedbackReason, staff).then(() => {
      initialise();
    });
  };

  const toggleExpandAll = (triggeredFromExportButton) => {
    let allIds = [];
    staff.map((s) => {
      return allIds.push(s.id);
    });
    if (!allExpanded && !triggeredFromExportButton) {
      setExpandedStaffRows(allIds);
      setAllExpanded(true);
    } else if (allExpanded && !triggeredFromExportButton) {
      setExpandedStaffRows([0]);
      setAllExpanded(false);
    }
    //if triggered from export button then expand all staff
    else if (triggeredFromExportButton) {
      setExpandedStaffRows(allIds);
    }
  }

  const filterChangeHandler = (selectedStaffDropdownFilter) => {
    setStaffDropdownFilter(selectedStaffDropdownFilter);
  }

  const itemCount = (state) => {
    return staff.filter(staffMember => {
      return staffMember.state === state;
    }).length;
  }

  const staffIdNotInExpandedList = (id) => {
    return expandedStaffRows.indexOf(id) === -1;
  }

  const handleToggleExpandStaff = (staffMemberId) => {
    setBlinkLinkCard(false);
    if (staffIdNotInExpandedList(staffMemberId)) {
      setExpandedStaffRows((prevExpandedItems) => [staffMemberId, ...prevExpandedItems]);
    } else {
      setExpandedStaffRows(prevState => prevState.filter((id) => id !== staffMemberId));
    }
  };

  const generateExport = async () => {
    const exportData = []
    filteredStaffList.forEach(s => {
      s.groupMappings.forEach(gm=> {
        gm.roles.forEach(r => {
          let roleLabel = roleLabels[r.code]
              ? roleLabels[r.code]
              : r.code.charAt(0).toUpperCase() + r.code.slice(1);

          const exportRow = {};
          exportRow[t("STAFF_LIST_TABLE_HEADER_STAFF_ID", "ID")] = s.id;
          exportRow[t("STAFF_LIST_TABLE_HEADER_FIRST_NAME", "First name")] = s.firstName;
          exportRow[t("STAFF_LIST_TABLE_HEADER_LAST_NAME", "Last name")] = s.lastName;
          exportRow[t("STAFF_LIST_TABLE_HEADER_EMAIL", "Email")] = s.email;
          if (showIsSuperAdmin) {
            exportRow[t("STAFF_LIST_TABLE_HEADER_IS_SUPER_ADMIN", "Is Super Admin?")] = s.superAdmin ? t("GENERIC_YES") : t("GENERIC_NO");
          }
          if (showDrugManager) {
            exportRow[t("STAFF_LIST_TABLE_HEADER_DRUG_MANAGER", "Drug Manager?")] = s.drugManager ? t("GENERIC_YES") : t("GENERIC_NO");
          }
          exportRow[t("STAFF_CARD_CREATION_DATE", "Created date")] = s.createdDate;
          exportRow[t("STAFF_CARD_END_DATE", "Suspended date")] = s.endDate;
          exportRow[t("STAFF_LIST_TABLE_HEADER_STATUS", "Status")] = s.state;
          exportRow[t("GROUPS_LIST_GROUP_NAME", "Group")] = groupLabels[gm.group.code].label;
          exportRow[t("STAFF_GROUP_HEADER", "Role")] = roleLabel;

          exportData.push(exportRow)
        })
      })
    })
    let results = jsonToCSV(exportData, { escapeFormulae: true });
    const csvBlobData = new Blob([results], {
      type: "text/csv;charset=utf-8;",
    });

    const theDate = DateTimeService.build.asDisplayDate(new Date());
    const filename = `StaffDetailsExport-${theDate}.csv`;
    saveAs(csvBlobData, filename);

  }

  const suspendedCount = itemCount('SUSPENDED');
  const awaitingActivationCount = itemCount('AWAITING_ACTIVATION');

  const handleShowAdmin = () => {
    setShowAdmin(!showAdmin);
  }
  const handleFilteredStaffList = (list) => {
    setFilteredStaffList(list);
  };

  const handleFilterChanged = (concatenatedSearchResults) => {
    setFilteredStaffList(concatenatedSearchResults);
    setInitialFilterForGroups(concatenatedSearchResults);
    populateLinkButtons(concatenatedSearchResults);
  }

  const populateLinkButtons = (list) => {
    //set up the buttons correctly
    const links = [];
    const icons = [];

    list.forEach(staffEntity => {
      const staffLinkedToSubject = staffEntity.linkedSubjectId !== null;

      if (staffLinkedToSubject) {
        links.push(t("UNLINK_STAFF_SUBJECT", "Unlink"));
        icons.push(<Icon name="unlink"/>);
      } else {
        links.push(t("LINK_STAFF_SUBJECT", "Link"));
        icons.push(<Icon name="linkify"/>);
      }
    });
    setLinkedButtonsText(links);
    setLinkedButtonsIcons(icons);
  }

  const getTableColumnCount = () => {
    let count = TABLE_COL_FIXED_COUNT;
    if (showIsSuperAdmin) {
      count++;
    }
    if (showDrugManager) {
      count++;
    }
    return count;
  }

  const tableColumnCount = useMemo(getTableColumnCount, [showIsSuperAdmin, showDrugManager]);

  let styles = {
    marginRight: '20px',
    width: '250px',
    height: '250px',
    backgroundColor: 'yellow',
  };

  const openStaffCardWithBlink = (staffId) => {
    setBlinkLinkCard(true);
    setExpandedStaffRows((prevExpandedItems) => [staffId, ...prevExpandedItems])
  }

  const handleUpdateButtonState = (index, newValue) => {
    const newButtonList = linkedButtonsText.map((item, i) => {
      if (i === index) {
        if (newValue === "link") {
          return t("UNLINK_STAFF_SUBJECT", "Unlink");
        } else if (newValue === "unlink") {
          return t("LINK_STAFF_SUBJECT", "Link");
        }
        return item;
      } else {
        return item;
      }
    });
    setLinkedButtonsText(newButtonList);

    const newIconList = linkedButtonsIcons.map((item, i) => {
      if (i === index) {
        if (newValue === "link") {
          return <Icon name="unlink"/>;
        } else if (newValue === "unlink") {
          return <Icon name="linkify"/>;
        }
        return item;
      } else {
        return item;
      }
    });
    setLinkedButtonsIcons(newIconList);
  };

  return (
    <Page
      name="STAFF_LIST"
      header={t("STAFF_LIST_HEADER")}
      subheader={t("STAFF_LIST_SUBHEADER")}
      loading={isLoading}>
      <div style={{
        padding:10,
        display: 'flex',
        height: 50,
        alignItems: 'center' }}>
        <StaffListFilter
          suspendedCount={suspendedCount}
          awaitingActivationCount={awaitingActivationCount}
          selected={staffDropdownFilter}
          onChangeFilter={filterChangeHandler}/>
          {myProfile?.superAdmin && (<div style={{marginLeft: 20, marginBottom: 10}}>
            <AparitoSwitch style={styles}
              id="aparitoSwitch"
              checked={showAdmin}
              onChange={handleShowAdmin}
              label={t("STAFF_INCLUDE_SUPER_ADMIN", "Include Super Admin")} />
          </div>)}

          <NameAndEmailSearch
            staff={staff}
            showAdmin={showAdmin}
            roleLabels={roleLabels}
            groupLabels={groupLabels}
            staffDropdownFilter={staffDropdownFilter}
            initialFilterForGroups={initialFilterForGroups}
            handleFilterChanged={handleFilterChanged}
            />

          <GroupAndRoleSearch
            initialFilterForGroups={initialFilterForGroups}
            handleFilteredStaffList={handleFilteredStaffList}
            groupLabels={groupLabels}
            roleLabels={roleLabels}/>
      </div>

      <Table selectable columns={tableColumnCount} id={"staffTable"}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell id="expand" onClick={() => toggleExpandAll(false)} style={{ cursor: "pointer" }}>
              {!allExpanded && <Icon name="caret right" />}
              {allExpanded && <Icon name="caret down" />}
              {" " + t("STAFF_LIST_TABLE_HEADER_EXPAND", "Expand")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("STAFF_LIST_TABLE_HEADER_STAFF_ID", "ID")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("STAFF_LIST_TABLE_HEADER_FIRST_NAME", "First name")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("STAFF_LIST_TABLE_HEADER_LAST_NAME", "Last name")}
            </Table.HeaderCell>
            {showIsSuperAdmin && (
              <Table.HeaderCell>
                {t("STAFF_LIST_TABLE_HEADER_IS_SUPER_ADMIN", "Is Super Admin?")}
              </Table.HeaderCell>
            )}
            {showDrugManager && (
              <Table.HeaderCell>
                {t("STAFF_LIST_TABLE_HEADER_DRUG_MANAGER", "Drug Manager?")}
              </Table.HeaderCell>
            )}
            <Table.HeaderCell>
              {t("STAFF_LIST_TABLE_HEADER_EMAIL", "Email")}
            </Table.HeaderCell>
            <Table.HeaderCell collapsing>
              {t("STAFF_LIST_TABLE_HEADER_CREATED_DATE", "Created Date")}
            </Table.HeaderCell>
            <Table.HeaderCell collapsing>
              {t("STAFF_LIST_TABLE_HEADER_STATUS", "Status")}
            </Table.HeaderCell>
            {canManageStaff && (
              <Table.HeaderCell id="actions">
                {t("STAFF_LIST_TABLE_HEADER_ACTIONS", "Actions")}
              </Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {filteredStaffList.map((staffEntity, index) => {
            const disabled = !staffEntity.canBeEdited;
            const goToEdit =
              disabled ||
              (() => {
                history.push("/app/staff/edit/" + staffEntity.id);
              });

            return (
              <Fragment key={staffEntity.id}>
                <Table.Row
                  warning={staffEntity.state !== "ACTIVE"}
                  style={disabled ? { opacity: 0.5 } : { cursor: "pointer" }}
                >
                  <Table.Cell id="expand" onClick={() => handleToggleExpandStaff(staffEntity.id)}>
                   {staffIdNotInExpandedList(staffEntity.id) && <Icon name="caret right" /> }
                   {!staffIdNotInExpandedList(staffEntity.id) && <Icon name="caret down" /> }
                  </Table.Cell>
                  <Table.Cell onClick={goToEdit}>{staffEntity.id}</Table.Cell>
                  <Table.Cell onClick={goToEdit}>{staffEntity.firstName}</Table.Cell>
                  <Table.Cell onClick={goToEdit}>{staffEntity.lastName}</Table.Cell>
                  {showIsSuperAdmin && (
                    <Table.Cell onClick={goToEdit}>
                      {staffEntity.superAdmin ? t("GENERIC_YES") : t("GENERIC_NO")}
                    </Table.Cell>
                  )}
                  {showDrugManager && (
                    <Table.Cell onClick={goToEdit}>
                      {staffEntity.drugManager ? t("GENERIC_YES") : t("GENERIC_NO")}
                    </Table.Cell>
                  )}
                  <Table.Cell onClick={goToEdit}>{staffEntity.email}</Table.Cell>
                  <Table.Cell onClick={goToEdit}>
                    {staffEntity.createdDate ? `${DateTimeService.build.asDisplayDateTime(staffEntity.createdDate)}` : ''}
                  </Table.Cell>
                  <Table.Cell onClick={goToEdit}>
                    {buildStaffStateLabel(staffEntity, t("STAFF_STATE_" + staffEntity.state))}
                  </Table.Cell>
                  {canManageStaff && (
                    <Table.Cell id="actions" textAlign={"right"}>
                      {staffEntity.state === "AWAITING_ACTIVATION" &&
                        !hasEmailResent[index] && (
                          <Button
                            primary
                            size={"tiny"}
                            labelPosition="left"
                            icon
                            disabled={disabled}
                            onClick={() => sendActivationEmail(staffEntity, index)}>
                            {t("STAFF_LIST_SEND_ACTIVATION_EMAIL_BUTTON")}{" "}
                            <Icon name="mail" />
                          </Button>
                        )}

                      {staffToSubjectLinkingEnabled &&
                          displayOnStaffListPage && (<Button
                          color="green"
                          labelPosition="right"
                          icon
                          style={{marginBottom:"5px", width:"120px"}}
                          onClick={() => openStaffCardWithBlink(staffEntity.id)}
                        >
                          {linkedButtonsText[index]} {linkedButtonsIcons[index]}
                        </Button>)
                      }

                      {staffEntity.state === "AWAITING_ACTIVATION" && hasEmailResent[index] && (
                        <Button
                          color="yellow"
                          disabled
                          size={"tiny"}
                          style={{marginBottom:"5px", width:"120px"}}
                            labelPosition="left"
                            icon
                          >
                            {t("SENT")} <Icon name="paper plane" />
                          </Button>
                        )}

                    {staffEntity.state !== "SUSPENDED" && !PermissionsService.isPrimarySuperAdmin(staffEntity) && !disabled && (
                      <ConfirmButtonWithFeedback
                        buttonText={t("STAFF_LIST_SUSPEND_BUTTON", "Suspend")}
                        headerText={t("STAFF_ACCOUNT_SUSPEND_HEADER", "Suspend Staff Account")}
                        contentText={t("STAFF_ACCOUNT_SUSPEND_DETAIL", "Please confirm that you want to suspend this staff account")}
                        color={"red"}
                        confirmButtonText={t("STAFF_LIST_SUSPEND_BUTTON", "Suspend")}
                        cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                        onConfirm={(feedback) => toggleStaffSuspension(feedback, staffEntity)}
                        placeholderText={t("STAFF_LIST_SUSPEND_BUTTON_REASON", "Reason")}
                        mandatoryValidationText={t("STAFF_LIST_SUSPEND_BUTTON_REASON_ERROR", "Please enter a valid reason")}
                        propStyle={{marginBottom:"5px", width:"120px"}}
                      />
                    )}

                      {staffEntity.state === "SUSPENDED" && !PermissionsService.isPrimarySuperAdmin(staffEntity) && (
                        <ConfirmButtonWithFeedback
                          buttonText={t("STAFF_LIST_ACTIVATE_BUTTON", "Activate")}
                          headerText={t("STAFF_ACCOUNT_ACTIVATE_HEADER", "Activate Staff Account")}
                          contentText={t("STAFF_ACCOUNT_ACTIVATE_DETAIL", "Please confirm that you want to activate this staff account")}
                          color={"green"}
                          confirmButtonText={t("STAFF_LIST_ACTIVATE_BUTTON", "Activate")}
                          cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                          onConfirm={(feedback) => toggleStaffSuspension(feedback, staffEntity)}
                          disabled={disabled}
                          placeholderText={t("STAFF_LIST_ACTIVATE_BUTTON_REASON", "Subject code")}
                          mandatoryValidationText={t("STAFF_LIST_ACTIVATE_BUTTON_REASON_ERROR", "Please enter a valid reason")}
                        />
                      )}
                    </Table.Cell>
                  )}
                </Table.Row>
                {!staffIdNotInExpandedList(staffEntity.id) && (
                <Table.Row>
                  <Table.Cell id="expandedData" colSpan={tableColumnCount}>
                    {staffToSubjectLinkingEnabled &&
                        displayOnStaffListPage && (<StaffSubjectLinkCard
                        disabled={disabled}
                        index={index}
                        blink={blinkLinkCard}
                        staffEntity={staffEntity}
                        groupLabels={groupLabels}
                        handleUpdateButtonState={handleUpdateButtonState}/>)
                    }
                    <StaffCard
                      staffEntity={staffEntity}
                      groupLabels={groupLabels}
                      roleLabels={roleLabels}/>
                  </Table.Cell>
                </Table.Row>
                )}
              </Fragment>
            );
          })}
        </Table.Body>
      </Table>

      {filteredStaffList.length === 0 && (
        <div style={{ marginBottom:20 }}>{t("STAFF_NO_STAFF_IN_LIST", "No staff listed, try adjusting the filters above.")}</div>
      )}

      {canManageStaff && (
        <Button primary icon as={Link} labelPosition="left" to={"/app/staff/new"}>
        {t(["STAFF_LIST_ADD_NEW", "GLOBAL_BUTTON_ADD_NEW"])}{" "}
        <Icon name="user md" />
      </Button>
      )}

      <Button
        primary
        icon
        disabled={filteredStaffList.length === 0}
        labelPosition="left"
        onClick={generateExport}>
        {t("EXPORT_TABLE", "Export Table as CSV")}
        <Icon name="table" />
      </Button>
    </Page>
  );
};

export default withTranslation()(StaffListPage);
