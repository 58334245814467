import React, { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import ConfigContext from "../../context/ConfigContext";
import QuestionnaireDefinitionService from "../../services/admin/QuestionnaireDefinitionService";
import ConfirmButton from "../dashboard/ConfirmButton";
import ConfirmButtonWithFeedback from "../dashboard/ConfirmButtonWithFeedback";
import ResponseErrorPanel from "../errors/ResponseErrorPanel";
import StaffPermissionService from "../../services/StaffPermissionService";
import { compose } from "redux";
import withPermissionWrapper from "../../security/withPermissionWrapper";


function AdminQuestionnaireDefinitionDuplicateModal({
  t,
  isOpen,
  setIsOpen,
  questionnaireDefinitionCode,
  setSearchTermCallback,
}) {

  const config = useContext(ConfigContext);
  const [cloneObj, setCloneObj] = useState();
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(null);
  const [errorResponse, setErrorResponse] = useState();

  useEffect(() => {
    setCloneObj({ clone_questionnaireDefinition_code: questionnaireDefinitionCode });
  }, [questionnaireDefinitionCode]);

  let showFeedbackOnSubmit = config.audit?.captureGroupAlternationFeedback;

  const onChange = (e, item) => {
    e.preventDefault();
    setCloneObj((cloneObj) => {
      const changeCloneObj = { ...cloneObj, [e.target.name]: e.target.value };
      return changeCloneObj;
    });
    setHasErrors(false);
  }

  const isValid = () => {
    if (cloneObj?.clone_questionnaireDefinition_code === undefined) {
      return false;
    }
    if (cloneObj?.new_questionnaireDefinition_code === undefined) {
      return false;
    }
    if (cloneObj.new_questionnaireDefinition_code.trim().length === 0) {
      return false;
    }
    if (cloneObj.new_questionnaireDefinition_code.indexOf(" ") > -1) {
      return false;
    }
    let regEx = config?.validationRules?.includes('allowUpperCaseChars') ? /^[a-zA-Z0-9-]+$/g : /^[a-zA-Z0-9-]+$/g;
    if (!regEx.test(cloneObj.new_questionnaireDefinition_code)) {
      return false;
    }
    if (loading) {
      return false;
    }
    return true;
  }

  const handleSubmit = async (feedback) => {
    try {
      setLoading(true);
      await QuestionnaireDefinitionService.cloneQuestionnaireDefinition(cloneObj, feedback);
      setSearchTermCallback(cloneObj.new_questionnaireDefinition_code);
      setIsOpen();
      setHasErrors(false);
      setLoading(false);
    } catch (response) {
      setLoading(false);
      //showErrors(response);
      setErrorResponse(response);
      setHasErrors(true)
    }
  }

  return (
    <Modal open={isOpen} onClose={() => { setIsOpen(); setHasErrors(false) }}>
      <Modal.Content scrolling>
        <Modal.Description>
          <Form>
            <Form.Field>
              <label ><h3 className="ui header">{t("ADMIN_QUESTIONNAIREDEFINITION_CLONE_INFO_CODE", "Clone Questionnaire Definition")}</h3></label>
            </Form.Field>
            <Form.Field>
              <ResponseErrorPanel errorResponse={errorResponse} showErrors={hasErrors} title={t("ADMIN_QUESTIONNAIREDEFINITION_VALIDATIONFAILED", "Validation failed, please expand to show errors")} />
            </Form.Field>
            <Form.Field>
              <label>{t("ADMIN_QUESTIONNAIREDEFINITION_CLONE_CODE", "Please enter new code for the new copy of this Questionnaire Definition:")}</label>
              <Input name="new_questionnaireDefinition_code" fluid placeholder={cloneObj?.clone_questionnaireDefinition_code + "-copy"} onChange={onChange} />
            </Form.Field>
            {showFeedbackOnSubmit ? (
              <ConfirmButtonWithFeedback
                buttonText={t("GLOBAL_BUTTON_SUBMIT", "Submit")}
                headerText={t("GLOBAL_BUTTON_SUBMIT", "Submit")}
                contentText={t(
                  "ADMIN_QUESTIONNAIREDEFINITION_CLONE_DETAILED",
                  "Please supply a reason why this modification is required"
                )}
                confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
                cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                onConfirm={handleSubmit}
                placeholderText={t("ADMIN_QUESTIONNAIREDEFINITION_CLONE_PLACEHOLDER_TEXT", "Supply reason here.")}
                mandatoryValidationText={t(
                  "ADMIN_QUESTIONNAIREDEFINITION_CLONE_VALIDATION_TEXT",
                  "Please supply a reason for the change."
                )}
                disabled={!isValid()}
                color={isValid() ? "orange" : "grey"}
              />
            ) : (
              <ConfirmButton
                buttonText={t("GLOBAL_BUTTON_SUBMIT", "Submit")}
                headerText={t("GLOBAL_BUTTON_SUBMIT", "Submit")}
                contentText={t(
                  "ADMIN_QUESTIONNAIREDEFINITION_NEW_DETAILED_NO_FEEDBACK",
                  "Please confirm that you want to make this change"
                )}
                confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
                cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                onConfirm={handleSubmit}
                disabled={!isValid()}
                color={isValid() ? "orange" : "grey"}
              />
            )}
            <Button
              onClick={() => { setIsOpen(); setHasErrors(false) }}>
              {t("ADMIN_QUESTIONNAIREDEFINITION_CANCEL", "Cancel")}
            </Button>
          </Form>

        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

const withEnhancements = (options) => compose(
  withPermissionWrapper(options),
  withTranslation()
);
export default withEnhancements({ permissionFunctionDelegate: StaffPermissionService.canManageQuestionnaireDefinitions })(AdminQuestionnaireDefinitionDuplicateModal);
