import React, { useState, useContext, useEffect, useMemo } from "react";
import { withTranslation } from "react-i18next";
import AuthService from "../../services/AuthService";
import { Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import PasswordChangeModal from "../../pages/profile/PasswordChangeModal";
import UserContext from "../../context/UserContext";
import SubjectService from "../../SubjectService";
import StaffService from "../../StaffService";
import {typeHelper} from "atom5-branching-questionnaire";
import StaffPermissionService from "../../services/StaffPermissionService";

const STUDY_COBFIGURATION_MENU_ITEM_DEFINITIONS = [
    {
        url: '/app/utils/tech-support',
        translationKey: 'MENU_DROP_SUPPORT',
        fallbackText: 'Tech support',
        permissionFunctionDelegate: StaffPermissionService.canAccessTechSupportFeatures
    },
    {
        url: '/app/utils/answers/recalculate',
        translationKey: 'MENU_DROP_RECALCULATE_ANSWERS',
        fallbackText: 'Answer recalculation',
        permissionFunctionDelegate: StaffPermissionService.canPerformAnswerRecalculation
    },
    {
        url: '/app/utils/attachment-blurring',
        translationKey: 'MENU_DROP_BLURRING',
        fallbackText: 'Attachment blurring',
        permissionFunctionDelegate: StaffPermissionService.canManualAttachmentBlurringStatusUpdate
    },
    {
        url: '/app/utils/attachment-monitoring',
        translationKey: 'MENU_DROP_ATTACHMENTS',
        fallbackText: 'Attachment monitoring',
        permissionFunctionDelegate: StaffPermissionService.canViewAttachmentMonitoring
    },
    {
        url: '/app/utils/attachment-randomisation',
        translationKey: 'MENU_DROP_RANDOMISATION',
        fallbackText: 'Attachment randomisation',
        permissionFunctionDelegate: StaffPermissionService.canViewAttachmentsForRandomising
    },
    {
        url: '/app/utils/cache-management',
        translationKey: 'MENU_DROP_CACHE',
        fallbackText: 'Cache management',
        permissionFunctionDelegate: StaffPermissionService.canRefreshCaches
    },
    {
        url: '/app/utils/config-tool',
        translationKey: 'MENU_DROP_CONFIG',
        fallbackText: 'Configuration',
        permissionFunctionDelegate: StaffPermissionService.canManageConfig
    },
    {
        url: '/app/utils/triggers',
        translationKey: 'MENU_DROP_TRIGGERS',
        fallbackText: 'Triggers',
        permissionFunctionDelegate: StaffPermissionService.canManageTriggers
    },
    {
        url: '/app/utils/digest-alerts',
        translationKey: 'MENU_DROP_DIGEST_ALERTS',
        fallbackText: 'Digest email alerts',
        permissionFunctionDelegate: StaffPermissionService.canSearchNotificationActivityAudit
    },
    {
        url: '/app/utils/column-overrides',
        translationKey: 'MENU_DROP_COLUMN',
        fallbackText: 'Export configuration',
        permissionFunctionDelegate: StaffPermissionService.canManageExportConfig
    },
    {
        url: '/app/utils/lookup',
        translationKey: 'MENU_DROP_LOOKUP',
        fallbackText: 'Lookups',
        permissionFunctionDelegate: StaffPermissionService.canManageLookups
    },
    {
        url: '/app/utils/modules',
        translationKey: 'MENU_DROP_MODULES',
        fallbackText: 'Modules',
        permissionFunctionDelegate: StaffPermissionService.canManageModules
    },
    {
        url: '/app/utils/notification-auditactivity',
        translationKey: 'MENU_DROP_NOTIFICATION',
        fallbackText: 'Notification audit',
        permissionFunctionDelegate: StaffPermissionService.canSearchNotificationActivityAudit
    },
    {
        url: '/app/utils/questionnaire-definitions',
        translationKey: 'MENU_DROP_QUESTIONNAIREDEFS',
        fallbackText: 'Questionnaire definitions',
        permissionFunctionDelegate: StaffPermissionService.canManageQuestionnaireDefinitions
    },
    {
        url: '/app/utils/questionnaire-workflow',
        translationKey: 'MENU_DROP_QUESTIONNAIRE_WORKFLOW',
        fallbackText: 'Questionnaire workflows',
        permissionFunctionDelegate: StaffPermissionService.canManageQuestionnaireWorkflows
    },
    {
        url: '/app/utils/remotejob-processing-queues',
        translationKey: 'MENU_DROP_REMOTE_PROCESSING_QUEUES',
        fallbackText: 'Remote job processing queues',
        permissionFunctionDelegate: StaffPermissionService.canManageRemoteJobProcessingQueues
    },
    {
        url: '/app/utils/study-management',
        translationKey: 'MENU_DROP_STUDY_MANAGEMENT',
        fallbackText: 'Study management',
        permissionFunctionDelegate: StaffPermissionService.canManageTrialLevelConfig
    },
    {
        url: '/app/utils/translations',
        translationKey: 'MENU_DROP_TRANSLATIONS',
        fallbackText: 'Translations & Languages',
        permissionFunctionDelegate: StaffPermissionService.canManageTranslations
    }
];

const DropDownMenu = (props) => {
    const { t, outstandingMandatoryQuestionnaires, switchAccountSubjectId, switchAccountStaffId } = props;

    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [canChangePassword, setCanChangePassword] = useState(false);

    const { profile } = useContext(UserContext);
    const isSuperAdmin = profile?.superAdmin;

    const accountType = AuthService.getAccountType();
    const isStaff = accountType === "staff";

    const showPassword = () => {
        setShowPasswordModal(true);
    };

    const closePassword = () => {
        setShowPasswordModal(false);
    };

    const checkIfAllowedToChangePassword = () => {
        SubjectService.canChangePassword()
            .then((data) => {
                setCanChangePassword(data)
            });
    };

    const [staffToSubjectLinkingEnabled, setStaffToSubjectLinkingEnabled] = useState(true);

    const initialise = async () => {
        const staffToSubjectLinking = await StaffService.isStaffToSubjectLinkingEnabled();
        setStaffToSubjectLinkingEnabled(typeHelper.parseBool(staffToSubjectLinking.enabled));
    }

    useEffect(() => {
        checkIfAllowedToChangePassword();
        initialise().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSwitchAccounts = async () => {
        try {
            if (switchAccountSubjectId !== undefined) {
                await AuthService.switchToSubjectAccount(switchAccountSubjectId);
            } else if (switchAccountStaffId !== undefined) {
                await AuthService.switchToStaffAccount(switchAccountStaffId);
            }
        } catch(err) {
            //if there is an error then log to the console and redirect to the homepage
            console.log("Error: " + err);
            window.location.href = window.location.origin;
        }
    }

    const getStudyConfigurationItemsToRender = () => {
        if (!isStaff) {
            return [];
        }

        return STUDY_COBFIGURATION_MENU_ITEM_DEFINITIONS
            .map(item => {
                return { ...item, label: t(item.translationKey, item.fallbackText) };
            })
            .sort((a, b) => a?.label?.localeCompare(b.label))
            .filter(item => {
                if (item.permissionFunctionDelegate != null && typeof item.permissionFunctionDelegate === "function") {
                    return typeHelper.parseBool(item.permissionFunctionDelegate(profile));
                }
                return false;
            })
            .map(item => {
                return <Dropdown.Item as={Link} key={item.url} to={item.url} fitted={'vertically'}>{item.label}</Dropdown.Item>;
            });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const studyConfigurationItemsToRender = useMemo(getStudyConfigurationItemsToRender, [profile, isStaff]);

    return (
        <>
            <Dropdown item text={t(
                'MENU_DROP_LABEL',
                'My Account'
            )}>
                <Dropdown.Menu className='left'>
                    <Dropdown.Header content='Account' />
                    <Dropdown.Item
                        className="atom5-video-controlstop"
                        as={Link}
                        to="/app/profile"
                    >
                        {t("MENU_PROFILE")}
                    </Dropdown.Item>
                    {isStaff && (
                        <Dropdown.Item as={Link} to={"/app/permissions"} fitted={"vertically"}>
                            {t(
                                'MENU_DROP_MYPER',
                                'My Roles & Permissions'
                            )}
                        </Dropdown.Item>
                    )}
                    {canChangePassword && (
                        <Dropdown.Item onClick={showPassword}>
                            {t(
                                'MENU_DROP_PASSWORD',
                                'Change Password'
                            )}
                        </Dropdown.Item>
                    )}
                    {isSuperAdmin && (
                        <>
                            <Dropdown.Divider />
                            <Dropdown.Header content={t(
                                'MENU_DROP_ADMIN_TITLE',
                                'Admin'
                            )} />
                            <Dropdown.Item as={Link} to={"/app/utils"} fitted={"vertically"}>
                                {t(
                                    'MENU_DROP_ADMIN',
                                    'Admin Utilities'
                                )}
                            </Dropdown.Item>
                        </>
                    )}

                    {studyConfigurationItemsToRender?.length > 0 && (
                        <>
                            <Dropdown.Divider />
                            <Dropdown scrolling item text={t(['MENU_DROP_STUDY_CONFIGURATION_TITLE', 'MENU_DROP_MORE_TITLE'], 'Study configuration')}>
                                <Dropdown.Menu style={{ maxHeight: "38rem" }}>
                                    <Dropdown.Header content={t(['MENU_DROP_STUDY_CONFIGURATION_TITLE', 'MENU_DROP_MORE_TITLE'], 'Study configuration')} />
                                    {studyConfigurationItemsToRender}
                                </Dropdown.Menu>
                            </Dropdown>
                        </>
                    )}

                    <Dropdown.Divider />
                    {(switchAccountSubjectId != null || switchAccountStaffId != null)
                        && outstandingMandatoryQuestionnaires.length === 0
                        && staffToSubjectLinkingEnabled && (
                            <Dropdown.Item
                                onClick={handleSwitchAccounts}
                            >
                                {t("STAFF_TO_SUBJECT_SWITCH", "Switch user")}
                            </Dropdown.Item>)
                    }
                    <Dropdown.Item
                        className="atom5-video-controlstop"
                        onClick={() => {
                            AuthService.logout();
                        }}
                    >
                        {t("GLOBAL_BUTTON_SIGN_OUT")}
                    </Dropdown.Item>

                </Dropdown.Menu>
            </Dropdown>

            <PasswordChangeModal show={showPasswordModal}
                                 onClose={closePassword}
                                 closeIcon />
        </>
    );
};

export default withTranslation()(DropDownMenu);
