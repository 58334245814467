import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Dropdown, Form, Input} from "semantic-ui-react";
import { ACTION_TYPES } from "../../../hooks/useCompleteQuestionnaireDefinitionInformation";
import { QUESTIONNAIRE_DEFINITION_RESTRICT_TO } from "../../../constants/QUESTIONNAIRE_DEFINITION_RESTRICT_TO";
import WorkflowService from "../../../services/admin/WorkflowService";

function QuestionnaireEditorCardComponent({
  questionnaireDefinition,
  isReadOnly = false,
  dispatch,
  blockNavigation
}) {
  const [workflowDefinitions, setWorkflowDefinitions] = useState([]);
    const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect( () => {
      const loadData = () => {
          const fetchAllWorkflows = async () => {
              setLoading(true);
              const workflowDefinitionsList = await WorkflowService.fetchAllWorkflowDefinitions();
              setWorkflowDefinitions(workflowDefinitionsList);
              setLoading(false);
          }
          fetchAllWorkflows().catch(error => {
              setLoading(false);
              console.log("Error loading workflowDefinitionList: " + error);
          });
      };
      loadData();
  },[])

  const restrictToOptions = Object.entries(QUESTIONNAIRE_DEFINITION_RESTRICT_TO)
    .map(([_key, value]) => {
      return {
        key: value,
        text: value,
        value: value,
      };
    });

    const workflowToOptions = workflowDefinitions
        .map( wfd => {
            return {
                key: wfd.code,
                text: wfd.code,
                value: wfd.id,
            };
        }).sort((a, b) => a.key.localeCompare(b.key));

  const onChange = (_e, v) => {
    const composedResponse = { ...questionnaireDefinition, [v.name]: v.value };
    blockNavigation();
    dispatch({
      type: ACTION_TYPES.EDIT_QUESTIONNAIRE_DEFINITION,
      payload: composedResponse,
    });
  };

  return (
    <Form>
      <Form.Field>
        <label>{t("QUESTIONNAIRE_DEFINITION_TYPE", "Type")}</label>
        <Input
          value={questionnaireDefinition.type}
          name={"type"}
          fluid
          transparent={isReadOnly}
          disabled={isReadOnly}
          onChange={onChange}
        />
      </Form.Field>
      <Form.Field>
        <label>{t("QUESTIONNAIRE_DEFINITION_SEQUENCE", "Sequence")}</label>
        <Input
          value={questionnaireDefinition.sequence}
          name={"sequence"}
          fluid
          transparent={isReadOnly}
          disabled={isReadOnly}
          onChange={onChange}
        />
      </Form.Field>
      <Form.Field>
        <label>
          {t("QUESTIONNAIRE_DEFINITION_NONSCORING", "Non Scoring?")}
        </label>
        <Checkbox
          checked={questionnaireDefinition.nonScoring}
          name={"nonScoring"}
          onChange={onChange}
        />
      </Form.Field>
      <Form.Field>
        <label>
          {t("QUESTIONNAIRE_DEFINITION_SCORECALCULATION", "Score Calculation")}
        </label>
        <Input
          value={questionnaireDefinition.scoreCalculation}
          name={"scoreCalculation"}
          fluid
          transparent={isReadOnly}
          disabled={isReadOnly}
          onChange={onChange}
        />
      </Form.Field>
      <Form.Field>
        <label>{t("QUESTIONNAIRE_DEFINITION_RESTRICTTO", "Restrict to")}</label>
        <Dropdown
          fluid
          selection
          options={restrictToOptions}
          value={questionnaireDefinition.restrictTo}
          name={"restrictTo"}
          onChange={onChange}
          disabled={isReadOnly}
        />
      </Form.Field>
    <Form.Field>
        <label>{t("QUESTIONNAIRE_DEFINITION_WORKFLOW", "Assign Workflow")}</label>
        <Dropdown
            fluid
            selection
            clearable
            loading={loading}
            options={workflowToOptions}
            value={questionnaireDefinition.questionnaireWorkflowDefinitionId}
            name={"questionnaireWorkflowDefinitionId"}
            onChange={(_e, v) => {
                const wfd = {name:"questionnaireWorkflowDefinition", value: null}
                const composedResponse = { ...questionnaireDefinition, [wfd.name]: wfd.value, [v.name]:v.value };
                blockNavigation();
                dispatch({
                    type: ACTION_TYPES.EDIT_QUESTIONNAIRE_DEFINITION,
                    payload: composedResponse,
                });
            } }
            disabled={isReadOnly}
        />
    </Form.Field>
    </Form>
  );
}

export default QuestionnaireEditorCardComponent;
