import React, { useState, useEffect, useContext } from "react";
import { withTranslation } from "react-i18next";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Header,
  Loader,
  Message,
  Segment,
  Table,
} from "semantic-ui-react";
import Page from "../../components/page/Page";
import InternationalisationService from "../../InternationalisationService";
import {
  getLanguages,
  searchTranslations,
  downloadForTranslation,
  downloadForMigration,
  getBackgroundJobs, downloadDiffsFromRefLanguage, downloadQuestionnaireDefinitionsTranslatableReport,
} from "../../services/admin/AdminTranslationService";
import EditTranslationModal from "../../components/admin/EditTranslationModal";
import EditLanguageModal from "../../components/admin/EditLanguageModal";
import { saveAs } from "file-saver";
import TrialContext from "../../context/TrialContext";
import UploadTranslationsModal from "../../components/admin/UploadTranslationsModal";
import DownloadTranslationsFiltersModal from "../../components/admin/DownloadTranslationsFiltersModal";
import StaffPermissionService from "../../services/StaffPermissionService";
import { compose } from "redux";
import withPermissionWrapper from "../../security/withPermissionWrapper";

function AdminTranslationsPage(props) {
  const [loading, setLoading] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [languages, setLanguages] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState({});
  const [selectedTranslation, setSelectedTranslation] = useState();
  const [editLanguageModalVisible, setEditLanguageModalVisible] =
    useState(false);
  const [jobs, setJobs] = useState([]);
  const [newLanguageModalVisible, setNewLanguageModalVisible] = useState(false);
  const [importCsvModalVisible, setImportCsvModalVisible] = useState(false);
  const [
    downloadTranslationsFilterModalVisible,
    setDownloadTranslationsFilterModalVisible,
  ] = useState(false);

  const {trial} = useContext(TrialContext);

  const { t } = props;

  function parseError(e, fatal) {
    if (e.message) {
      setError({ message: e.message, fatal: fatal });
    } else if (typeof e == "string") {
      setError({ message: e, fatal: fatal });
    } else {
      setError({ message: JSON.stringify(e), fatal: fatal });
    }
  }
  useEffect(() => {
    doSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const initLanguages = async () => {
    const languages = (await getLanguages()).map((l) => ({
      key: l.languageCode,
      value: l.languageCode,
      text: l.languageName,
    }));
    setLanguages(languages);

    if (!selectedLanguage || !selectedLanguage) {
      setSelectedLanguage(
        languages && languages.length > 0 ? languages[0].key : undefined
      );
    }
  };

  const initJobs = async () => {
    const result = await getBackgroundJobs();
    setJobs(result);
  };
  useEffect(() => {
    const init = async () => {
      try {
        await initLanguages();
        await initJobs();

        setLoading(false);
      } catch (e) {
        console.error("Error intializing Admin Translations:", e);
        parseError(e, true);
      } finally {
      }
    };
    init();
    return setInterval(initJobs, 15000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearServerCache = () => {
    InternationalisationService.clearServerCache();
  };
  const doSearch = async () => {
    if(selectedLanguage){
      const result = await searchTranslations(selectedLanguage, searchQuery);
      setSearchResults(result);
    }else{
      setSearchResults([]);
    }

  };

  const doDownloadForTranslation = async () => {
    if (selectedLanguage) {
      setLoading(true);
      try {
        const blob = await downloadForTranslation(selectedLanguage);
        saveAs(
          blob,
          trial.name +
            "-atom5-to-translate-" +
            selectedLanguage +
            "-" +
            new Date().toISOString().slice(0, 10) +
            ".zip"
        );
        setLoading(false);
      } catch (e) {
        parseError(e, true);
        setLoading(false);
      }
    }
  };

  const doDownloadForMigration = async () => {
    if (selectedLanguage) {
      setLoading(true);
      try {
        const blob = await downloadForMigration(selectedLanguage);
        saveAs(
          blob,
          trial.name +
            "-atom5-backup-translations-" +
            selectedLanguage +
            "-" +
            new Date().toISOString().slice(0, 10) +
            ".zip"
        );
        setLoading(false);
      } catch (e) {
        parseError(e, true);
        setLoading(false);
      }
    }
  };

  const doDownloadDiffsFromReferenceLanguage = async () => {
    if (selectedLanguage) {
      setLoading(true);
      try {
        const refLanguage = 'en_GB'
        const blob = await downloadDiffsFromRefLanguage(selectedLanguage, refLanguage);
        saveAs(
            blob,
            trial.name +
            "-atom5-diffs-" +
            selectedLanguage +
            "-from-" +
            refLanguage +
            "-" +
            new Date().toISOString().slice(0, 10) +
            ".csv"
        );
        setLoading(false);
      } catch (e) {
        parseError(e, true);
        setLoading(false);
      }
    }
  };

  const doDownloadQuestionnaireDefinitionsTranslatableReport = async () => {
    if (selectedLanguage) {
      setLoading(true);
      try {
        const blob = await downloadQuestionnaireDefinitionsTranslatableReport(selectedLanguage);
        saveAs(
            blob,
            trial.name +
            "-atom5-translatable-questionnaires-" +
            selectedLanguage +
            "-" +
            new Date().toISOString().slice(0, 10) +
            ".csv"
        );
        setLoading(false);
      } catch (e) {
        parseError(e, true);
        setLoading(false);
      }
    }
  };


  const sanitize = (val) => {
    if (!val) {
      return val;
    }
    if (val.length > 100) {
      return val.substring(0, 100) + "...";
    } else {
      return val;
    }
  };
  const jobsList = (
    <div>
      {jobs.length > 0 &&
        jobs.map((j) => (
          <p
            title={j.unmatched?.map((x) => {
              return x + ",\n";
            })}
          >
            {j.name} with status:{j.status} started at {j.startedAt}{" "}
            {j.message ? "(" + j.message + ")" : ""}
          </p>
        ))}
      {jobs.length === 0 && <p>No imports running</p>}
    </div>
  );
  const table = (
    <Table selectable columns={4}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ minHeight: "40px" }}>
            {t("TRANSLATION_CLIENT", "Client")}
          </Table.HeaderCell>
          <Table.HeaderCell style={{ minHeight: "40px" }}>
            {t("TRANSLATION_CODE", "Code")}
          </Table.HeaderCell>
          <Table.HeaderCell style={{ minHeight: "40px" }}>
            {t("TRANSLATION_ENGLISH", "English")}
          </Table.HeaderCell>
          <Table.HeaderCell style={{ minHeight: "40px" }}>
            {languages.find((l) => l.key === selectedLanguage)?.text}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {searchResults.map((line) => {
          return (
            <Table.Row
              key={line.code}
              onClick={(e) => {
                setSelectedTranslation(line);
              }}
            >
              <Table.Cell>{line.client}</Table.Cell>
              <Table.Cell>{line.code}</Table.Cell>
              <Table.Cell title={line.translationDefault}>
                {sanitize(line.translationDefault)}
              </Table.Cell>
              <Table.Cell title={line.translation}>
                {sanitize(line.translation)}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );

  return (
    <Page
      name="Admin Translation Utilities"
      header={t("ADMIN_TRANSLATION_HEADER", "Admin Translations")}
    >
      {error && error.fatal && (
        <Message
          error
          header={t("GLOBAL_ERROR_TITLE", "Error")}
          content={
            "Fatal error, if it persist contact support:" + error.message
          }
        />
      )}
      {loading && <Loader active={loading} />}
      {!loading && (
        <>
          <Message
              warning
              header={t("TRANSLAITON_CACHE_INFO", "Note")}
              content={
                "Make sure to clear caches once you have completed editing the translations, so the changes are visible. "
              }
          />
          <Grid columns={"equal"}>
            <Grid.Column>
              <Form onSubmit={doSearch}>
                <Form.Field>
                  <label>{t("SELECT_LANGUAGE", "Select Language")}</label>
                  <Dropdown
                    name="selectedLanguage"
                    selection
                    options={languages}
                    value={selectedLanguage}
                    onChange={(e, data) => {
                      e.preventDefault();
                      setSelectedLanguage(data.value);
                    }}
                  />
                </Form.Field>

                <Form.Field>
                  <label>{t("ADMIN_UTILS_QUERY", "Search Term")}</label>
                  <input
                    name="query"
                    value={searchQuery}
                    onChange={(e) => {
                      e.preventDefault();
                      setSearchQuery(e.target.value);
                    }}
                  />
                </Form.Field>

                <Button type="submit" primary>
                  {t("GLOBAL_BUTTON_SEARCH", "Search")}
                </Button>
                <Button
                  type="submit"
                  secondary
                  onClick={() =>
                    setSelectedTranslation({
                      language: selectedLanguage,
                      createNew: true,
                    })
                  }
                >
                  {t("TRANSLATION_CREATE_NEW", "New Translation")}
                </Button>
                <Button
                  type="submit"
                  secondary
                  onClick={() => setEditLanguageModalVisible(true)}
                >
                  {t("TRANSLATION_LANGUAGE_EDIT", "Edit Language")}
                </Button>
                <Button
                  type="submit"
                  secondary
                  onClick={doDownloadForTranslation}
                >
                  {t("TRANSLATION_DOWNLOAD_FOR_TRANSLATION", "Translation")}
                </Button>
                <Button
                  type="submit"
                  secondary
                  onClick={() =>
                    setDownloadTranslationsFilterModalVisible(true)
                  }
                >
                  {t(
                    "TRANSLATION_DOWNLOAD_FOR_TRANSLATION_SINGLE_FILE",
                    "Translation (Single file)"
                  )}
                </Button>
                <Button
                  type="submit"
                  secondary
                  onClick={doDownloadForMigration}
                >
                  {t("TRANSLATION_DOWNLOAD_FOR_MIGRATION", "Backup")}
                </Button>
                <Button
                  type="submit"
                  secondary
                  onClick={() => setImportCsvModalVisible(true)}
                >
                  {t("TRANSLATION_UPLOAD_TRANSLATIONS", "Import CSV")}
                </Button>
                <Button
                  type="submit"
                  secondary
                  onClick={() => {
                    setNewLanguageModalVisible(true);
                  }}
                >
                  {t("TRANSLATION_UPLOAD_NEW_LANGUAGE", "New Language")}
                </Button>
                <Button type="submit" tertiary onClick={clearServerCache}>
                  {t("ADMIN_UTILS_CLEAR_TRANSLATION_CACHE", "Clear Caches")}
                </Button>
              </Form>
            </Grid.Column>

          </Grid>
          <Grid>
            <Grid.Row>
              <p style={{'paddingLeft':'20px', cursor: 'pointer', textDecoration:"underline"}}
                  onClick={doDownloadDiffsFromReferenceLanguage}
              >
                {t("TRANSLATION_DOWNLOAD_DIFFS", "Diffs from Reference Language")}
              </p>
            </Grid.Row>
              <Grid.Row>
                <p style={{'paddingLeft':'20px', cursor: 'pointer', textDecoration:"underline"}}
                  onClick={doDownloadQuestionnaireDefinitionsTranslatableReport}
              >
                {t("TRANSLATION_QD_CHECK", "Check Translatable Questionnaires")}
              </p>

            </Grid.Row>
          </Grid>
          <Segment style={{ overflow: "auto" }}>{jobsList}</Segment>
          <Segment style={{ overflow: "auto" }}>
            <Header as="h4">
              {t("TRANSLATION_RESULTS", "Search Results")}
            </Header>
            {table}
          </Segment>
        </>
      )}
      <EditTranslationModal
        open={selectedTranslation !== undefined}
        onClose={() => {
          setSelectedTranslation(undefined);
          doSearch();
        }}
        record={selectedTranslation}
      />

      <EditLanguageModal
        open={editLanguageModalVisible}
        onClose={() => {
          setEditLanguageModalVisible(false);
          setNewLanguageModalVisible(false);
          initLanguages().then(() => doSearch());
        }}
        createNew={false}
        languageCode={selectedLanguage}
        languageName={languages.find((l) => l.key === selectedLanguage)?.text}
      />

      <EditLanguageModal
        open={newLanguageModalVisible}
        onClose={() => {
          setEditLanguageModalVisible(false);
          setNewLanguageModalVisible(false);
          initLanguages().then(() => doSearch());
        }}
        createNew={true}
        languageCode={""}
        languageName={""}
      />

      <UploadTranslationsModal
        open={importCsvModalVisible}
        onClose={() => {
          setImportCsvModalVisible(false);
          initLanguages().then(() => doSearch());
        }}
      />

      <DownloadTranslationsFiltersModal
        open={downloadTranslationsFilterModalVisible}
        language={selectedLanguage}
        trial={trial}
        onClose={() => {
          setDownloadTranslationsFilterModalVisible(false);
          initLanguages().then(() => doSearch());
        }}
      />
    </Page>
  );
}

const withEnhancements = (options) => compose(
  withPermissionWrapper(options),
  withTranslation()
);
export default withEnhancements({ permissionFunctionDelegate: StaffPermissionService.canManageTranslations })(AdminTranslationsPage);
