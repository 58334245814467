import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Message, Grid, Form, Icon, Loader, Table, Dropdown } from "semantic-ui-react";
import Page from "../../components/page/Page";
import ConfigService from "../../services/admin/ConfigService";
import ClientsDropdown from "./ClientsDropdown";
import ClientsOptions from "./ClientsOptions";
import AdminConfigToolsCreateOrEditPage from "./AdminConfigToolsCreateOrEditPage"
import traverse from "traverse";
import FileSaver from 'file-saver';
import StaffPermissionService from "../../services/StaffPermissionService";
import { compose } from "redux";
import withPermissionWrapper from "../../security/withPermissionWrapper";

const AdminConfigToolsListPage = ({ t }) => {
  
  const [error, setError] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [loadedList, setLoadedList] = useState([]);
  const [selectedDropdown, setSelectedDropdown] = useState(ClientsOptions.getDefaultDisplayLabels());

  const [addingNew, setAddingNew] = useState(false);

  useEffect(() => {
    initialise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selected) {
      setAddingNew(true);
    }
  }, [selected]);

  const initialise = async () => {
    try {
      setLoading(true);
      await loadConfiguration();
    } finally {
      setLoading(false);
    }
  };
  
  const filterDataByDropdownAndSearchTerm = () => {

    //filter on dropdown and on key or value matching searchTerm
    let newList = loadedList.filter((item) => selectedDropdown.indexOf(item.client) !== -1);

    if (searchTerm.length > 0) {
      newList = newList.filter((item) => item.key.toLowerCase().indexOf(searchTerm.trim().toLowerCase()) >= 0
                || item.value.toLowerCase().indexOf(searchTerm.trim().toLowerCase()) >= 0);
    }

    return newList;
  };
  
  const loadConfiguration = async () => {
    try {
      const data = await ConfigService.getConfig();
      if (data) {
        setLoadedList(data);
        setError(false);
      } else {
        setError(true);
      }
    } catch (e) {
      console.error("Error fetching configuration: ", e);
      setError(true);
    }
  };
  
  const handleUpdateFilterHandler = (data) => {
    setSelectedDropdown(data.value);
  };

  const makeClickable = (configurationItem) => {
    if (configurationItem.editable) {
      setSelected(configurationItem);
    }
  }

  const createTable = () => {
    let list = filterDataByDropdownAndSearchTerm();

    if (!list) {
      return;
    }

    let nonEditableItems = ConfigService.getNonEditableConfigItems();

    nonEditableItems = nonEditableItems === undefined ? "" : nonEditableItems.split(",");
    list = list.map((item) => {
      item.editable = nonEditableItems.includes(item.key) 
            || item.client === 'System' ? false : true;

      return item;
    });

    const headers = [
      { title: ["ADMIN_CONFIG_CODE", "Client"], config: { width: 2 } },
      { title: ["ADMIN_CONFIG_KEY", "Key"], config: { width: 3 } },
      { title: ["ADMIN_CONFIG_VALUE", "Value"], config: { width: 4 } }
    ];

    const cellStyle = { textOverflow: "elipses", whiteSpace: "nowrap" };
    const nonEditableCellStyle = { color:'red', textOverflow: "elipses", whiteSpace: "nowrap" };

    const headerRow = (
      <Table.Header>
          <Table.Row>
            {headers.map((header) => (
              <Table.HeaderCell {...header?.config}>
                {t(header.title)}
              </Table.HeaderCell>
            ))}
        </Table.Row>
      </Table.Header>
    );

    const configListRows = list.map((configurationItem, index) => {
      let pointer = configurationItem.editable ? {cursor: "pointer"} : {};
      let rowStyle = loading ? { backgroundColor: "#eee", color: "#999" } : pointer;
      return (
        <Table.Row
          key={index}
          onClick={
            loading ? () => {} : () => makeClickable(configurationItem)
          }
          style={rowStyle} >
          <Table.Cell style={configurationItem.editable ? cellStyle : nonEditableCellStyle}>
            {configurationItem.client}
          </Table.Cell>
          <Table.Cell style={configurationItem.editable ? cellStyle : nonEditableCellStyle}>
            {configurationItem.key}
          </Table.Cell>
          <Table.Cell style={configurationItem.editable ? cellStyle : nonEditableCellStyle}>
            {configurationItem.value}
          </Table.Cell>
        </Table.Row>
      )}
    );

    return (
      <>
        {headerRow}
        {configListRows}
      </>
    );
  }

  const addNewItem = () => {
    setSelected(null);
    setAddingNew(true);
  };

  const handleCancelAddNewItem = () => {
    setAddingNew(false);
  };

  const exportOptions = [
    { key: 'electron-clinician-web', text: 'Electron Clinician Web', value: 'electron-clinician-web', filename: 'electron-clinician-web.json' },
    { key: 'electron-subject-common', text: 'Electron Subject Common', value: 'electron-subject-common', filename: 'electron-subject-common.json' },
    { key: 'electron-subject-mobile', text: 'Electron Subject Mobile', value: 'electron-subject-mobile', filename: 'electron-subject-mobile.json' },
    { key: 'nucleus', text: 'Nucleus', value: 'nucleus', filename: 'nucleus.json' },
  ];

  const exportSelectChanged = (event, option) => {
    handleExport(option.value);
  };

  const handleExport = async (client) => {
    let fileContents = {};
    fileContents['code'] = client;
    fileContents['languages'] = [];
    fileContents['translations'] = {};
    
    const data = await ConfigService.getConfig();
    const dataFilteredByClient = data.filter((item) => item.client === client);
    const prefix = 'configuration.';
    dataFilteredByClient.forEach((configurationItem) => {
      const path = prefix + configurationItem.key;
      const parts = path.split(".")
      let value;
      try {
        value = JSON.parse(configurationItem.value);
      } catch (e) {
        value = configurationItem.value;
      }
      traverse(fileContents).set(parts, value);
    });
    
    var blob = new Blob([JSON.stringify(fileContents,null, 2)], {
      type: "text/plain;charset=utf-8"
    });
    const exportOption = exportOptions.find((option) => option.value === client);
    const exportFilename = exportOption?.filename || 'export.json';
    FileSaver.saveAs(blob, exportFilename);
  };

  return (
    <Page
      name="Admin Config"
      header={t("ADMIN_CONFIG_TOOLS", "Admin Config Tools")}>
        {error && (
          <Message
            error
            header={t("GLOBAL_ERROR_TITLE", "Error")}
            content={"Error, if it persist contact support:" + error.message}
          />
        )}
        {loading && loadedList.length === 0 && (
          <Loader active={loading} />
        )}
        
        {!loading && (
          <Grid>
            <Grid.Row>
              <Grid.Column width={addingNew ? 8 : 16}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <ClientsDropdown
                        multiple={true}
                        selectedDropdown={selectedDropdown}
                        onChangeHandler={handleUpdateFilterHandler}/>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Form>
                          <Form.Field>
                            <Form.Input
                              icon={<Icon name="search" />}
                              iconPosition={"left"}
                              value={searchTerm}
                              placeholder={t("ADMIN_KEY_OR_VALUE_FILTER", "Key or value filter")}
                              onChange={(e, v) => {
                                setSearchTerm(v.value);
                              }}
                            />
                          </Form.Field>
                        </Form>
                    </Grid.Column>
                    <Grid.Column width={2}>
                      {!addingNew && <Form>
                        <Button
                          fluid
                          primary
                          onClick={addNewItem}
                        >
                        {t("ADMIN_CONFIG_NEW", "Create New")}
                        </Button>
                      </Form>}
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <Form>
                        <Button.Group
                          primary>
                          <Button>{t("ADMIN_EXPORT_BUTTON", "Export")}</Button>
                          <Dropdown id='exportDropdown'
                            className='button icon'
                            floating
                            options={exportOptions}
                            onChange={exportSelectChanged}
                            trigger={<></>}
                          />
                        </Button.Group>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <Table fixed id={"adminConfigList"}>
                  {createTable()}
                </Table>

              </Grid.Column>
              {addingNew && <Grid.Column width={8}>
                    <AdminConfigToolsCreateOrEditPage
                      selected={selected}
                      onlyDefaultClients={true}
                      loadedList={loadedList}
                      loadConfiguration={loadConfiguration}
                      handleCancelAddNewItem={handleCancelAddNewItem}/>           
              </Grid.Column>}

            </Grid.Row>
          </Grid>
        )}
    </Page>
  );
}

const withEnhancements = (options) => compose(
  withPermissionWrapper(options),
  withTranslation()
);
export default withEnhancements({ permissionFunctionDelegate: StaffPermissionService.canManageConfig })(AdminConfigToolsListPage);
