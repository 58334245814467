import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Loader, Message } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import Page from "../../components/page/Page";
import TriggerService from "../../services/admin/TriggerService";
import AdminTriggerEditComponent from "../../components/admin/AdminTriggerEditComponent";
import { useHistory } from "react-router-dom";
import StaffPermissionService from "../../services/StaffPermissionService";
import { compose } from "redux";
import withPermissionWrapper from "../../security/withPermissionWrapper";

const DEFAULT_BLANK_TRIGGER = { isActive: true };

function AdminTriggerCreateEditPage({ t }) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);

  const history = useHistory();
  const params = useParams();
  const { triggerId } = params;
  const isEditing = !!triggerId;

  const getTrigger = async (triggerId) => {
    const trigger = await TriggerService.getTrigger(triggerId);
    if (trigger) {
      setTrigger(trigger);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const onChangeTrigger = (_e, data) => {
    let value = data.type === "checkbox" ? data.checked : data.value;
    setTrigger((staleTrigger) => {
      return { ...staleTrigger, [data.name]: value };
    });
  };

  const submitTrigger = async (feedback) => {
    let response = [];
    if (isEditing) {
      response = await TriggerService.editTrigger(
        trigger,
        feedback
      );
    } else {
      response = await TriggerService.createTrigger(trigger, feedback);
    }

    if (!Array.isArray(response)) {
      setError(true);
      return;
    }

    if (response.length > 0) {
      setValidationErrors(response);
      return;
    }

    history.push("/app/utils/triggers");
  };

  const onSoftDelete = async (feedback) => {
    const newIsActive = !trigger.isActive;
    await TriggerService.updateTriggerIsActive(
      trigger.id,
      newIsActive,
      feedback
    );
    history.push("/app/utils/triggers");
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (isEditing) {
      getTrigger(triggerId);
    } else {
      setTrigger(DEFAULT_BLANK_TRIGGER);
    }
  }, [triggerId, isEditing]);

  return (
    <Page
      name="Admin Trigger Utilities"
      header={t("ADMIN_TRIGGER_HEADER", "Admin Triggers")}
    >
      {error && (
        <Message
          error
          header={t("GLOBAL_ERROR_TITLE", "Error")}
          content={"Error, if it persist contact support"}
        />
      )}
      {loading && <Loader active={loading} />}
      {!loading && trigger && (
        <>
          <AdminTriggerEditComponent
            trigger={trigger}
            onChange={onChangeTrigger}
            onSubmit={submitTrigger}
            onSoftDelete={onSoftDelete}
            validationErrors={validationErrors}
          />
        </>
      )}
    </Page>
  );
}

const withEnhancements = (options) => compose(
  withPermissionWrapper(options),
  withTranslation()
);
export default withEnhancements({ permissionFunctionDelegate: StaffPermissionService.canManageTriggers })(AdminTriggerCreateEditPage);
