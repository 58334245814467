import React, { useContext } from "react";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import RoleEditComponent from "../../components/roles/RoleEditComponent";
import TrialContext from "../../context/TrialContext";
import RoleService from "../../services/RoleService";
import { useHistory } from "react-router-dom";
import { compose } from "redux";
import withPermissionWrapper from "../../security/withPermissionWrapper";
import StaffPermissionService from "../../services/StaffPermissionService";

function RolesNewPage({ t }) {
  const {trial} = useContext(TrialContext);
  const history = useHistory();

  async function handleSubmit(newRole, feedback) {
    await RoleService.newRole(newRole, feedback);
    history.push("/app/roles");
  }

  return (<Page
    name="ROLES_NEW"
    header={t("ROLES_NEW_HEADER", "New Role")}
    subheader={t("ROLES_NEW_SUBHEADER", "Create a new Role")}
    loading={false}
    >
    <RoleEditComponent
      onSubmit={handleSubmit}
      trial={trial}
      roleToEdit={{ code: '', label: '', override: '', creatableRolesOverride: [], permissions: [] }}
    />

  </Page>);
}

const withEnhancements = (options) => compose(
  withPermissionWrapper(options),
  withTranslation()
);
export default withEnhancements({ permissionFunctionDelegate: StaffPermissionService.canManageRoles })(RolesNewPage);
