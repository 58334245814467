import React, {useState} from "react";
import {withTranslation} from "react-i18next";
import {Button, Grid, Table} from "semantic-ui-react";
import UpdateLogLevelModal from "../../components/admin/techsupport/UpdateLogLevelModal";
import Page from "../../components/page/Page";
import SubjectCodeLookup from "../../components/subjects/SubjectCodeLookup";
import {DateTime} from "luxon";
import SensorDataService from "../../services/sensordata/SensorDataService";
import _ from "lodash";
import DateTimeField from "../../components/DateTimeField";
import moment from "moment/moment";
import { saveAs } from "file-saver";
import { compose } from "redux";
import withPermissionWrapper from "../../security/withPermissionWrapper";
import StaffPermissionService from "../../services/StaffPermissionService";

const AdminTechSupportPage = (props) => {
    const {t} = props;
    const [modal, setModal] = useState()
    const [subject, setSubject] = useState()
    const [stats, setStats] = useState()
    const [logs, setLogs] = useState()
    const [date, setDate] = useState(DateTime.now().toISODate())
    const [loading, setLoading] = useState(false);
    const downloadGarminLogs = async (e) => {
        e.preventDefault();
        setLoading(true);
        const subjectId = subject ? subject.subjectId : undefined
        const subjectCode = subject ? subject.subjectCode : undefined
        const fromDate = date;
        const toDate = date;
        const blob = await SensorDataService.downloadDeviceLogs(subjectId, fromDate, toDate, 'GarminService')
        setLoading(false);
        saveAs(
            blob,
            subjectCode + '-' +
            subjectId +
            "-device-logs-" +
            toDate.slice(0, 10) +
            ".log"
        );

    };

    const getGarminStats = async (e) => {
        e.preventDefault();
        const subjectId = subject ? subject.subjectId : undefined
        const fromDate = date;
        const toDate = date;
        const garminStats = await SensorDataService.getStats(subjectId, fromDate, toDate)
        setStats(garminStats)
        const garminLogs = await SensorDataService.getDeviceLogs(subjectId, fromDate, toDate, 'GarminService')
        setLogs(garminLogs)
    };
    return (
        <Page
            name="Tech Support"
            header={t("ADMIN_TECH_SUPPORT", "Tech Support")}
        >
            <UpdateLogLevelModal open={modal === 'UpdateLogLevel'} onClose={() => setModal(undefined)} t={t}/>
            <Table selectable>
                <Table.Header>
                    <Table.Row key={"header"}>
                        <Table.HeaderCell key={"item"}>
                            {t("ADMIN_TECH_SUPPORT_ACTTIVITY", "Activity")}
                        </Table.HeaderCell>
                        <Table.HeaderCell key={"action"}>
                            {t("ADMIN_TECH_SUPPORT_ACTION", "Action")}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>Logging</Table.Cell>
                        <Table.Cell>
                            <Button
                                primary
                                onClick={() => setModal('UpdateLogLevel')}
                            >
                                Update Log Level
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <Grid>
                <Grid.Column width={4}>
                    <SubjectCodeLookup
                        onChange={(subjects) => {
                            setSubject(subjects && subjects.length > 0 ? subjects[0] : undefined)
                        }}
                        value={subject ? [subject] : []}
                    />
                </Grid.Column>
                <Grid.Column width={4}>
                    <DateTimeField
                        value={moment(date)}
                        onChange={(v) => {
                            if (v.isValid()) {
                                setDate(v.format("YYYY-MM-DD"));
                            }
                        }}
                        mode={"date"}
                        readOnly={false}
                        nowOnly={false}
                    />
                </Grid.Column>
                <Grid.Column width={6}>

                    <Button type='submit' secondary onClick={getGarminStats}>
                        {t('GET_GARMIN_STATS', 'Get Garmin Stats')}
                    </Button>
                    <Button type='submit' secondary onClick={downloadGarminLogs}>
                        {!loading ? t('DOWNLOAD_GARMIN_LOGS', 'Download Garmin Logs') : t('DOWNLOAD_IN_PROGRESS', 'Downloading..')}
                    </Button>
                </Grid.Column>
            </Grid>
            <div>
                {_.keys(stats).sort().map(k => {
                    return <div>{k}: {stats[k]}</div>
                })}
                {stats &&
                    <div>-----------------------------------------------------------------------------------------</div>}
            </div>

            <div>
                {logs?.map(l => {
                    return <div style={{'white-space': 'pre-wrap'}} title={l.metadata}>{l.timestamp} {l.message}</div>
                })}
            </div>
        </Page>
    );
};


const withEnhancements = (options) => compose(
    withPermissionWrapper(options),
    withTranslation()
);
export default withEnhancements({ permissionFunctionDelegate: StaffPermissionService.canAccessTechSupportFeatures })(AdminTechSupportPage);
