import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { typeHelper } from 'atom5-branching-questionnaire';
import { DISPLAY_TIME_FORMAT } from './constants/DISPLAY_TIME_FORMAT';
import ControlPanel from './components/ControlPanel';
import { Transition } from "semantic-ui-react";
import { useFullScreen } from '../../../context/FullScreenContext';
import ReactPlayer from 'react-player';
import RegionSelector from "./RegionSelector";
import useKey from "../../../hooks/useKey";


const defaultOptions = {
  defaultTimeDisplayUnit: DISPLAY_TIME_FORMAT.MINUTES_SECONDS.value,
  allowMuteChange: true,
  isMonitoredVideo: false,
  isControlPanelLockedOpen: false

};

const defaultTimeSkipOptions = {
  isEnabled: true,
  sizes: [0.01, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 2, 5, 10, 20, 30, 60]
};

const defaultPlaybackRateOptions = {
  isEnabled: true,
  rates: [0.1, 0.2, 0.5, 0.75, 1, 1.25, 1.5, 2, 5]
};

const defaultAnnotationOptions = {
  isEnabled: false, // AT-2043
  categories: [
    { code: 'general', titleDefault: 'General', titleTranslationKey: 'ENHANCED_VIDEO_PLAYER_MARKERS_CATEGORY_TITLE_GENERAL' },
  ]
};

export const ANNOTATION_TYPE = {
  INSTANT: { value: "INSTANT", defaultText: "Instant" },
  INTERVAL: { value: "INTERVAL", defaultText: "Interval" }
}


const VideoPlayer = forwardRef((props, ref) => {

  const {
    videoData,
    question,
    options: passedOptions,
    timeSkipOptions: passedTimeSkipOptions,
    playbackRateOptions: passedPlaybackRateOptions,
    annotationOptions: passedAnnotationOptions,
    annotations: passedAnnotations,
    expandedView = false,
    onReady,
    onError,
    onPlayingComplete,
    onTimeUpdate,
    changeAnswerMapValue,
    getAnswerMapValue
  } = props;

  const fullScreenContext = useFullScreen();
  const options = { ...defaultOptions, ...passedOptions };
  const timeSkipOptions = { ...defaultTimeSkipOptions, ...passedTimeSkipOptions };
  const playbackRateOptions = { ...defaultPlaybackRateOptions, ...passedPlaybackRateOptions };
  const annotationOptions = { ...defaultAnnotationOptions, ...passedAnnotationOptions };

  const playerRef = useRef();
  const portalTarget = useRef();
  const [isReady, setIsReady] = useState(false);
  const isUsingRegionSelector = typeHelper.parseBool(question?.config?.shouldUseRegionSelector);

  const connections = question?.config?.videoPlayer?.connections;

  /// Left in case we need to add a toggle to deactivate shortcutKeys
  const [shortcutKeysActive,] = useState(true);

  React.useImperativeHandle(ref, () => ({
    setAnnotations(annotations) {
      setAnnotations(annotations);
    }
  }));

  useEffect(() => {
    if (isReady === false) {
      return;
    }
    const internalPlayer = getInternalPlayer();
    if (internalPlayer == null) {
      console.error('Error: internalPlayer is NULL');
      return;
    }

    const listeners = [
      { object: internalPlayer, event: 'timeupdate', handler: handleVideoTimeUpdate }
    ];
    if (options?.isMonitoredVideo) {
      listeners.push({ object: internalPlayer, event: 'seeking', handler: handleVideoSeeking });
      listeners.push({ object: internalPlayer, event: 'seeked', handler: handleVideoSeeked });
    }

    for (const listener of Object.values(listeners)) {
      listener.object.addEventListener(listener.event, listener.handler);
    }

    return () => {
      for (const listener of Object.values(listeners)) {
        listener.object.removeEventListener(listener.event, listener.handler);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  const handleVideoTimeUpdate = () => {
    if (onTimeUpdate) {
      onTimeUpdate(sToMs(getCurrentTime()))
    }

    if (options?.isMonitoredVideo === false) {
      const vp = { ...viewingProgressRef.current };
      vp.currentTimeMilliseconds = sToMs(getCurrentTime());
      setViewingProgress(vp);
      return;
    }

    const vp = { ...viewingProgressRef.current };
    if (vp.currentTimeMilliseconds > vp.latestViewedTimeMilliseconds) {
      vp.latestViewedTimeMilliseconds = vp.currentTimeMilliseconds;
    }

    vp.currentTimeMilliseconds = sToMs(getCurrentTime());
    setViewingProgress(vp);
  };

  const handleVideoSeeking = () => {
    if (options?.isMonitoredVideo === false) {
      return;
    }

    const vp = { ...viewingProgressRef.current };
    if (vp.isManualSeeking === false) {
      vp.isManualSeeking = true;
      vp.seekTo = sToMs(getCurrentTime());
      vp.latestViewedTimeAtManualSeekStart = vp.latestViewedTimeMilliseconds;
      setViewingProgress(vp);
    }
  };

  const handleVideoSeeked = () => {
    if (options?.isMonitoredVideo === false) {
      return;
    }

    const vp = { ...viewingProgressRef.current };
    if (vp.isManualSeeking) {
      if (vp.seekTo > vp.latestViewedTimeAtManualSeekStart) {
        _setCurrentPlayerTime(msToS(vp.latestViewedTimeAtManualSeekStart));
      }
    }

    vp.latestViewedTimeMilliseconds = vp.latestViewedTimeAtManualSeekStart;
    vp.isManualSeeking = false;
    vp.seekTo = null;

    setViewingProgress(vp);
  };

  const [viewingProgress, _setViewingProgress] = useState({
    // Time
    durationMilliseconds: undefined,
    currentTimeMilliseconds: 0,
    latestViewedTimeMilliseconds: 0,
    // Frame
    frameRate: videoData?.frameRate,
    currentFrameIndex: 0,
    // Seeking
    isManualSeeking: false,
    latestViewedTimeAtManualSeekStart: 0,
    seekTo: null
  });
  const viewingProgressRef = useRef(viewingProgress);
  const setViewingProgress = (obj) => {
    viewingProgressRef.current = obj;
    _setViewingProgress(obj);
  };

  const [controlState, _setControlState] = useState({
    timeDisplayUnit: options.defaultTimeDisplayUnit,
    isControlPanelVisible: true,
    isControlPanelLockedOpen: options.isControlPanelLockedOpen,
    isPlaying: false,
    isMuted: true,
    playbackRate: playbackRateOptions.defaultPlaybackRate,
    timeSkipSize: timeSkipOptions.defaultSkipSize,
    isFullScreen: false,
    isExpandedView: expandedView,
    volume: 0.5
  });
  const controlStateRef = useRef(controlState);
  const setControlState = (obj) => {
    controlStateRef.current = obj;
    _setControlState(obj);
  };

  const [annotations, setAnnotations] = useState(passedAnnotations != null ? passedAnnotations : []);

  const fullScreenContainerRef = useRef(null);

  const autoHideControlPanelTimeoutHandle = useRef();

  useEffect(() => {
    return () => {
      clearTimeout(autoHideControlPanelTimeoutHandle.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function nextFrame(event) {
    let skipTime = controlState.timeSkipSize === undefined ? 1 : controlState.timeSkipSize;

    if (event.shiftKey) {
      skipTime *= 0.1;
    }
    const desiredTimeMs = viewingProgress.currentTimeMilliseconds + skipTime * 1000;
    let time = desiredTimeMs;
    if (desiredTimeMs < 0) {
      time = 0;
    } else if (desiredTimeMs > viewingProgress.durationMilliseconds) {
      time = viewingProgress.durationMilliseconds;
    }

    const timeInSeconds = msToS(time);
    _setCurrentPlayerTime(timeInSeconds);
  }
  function previousFrame(event) {
    let skipTime = controlState.timeSkipSize === undefined ? 1 : controlState.timeSkipSize;
    if (event.shiftKey) {
      skipTime *= 0.1;
    }
    const desiredTimeMs = viewingProgress.currentTimeMilliseconds - skipTime * 1000;
    let time = desiredTimeMs;
    if (desiredTimeMs < 0) {
      time = 0;
    } else if (desiredTimeMs > viewingProgress.durationMilliseconds) {
      time = viewingProgress.durationMilliseconds;
    }

    const timeInSeconds = msToS(time);
    _setCurrentPlayerTime(timeInSeconds);
  }

  useKey("ArrowLeft",
    (event) => {
      if (shortcutKeysActive) previousFrame(event);
    }
  );

  useKey("ArrowRight",
    (event) => {
      if (shortcutKeysActive) nextFrame(event);
    }
  );

  useEffect(() => {

    // Frame
    const frameRate = typeHelper.parseNumber(viewingProgress.frameRate);
    if (isNaN(frameRate)) {
      setViewingProgress({ ...viewingProgress, currentFrameIndex: NaN });
      return;
    }
    const currentTimeSeconds = msToS(viewingProgress.currentTimeMilliseconds);
    const frameIndex = currentTimeSeconds * frameRate;
    setViewingProgress({ ...viewingProgress, currentFrameIndex: frameIndex });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewingProgress.currentTimeMilliseconds, viewingProgress.frameRate])

  /// Helper function
  const getValueFromPath = (path, obj) => {
    const parts = path.split('.');
    let current = obj;
    for (const part of parts) {
      if (part.includes('[')) {
        const [key, index] = part.split(/\[|\]/).filter(Boolean);
        current = current[key][parseInt(index)];
      } else {
        // nosemgrep
        current = current[part];
      }
      if (current === undefined) {
        console.error("Path could not be processed: " + path);
        return undefined;
      }
    }
    return current;
  }

  useEffect(() => {
    if (changeAnswerMapValue && connections && annotations.length > 0) {
      connections.forEach(item => {
        const value = getValueFromPath(item.answer, { annotations });
        changeAnswerMapValue(item.questionCode, value, true);
      });
    }
  }, [connections, annotations, changeAnswerMapValue]);

  const handleControlClick = (action, data) => {
    switch (action) {
      // Control Panel Lock / Unlock
      case 'controlpanel-lock':
        setControlState({ ...controlState, isControlPanelLockedOpen: true, isControlPanelVisible: true });
        ensureControlPanelState();
        break;
      case 'controlpanel-unlock':
        setControlState({ ...controlState, isControlPanelLockedOpen: false });
        break;

      // Play / Pause
      case 'play':
        setControlState({ ...controlState, isPlaying: true });
        break;
      case 'pause':
        setControlState({ ...controlState, isPlaying: false });
        break;

      // Playback Rate
      case 'playbackrate':
        if (data.action === 'ratechange') {
          setControlState({ ...controlState, playbackRate: data.value });
        }
        break;

      // Time Skip
      case 'timeskip':
        if (data.action === 'sizechange') {
          setControlState({ ...controlState, timeSkipSize: data.value });
        } else if (data.action === 'skip') {
          const desiredTimeMs = viewingProgress.currentTimeMilliseconds + data.value;
          let time = desiredTimeMs;
          if (desiredTimeMs < 0) {
            time = 0;
          } else if (desiredTimeMs > viewingProgress.durationMilliseconds) {
            time = viewingProgress.durationMilliseconds;
          }

          const timeInSeconds = msToS(time);
          _setCurrentPlayerTime(timeInSeconds);
        }
        break;

      // Annotations
      case 'annotations':
        if (data.action === 'update') {
          setAnnotations(data.value);
        }
        break;

      // Time Display Units
      case 'timeFormat':
        if (data.action === 'formatchange') {
          setControlState({ ...controlState, timeDisplayUnit: data.value });
        }
        break;

      // Mute / Unmute
      case 'mute':
        setControlState({ ...controlState, isMuted: true });
        break;
      case 'unmute':
        setControlState({ ...controlState, isMuted: false });
        break;
      case 'volume':
        if (data.action === 'volumechange') {
          setControlState({ ...controlState, volume: data.value });
        }
        break;
      // FullScreen
      case 'fullscreen-enter':
        requestFullScreen(true);
        break;
      case 'fullscreen-exit':
        requestFullScreen(false);
        break;

      // Expanded View
      case 'expandedview-enter':
        setControlState({ ...controlState, isExpandedView: true });
        break;
      case 'expandedview-exit':
        setControlState({ ...controlState, isExpandedView: false });
        break;

      default:
        console.error('[VideoPlayer][handleControlClick] Unhandled action', action, data);
    }
  };

  useEffect(() => {
    // Handle keyboard shortcut escape within the full screen container, handled in the DOM - this is the only cae where full state may change outside of our control here.
    if (fullScreenContext.isFullScreen === false) {
      requestFullScreen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullScreenContext.isFullScreen]);

  const requestFullScreen = (isFullScreenRequested) => {
    try {
      fullScreenContext.setIsFullScreen(
        fullScreenContainerRef,
        isFullScreenRequested
      );
      setControlState({
        ...controlState,
        isFullScreen: isFullScreenRequested,
        isExpandedView: !isFullScreenRequested
      });
    } catch (error) {
      console.error('[VideoPlayer][requestFullScreen] Error', error);
      setControlState({ ...controlState, isFullScreen: false });
    }
  };

  const handleHideControlPanelTimeout = () => {
    clearTimeout(autoHideControlPanelTimeoutHandle.current);
    // controlState is old at this point, as it is the state at the point the setTimeout is created - by design in react
    // so we use the ref version here
    setControlState({ ...controlStateRef.current, isControlPanelVisible: false });
  }

  const handlePlayerMouseMove = () => {
    ensureControlPanelState();
    if (!controlState.isControlPanelLockedOpen) {
      autoHideControlPanelTimeoutHandle.current = setTimeout(handleHideControlPanelTimeout, 2500);
    }
  }

  const handleControlPanelMouseMove = () => {
    ensureControlPanelState();
  }

  const ensureControlPanelState = () => {
    if (controlState.isControlPanelLockedOpen) {
      clearTimeout(autoHideControlPanelTimeoutHandle.current);
      return;
    }
    if (!controlState.isControlPanelVisible) {
      setControlState({ ...controlState, isControlPanelVisible: true });
    }
    clearTimeout(autoHideControlPanelTimeoutHandle.current);
  }

  const containerStyle = {
    ...styles.containerCore,
    ...(controlState.isExpandedView ? styles.containerExpandedView : styles.containerDefault)
  };

  const handleMediaLoadError = (e) => {
    console.error('handleMediaLoadError', e);
    if (onError != null) {
      onError(e);
    }
  };

  const handleMediaReady = async () => {
    setIsReady(true);
    const player = getPlayer();
    if (player == null) {
      console.error('Error: player is NULL');
      return;
    }

    const durationMilliseconds = sToMs(player.getDuration()); // getDuration: Returns the duration (in seconds) of the currently playing media
    setViewingProgress({ ...viewingProgress, durationMilliseconds });
    setControlState({ ...controlStateRef.current, durationMilliseconds: durationMilliseconds });
    if (onReady != null) {
      onReady();
    }
  };

  const getPlayer = () => {
    const player = playerRef?.current;
    return player;
  };

  const getInternalPlayer = () => {
    const player = getPlayer();
    if (player != null) {
      return player.getInternalPlayer();
    }
    return null;
  };

  const getCurrentTime = () => {
    const player = getPlayer();
    if (player != null) {
      return player.getCurrentTime();
    }
    return null;
  };

  const _setCurrentPlayerTime = (time) => {
    const player = getPlayer();
    if (player == null) {
      console.error('Error: player is NULL');
      return;
    }

    const isDecimal = time % 1 !== 0;
    const seekType = isDecimal ? 'fragment' : 'seconds';
    player.seekTo(time, seekType);
  };

  const handleMediaEnded = () => {
    setControlState({ ...controlState, isPlaying: false });
    if (onPlayingComplete != null) {
      onPlayingComplete();
    }
  };

  const handleProgressChange = (timeMs) => {
    const timeInSeconds = msToS(timeMs);
    _setCurrentPlayerTime(timeInSeconds);
  };

  const sToMs = (s) => {
    return s * 1000;
  };

  const msToS = (ms) => {
    return ms / 1000;
  }

  // The RegionSelector moves the video controls down
  const controlBarAdditionalStyles = {};
  if (isUsingRegionSelector) {
    controlBarAdditionalStyles.backgroundColor = '#222';
    controlBarAdditionalStyles.bottom = -58
  }

  return (
    <>
      <div
        ref={fullScreenContainerRef}
        style={containerStyle}
        onMouseMove={handlePlayerMouseMove}
      >
        <div
          style={styles.playerOuterContainer}
        >
          {playerRef?.current?.getInternalPlayer() && isUsingRegionSelector && <RegionSelector
            video={playerRef?.current?.getInternalPlayer()}
            portalTarget={portalTarget}
            videoSizeState={controlState.isFullScreen ? "FULL_SCREEN" : controlState.isExpandedView ? "EXPANDED" : "SMALL"}
            question={question}
            changeAnswerMapValue={changeAnswerMapValue}
            getAnswerMapValue={getAnswerMapValue}
            videoData={videoData}
          />}
          <ReactPlayer
            ref={playerRef}
            playing={controlState.isPlaying}
            playbackRate={controlState.playbackRate}
            muted={controlState.isMuted}
            height={controlState.isFullScreen
              ? '100%'
              : controlState.isExpandedView ? '800px' : '500px'
            }
            // width 100% required for RegionSelector
            width={'100%'}
            url={videoData?.url}
            volume={controlState.volume}
            controls={false}
            onReady={handleMediaReady}
            onError={handleMediaLoadError}
            onEnded={handleMediaEnded}
            preload={'none'}
            config={{
              file: {
                attributes: {
                  onContextMenu: e => e.preventDefault(),
                  controlsList: 'nodownload',
                }
              }
            }}
          />
        </div>
        <Transition visible={controlState.isControlPanelVisible} animation='fade' duration={500}>
          <div
            style={{ ...styles.controlPanelContainer, ...controlBarAdditionalStyles }}
            onMouseMove={handleControlPanelMouseMove}
          >
            <ControlPanel
              viewingProgress={viewingProgress}
              controlState={controlState}
              options={options}
              timeSkipOptions={timeSkipOptions}
              playbackRateOptions={playbackRateOptions}
              annotationOptions={annotationOptions}
              annotations={annotations}
              setAnnotations={setAnnotations}
              onControlClick={handleControlClick}
              onProgressChange={handleProgressChange}
            />
          </div>
        </Transition>
      </div>
      {/*This portal is required for RegionSelector*/}
      <div ref={portalTarget} />
    </>
  );
});


const styles = {
  containerCore: {
    display: 'flex',
    // border: '4px solid #333',
    // border: '4px solid orange', // TODO: Remove / change colour? grey??
    position: 'relative',
  },
  containerDefault: {
    width: '640px',
  },
  containerExpandedView: {
    width: '100%',
  },

  controlPanelContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    zIndex: 100,
    backgroundImage: "linear-gradient(to top, black, rgba(0,0,0,0))",
  },

  playerOuterContainer: {
    backgroundColor: '#222',
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
};

export default VideoPlayer;
