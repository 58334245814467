/* eslint no-unused-vars: 0 */
import React, {Component} from "react";
import {Button, Dropdown, Form, Grid, Header, Icon, Label, List, Message, Modal, Segment} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import StaffService from "../../StaffService";
import GroupService from "../../services/GroupService";
import GroupPermission from "../../GroupPermission";
import TrialService from "../../TrialService";
import AuthService from "../../services/AuthService";
import ConfigContext from "../../context/ConfigContext";
import GeneralHelpers from "../../helpers/GeneralHelpers";
import AparitoSwitch from "../questionnaire/AparitoSwitch";
import {typeHelper} from "atom5-branching-questionnaire";
import StaffSubjectLinkCard from "../../pages/staff/StaffSubjectLinkCard";
import SubjectService from "../../SubjectService";
import {Link} from "react-router-dom";
import PermissionsService from "../../services/PermissionsService";

class StaffForm extends Component {
  state = {
    superAdmin: false,
    isPrimarySuperAdmin: false,
    firstName: "",
    lastName: "",
    email: "",
    drugManager: false,
    availableRoles: [],
    groupRoles: [],
    groups: [],
    roles: [],
    groupLabels: {},
    roleLabels: {},

    submitting: false,

    showSuperAdmin: false,
    showDrugManager: true,
    warning: null,

    isEditingOwnAccount: false,
    showIsEditingOwnAccountWarning: undefined,

    staffMember: null,
    staffToSubjectLinkingEnabled: false,
    linkingErrorMsg: "",
    createLinkedAccountCheckbox: false,
    subjectGroup: "",
    linkedGroupDropdown: "",
    overrideGroup: false,
    linkableGroups: [],
    showLinkingControls: false,
    isLinked: false,
    subjectEmailExists: false
  };

  constructor(props, context) {
    super(props, context);

    this.state.showDrugManager = this.context.ui?.showDrugManager !== "false";

    if (props.staff) {
      this.state.staffMember = props.staff;
      this.state.firstName = props.staff.firstName;
      this.state.lastName = props.staff.lastName;
      this.state.email = props.staff.email;
      this.state.drugManager = props.staff.drugManager;
      this.state.superAdmin = props.staff.superAdmin;
      this.state.isPrimarySuperAdmin = PermissionsService.isPrimarySuperAdmin(props.staff);
      this.state.isLinked = props.staff.linkedSubjectId != null;

      this.state.groupRoles = [];

      for (const groupRole of props.staff.groupMappings) {
        this.state.groupRoles.push({
          group: groupRole.group.code,
          roles: groupRole.roles.map((role) => role.code),
        });
        this.addRoleLabelsForGroup(groupRole.group.code);
      }
    }

    AuthService.getIsSuperAdmin().then((isSuperAdmin) => {
      this.setState({showSuperAdmin: isSuperAdmin});
      if (isSuperAdmin) {
        TrialService.getGroups().then((allGroups) => {
          const newGroupLabels = {};
          for (const group of allGroups) {
            newGroupLabels[group.code] = group.label;
          }
          this.setState({
            availableGroups: allGroups,
            groupLabels: newGroupLabels,
          });
          this.populateLinkableGroups(allGroups);
        });
      } else {
        GroupService.getGroupsWithPermission(GroupPermission.CREATE_STAFF).then(
          (groups) => {
            TrialService.getGroups().then((allGroups) => {
              const newGroupLabels = {};
              for (const group of groups) {
                const trialGroup = allGroups.find((g) => g.code === group.code);
                newGroupLabels[group.code] = trialGroup.label;
              }
              this.setState({
                availableGroups: groups,
                groupLabels: newGroupLabels,
              });
              this.populateLinkableGroups(allGroups);
            });
          }
        );
      }

      GroupService.getDefaultGroupForNewLinkedSubject()
          .then((response) => {
            this.setState({
              subjectGroup: response.defaultGroup
            });
          }).catch((_error) => {
            console.log("Default link group not picked up from config");
            this.setState({
              subjectGroup: ""
            });
          });
    });

    StaffService.isStaffToSubjectLinkingEnabled()
        .then((response) => {
          this.setState({
            staffToSubjectLinkingEnabled: typeHelper.parseBool(response.enabled)
          });
        });

    this.onSubmitCallback = props.onSubmit;
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.showIsEditingOwnAccountWarning == null) {
      StaffService.getMyProfile().then(profile => {
        if (this.props.staff.id === profile.id) {
          this.setState({showIsEditingOwnAccountWarning: true, isEditingOwnAccount: true});
        }
      });
    }
  }

  populateLinkableGroups = (groups) => {
    const { t } = this.props;
    const lGroups = [];
    lGroups.push({
      key: 'select',
      text: t("STAFF_FORM_SELECT_GROUP", "Select Group"),
      value: 'select_group',
    });
    for (const group in groups) {
      if (groups[group]?.membersLinkable) {
        lGroups.push({
          key: groups[group].code,
          text: groups[group].label,
          value: groups[group].code,
        });
      }
    }
    this.setState({linkableGroups: lGroups});
  }

  handleLinkedGroupChange = (event, item) => {
    if (item.value === 'select_group') {
      return;
    }
    const selected = item.options.find(group => {
      return group.key === item.value
    });

    this.setState({linkedGroupDropdown: selected.key} );
  }

  addGroupRole = (event) => {
    event.preventDefault();
    const groupRoles = this.state.groupRoles;
    for(const group of this.state.groups) {
      groupRoles.push({group: group, roles: this.state.roles});
    }

    this.setState({ groupRoles, groups: [], roles: [] });
  };

  removeGroupRole = (group) => {
    const groupRoles = this.state.groupRoles;

    for (let i = 0; i < groupRoles.length; i++) {
      if (groupRoles[i].group === group) {
        groupRoles.splice(i, 1);
        break;
      }
    }

    this.setState({ groupRoles });
  };

  groupSelected = async (_event, item) => {
    this.setState({
      loading: true,
    });
    let { t } = this.props;
    let groupValues;
    if (item.value.includes('select_all')) {
      //add all the groups into the dropdown
      const nonSelectedGroups = this.getNonSelectedGroups();
      groupValues = nonSelectedGroups
          .filter(group => group.value !== 'select_all')
          .map(group => group.value)
    }else{
      groupValues = item.value;
    }
    //Get list of roles common to all the selected groups
    const commonAvailableRoles = await this.getAvailableRolesForStaffCreation(groupValues);
    //gets a list of the common roles that have already been selected
    const commonSelectedRoles = this.getCommonSelectedRoles(commonAvailableRoles);
    //If they have commonSelected roles that are now different from the current selected roles warn the user
    const noChanges =  GeneralHelpers.arraysEqual(commonSelectedRoles,this.state.roles);
    if (!noChanges){
      this.setState({warning : t("STAFF_FORM_ROLES_CHANGED","Current selected roles have changed")});
    } else{
      this.setState({warning : null});
    }
    this.setState({availableRoles:commonAvailableRoles, roles:commonSelectedRoles, groups:groupValues,  loading: false });

    this.addRoleLabelsForGroup(item.value);

  };

  getCommonSelectedRoles(commonAvailableRoles) {
    return this.state.roles.filter(role => {
      return commonAvailableRoles.filter(commonRole => {
        return commonRole.code === role;
      }).length > 0;
    });
  }

  //list all available roles given list of groups
  //user is assigning to a user. Available role will only be included in the list
  //if the user is allowed to grant the role for all the groups passed into this function.
  getAvailableRolesForStaffCreation =  async ( groups ) => {
    let commonAvailableRoles = [];
    for (const group of groups) {
      let availableRoles = await StaffService.getRolesForStaffCreation(group);
      if (commonAvailableRoles.length === 0){
        commonAvailableRoles = availableRoles; //if the list is empty add all the roles
      }else{
        let currentAvailableRoles = commonAvailableRoles;
        commonAvailableRoles = availableRoles.filter( availableRole => {
          return (currentAvailableRoles.filter(currentRole => currentRole.id === availableRole.id).length > 0);
        })
      }
    }
    return commonAvailableRoles;
  }

  addRoleLabelsForGroup = (groupCode) => {
    const roleLabels = this.state.roleLabels;
    StaffService.getRolesForStaffCreation(groupCode).then((availableRoles) => {
      for (const role of availableRoles) {
        roleLabels[role.code] = role.label;
      }
      this.setState({ roleLabels: roleLabels });
    });
  };

  roleSelected = (_event, item) => {
    this.setState({
      roles: item.value,
    });
  };

  inputChanged = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  checkBoxChanged = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  linkedCheckboxChanged = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
    if (event.target.checked) {
      this.checkLinkedSubjectExistence();
    }
  }

  isEmailAddressValid() {
    return (this.state.email.length > 0 && AuthService.EMAIL_REGEX.test(this.state.email));
  }

  checkLinkedSubjectExistence = () => {
    if (this.isEmailAddressValid()) {
      //check if subject with email exists
      SubjectService.doesSubjectExistWithEmail(this.state.email).then((subjectResult) => {
        if (subjectResult?.Id !== undefined) {
          //subject with email address exists, remove control
          this.setState({ showLinkingControls: false, subjectEmailExists: true });
        } else {
          //subject with email doesnt exist, show controls
          this.setState({ showLinkingControls: true, subjectEmailExists: false })
        }
      });
    }
  }

  emailChanged = () => {
    if (this.state.createLinkedAccountCheckbox) {
      this.checkLinkedSubjectExistence();
    }
  }

  handleOverrideChange = (event) => {
    this.setState({ overrideGroup: event });
  }

  handleUnlinking = () => {
    this.setState({ isLinked: false } );
  }

  handleSubmit = (event) => {
    const { t } = this.props;
    event.preventDefault();

    const staff = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      drugManager: this.state.drugManager,
      superAdmin: this.state.superAdmin,
      email: this.state.email,
      groupRoles: this.state.groupRoles,
      createLinkedAccountCheckbox: this.state.createLinkedAccountCheckbox,
      subjectGroup: this.state.subjectGroup
    };

    //validate linking info supplied if linking checkbox is ticked
    if (this.state.createLinkedAccountCheckbox) {
      //if not overridden make sure dropdown value selected
      if (this.state.overrideGroup === false &&
          this.state.showLinkingControls &&
          this.state.linkedGroupDropdown.trim().length === 0) {
        this.setState({ linkingErrorMsg: t("STAFF_FORM_SELECT_GROUP_ERROR", "Please select a group") });
        return;
      }

      //subject group is already set to the override value
      //if group selected from the dropdown then use this instead
      if (this.state.overrideGroup === false) {
        staff.subjectGroup = this.state.linkedGroupDropdown;
      }
    }

    const promise = this.onSubmitCallback(staff, this.state.isEditingOwnAccount);

    // If we have a promise, lets use that to determine when to show indication of submission
    if (promise) {
      this.setState({ submitting: true });

      promise.finally(() => {
        this.setState({ submitting: false });
      });
    }
  };

  getNonSelectedGroups() {
    const { t } = this.props;
    const groups = [];
    if (this.state.availableGroups) {
      groups.push({
        key: 'selectall',
        text: t("STAFF_FORM_SELECT_ALL","Select All"),
        value: 'select_all',
      });
      for (const group of this.state.availableGroups) {
        let groupAlreadyAssigned = false;

        for (const groupRole of this.state.groupRoles) {
          if (group.code === groupRole.group) {
            groupAlreadyAssigned = true;
            break;
          }
        }

        if (!groupAlreadyAssigned) {
          groups.push({
            key: group.code,
            text: this.state.groupLabels[group.code],
            value: group.code,
          });
        }
      }
    }
    return groups;
  }

  render() {
    const { t, error } = this.props;

    const groups = this.getNonSelectedGroups();

    for (const groupRole of this.state.groupRoles) {
      const index = groups.indexOf(groupRole.group);

      if (index >= 0) {
        groups.splice(index, 1);
      }
    }

    const roles = [];
    for (const role of this.state.availableRoles) {
      roles.push({ key: role.code, text: role.label, value: role.code });
    }

    const canSelectGroup = groups.length > 0 && !this.state.submitting;
    const canAddGroupRole =
      this.state.groups.length && this.state.roles.length > 0 && !this.state.submitting;
    const canSubmit = !this.state.submitting;

    return (
      <Form onSubmit={this.handleSubmit} error={error}>
        <Message error header={t("STAFF_FORM_ERROR_TITLE")} content={error} />
        <Message warning visible={this.state.warning !== null} header={t("STAFF_FORM_WARNING_TITLE","Warning")} content={this.state.warning} />

        <Modal
          size={"small"}
          open={this.state.showIsEditingOwnAccountWarning === true}
          closeIcon={false}
          closeOnDimmerClick={false}
          closeOnDocumentClick={false}
        >
          <Modal.Header>{t("STAFF_FORM_EDITING_OWN_ACCOUNT_WARNING_HEADER", "You are editing your own account")}</Modal.Header>
          <Modal.Content>
            {t("STAFF_FORM_EDITING_OWN_ACCOUNT_WARNING_TEXT", "As you are editing your own user account. When you save the changes, you will be immediately logged out to allow all changes to take effect fully.")}
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={() => this.setState({ showIsEditingOwnAccountWarning: false })}>{t("GLOBAL_BUTTON_CONFIRM", "Confirm")}</Button>
          </Modal.Actions>
        </Modal>

        <Grid columns={"equal"}>
          <Grid.Column>
            <Segment>
              <Header>{t("STAFF_FORM_DETAILS_HEADER")}</Header>
              {this.state.isPrimarySuperAdmin && (
                <Message info visible>
                  <Message.Header>
                    <Icon name={"warning sign"} /> WARNING
                  </Message.Header>
                  {t("PRIMARY_SUPERADMIN_EDIT_WARNING", "This is the Primary Super Admin, and some details cannot be edited")}
                </Message>
              )}
              {this.state.showSuperAdmin && (
                <Form.Field>
                  <label>{t("STAFF_FORM_IS_SUPER_ADMIN_LABEL", "Is Super Admin?")}</label>
                  <input
                    type="checkbox"
                    name={"superAdmin"}
                    checked={this.state.superAdmin}
                    value="superAdmin"
                    onChange={this.checkBoxChanged}
                    disabled={this.state.isPrimarySuperAdmin}
                  />
                </Form.Field>
              )}
              <Form.Field>
                <label>{t("STAFF_FORM_FIRSTNAME_LABEL")}</label>
                <input
                  autoFocus
                  name={"firstName"}
                  required
                  value={this.state.firstName}
                  onChange={this.inputChanged}
                  disabled={this.state.isPrimarySuperAdmin}
                />
              </Form.Field>
              <Form.Field>
                <label>{t("STAFF_FORM_LASTNAME_LABEL")}</label>
                <input
                  name={"lastName"}
                  required
                  value={this.state.lastName}
                  onChange={this.inputChanged}
                  disabled={this.state.isPrimarySuperAdmin}
                />
              </Form.Field>
              {this.state.showDrugManager && (
                <Form.Field>
                  <label>{t("STAFF_FORM_DRUG_MANAGER_LABEL")}</label>
                  <input
                    type="checkbox"
                    name={"drugManager"}
                    checked={this.state.drugManager}
                    value="drugManager"
                    onChange={this.checkBoxChanged}
                  />
                </Form.Field>
              )}
              <Form.Field>
                <label>{t("STAFF_FORM_EMAIL_LABEL")}</label>
                <input
                  name={"email"}
                  required
                  value={this.state.email}
                  onChange={this.inputChanged}
                  onBlur={this.emailChanged}
                  disabled={this.state.isPrimarySuperAdmin}
                />
              </Form.Field>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Grid.Row>
              <Segment>
                <Header>{t("STAFF_FORM_GROUP_ROLES_HEADER")}</Header>
                <Form.Group>
                  <Form.Field width={6}>
                    <label>{t("STAFF_FORM_GROUP_DROPDOWN_LABEL")}</label>
                    <Dropdown
                      placeholder={t("STAFF_FORM_GROUP_DROPDOWN_PLACEHOLDER")}
                      fluid
                      multiple
                      selection
                      options={groups}
                      onChange={this.groupSelected}
                      value={this.state.groups}
                      disabled={!canSelectGroup}
                    />
                  </Form.Field>
                  <Form.Field width={6}>
                    <label>{t("STAFF_FORM_ROLES_DROPDOWN_LABEL")}</label>
                    <Dropdown
                      placeholder={t("STAFF_FORM_ROLES_DROPDOWN_PLACEHOLDER")}
                      fluid
                      multiple
                      selection
                      options={roles}
                      onChange={this.roleSelected}
                      value={this.state.roles}
                      disabled={!canSelectGroup}
                    />
                  </Form.Field>
                  <Form.Field width={4}>
                    <label>&nbsp;</label>
                    <Button
                      style={{ height: 35 }}
                      primary
                      onClick={this.addGroupRole}
                      disabled={!canAddGroupRole}
                    >
                      {t("GLOBAL_BUTTON_ADD")}
                    </Button>
                  </Form.Field>
                </Form.Group>

                <List divided selection>
                  {this.state.groupRoles.map((groupRole) => (
                    <List.Item key={groupRole.group}>
                      <List.Content>
                        <List.Header>
                          {this.state.groupLabels[groupRole.group]}{" "}
                          <a
                            href={"#noop"}
                            onClick={(event) => {
                              event.preventDefault();
                              this.removeGroupRole(groupRole.group);
                            }}
                            style={{ float: "right" }}
                          >
                            {t("GLOBAL_BUTTON_REMOVE")}
                          </a>
                        </List.Header>
                        <List.Description style={{ paddingTop: "0.2em" }}>
                          {groupRole.roles.map((role) => (
                            <Label key={role}>
                              {this.state.roleLabels[role]}
                            </Label>
                          ))}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </Segment>
            </Grid.Row>
            {this.state.staffToSubjectLinkingEnabled === true && (<Grid.Row style={{ paddingTop: "0.5em" }}>
              <Segment>
                <Header>{t("STAFF_TO_SUBJECT_LINK", "Participant linking")}</Header>
                <Form.Group>
                  <Form.Field width={16}>
                    {!this.state.isLinked && (<Form.Field>
                      <label>{t("STAFF_FORM_LINK_ACCOUNT_LABEL", "Create linked participant / link if participant already exists?")}</label>
                      <input
                          type="checkbox"
                          name={"createLinkedAccountCheckbox"}
                          checked={this.state.createLinkedAccountCheckbox}
                          value="createLinkedAccountCheckbox"
                          onChange={this.linkedCheckboxChanged}
                      />
                    </Form.Field>)}
                    {this.state.createLinkedAccountCheckbox &&
                        this.state.subjectEmailExists && (
                            <label style={{marginTop:'5px'}}>{t("STAFF_FORM_SUBJECT_EXISTS_LABEL", "Participant with matching email address already exist, they will be linked!")}</label>
                        )
                    }

                    {this.state.createLinkedAccountCheckbox &&
                        this.state.email.length === 0 && (
                            <label style={{marginTop:'5px'}}>{t("STAFF_FORM_SUBJECT_NO_EMAIL__LABEL", "Enter new staff member email address")}</label>
                        )
                    }

                    {this.state.createLinkedAccountCheckbox &&
                        this.state.linkableGroups.length === 1 &&
                        this.state.showLinkingControls && (
                            <>
                              <label>{t("STAFF_FORM_SUBJECT_GROUP_LABEL", "No groups configured for linking participants")}</label>
                              <Link to={"/app/groups"}>{t("STAFF_FORM_SUBJECT_EDIT_GROUP_LABEL", "Manage groups here")}</Link>
                            </>
                        )
                    }

                    {this.state.createLinkedAccountCheckbox &&
                        this.state.linkableGroups.length > 1 &&
                        this.state.showLinkingControls && (<Form.Field style={{ paddingTop: "1.0em" }}>
                          <label>{t("STAFF_FORM_SUBJECT_GROUP_LABEL", "Assign the participant to the following group (only groups with linking enabled are selectable):")}</label>
                          {this.state.linkingErrorMsg.trim().length > 0 && (<Form.Field width={16}>
                            <Form.Field>
                              <div style={{padding: "1.0em", color:'red'}}>{this.state.linkingErrorMsg}</div>
                            </Form.Field>
                          </Form.Field>)}

                          <Dropdown
                              placeholder={t("STAFF_FORM_SELECT_GROUP_PLACEHOLDER", "Select Group")}
                              fluid
                              selection
                              style={{ background: !this.state.overrideGroup ? 'white' : '#ddd' }}
                              options={this.state.linkableGroups}
                              value={this.state.linkedGroupDropdown}
                              disabled={this.state.overrideGroup}
                              onChange={this.handleLinkedGroupChange}
                          />
                        </Form.Field>
                    )}

                    {this.state.createLinkedAccountCheckbox &&
                        this.state.email.length > 0 && (
                        <Grid columns={"equal"} style={{ paddingTop: "1.0em" }}>
                          <Grid.Column>
                            <AparitoSwitch style={styles}
                                           id="aparitoSwitch"
                                           checked={this.state.overrideGroup}
                                           onChange={this.handleOverrideChange}
                                           label={t("STAFF_FORM_DEFAULT_SUBJECT_GROUP_LABEL", "Override group")} />
                          </Grid.Column>
                          <Grid.Column>
                            <input
                                disabled={!this.state.overrideGroup}
                                name={"subjectGroup"}
                                required
                                style={{ background: this.state.overrideGroup ? 'white' : '#ddd' }}
                                value={this.state.subjectGroup}
                                onChange={this.inputChanged}
                            />
                          </Grid.Column>
                        </Grid>
                    )}

                  </Form.Field>
                </Form.Group>

                {this.state.showSuperAdmin && this.state.staffMember == null &&
                    t("STAFF_MANNUAL_ASSIGNING_WARNING", "Manually assigning participants is only available when editing existing staff members!")
                }

                {this.state.staffMember !== undefined &&
                    this.state.staffMember !== null &&
                        (<StaffSubjectLinkCard
                            index={0}
                            blink={false}
                            staffEntity={this.state.staffMember}
                            groupLabels={undefined}
                            handleUnlinking={this.handleUnlinking}
                            handleUpdateButtonState={undefined}/>)
                }
              </Segment>
            </Grid.Row>)}
          </Grid.Column>
          <Grid.Row>
            <Grid.Column>
              <Message warning visible={this.state.isEditingOwnAccount === true} header={t("STAFF_FORM_EDITING_OWN_ACCOUNT_WARNING_HEADER","You are editing your own account")} content={t("STAFF_FORM_EDITING_OWN_ACCOUNT_WARNING_TEXT", "As you are editing your own user account. When you save the changes, you will be immediately logged out to allow all changes to take effect fully.")} />
              <Button
                type="submit"
                loading={this.state.submitting}
                primary
                disabled={!canSubmit}
              >
                {t("GLOBAL_BUTTON_SAVE")}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

StaffForm.contextType = ConfigContext;

let styles = {
  marginRight: '20px',
  width: '250px',
  height: '250px',
  backgroundColor: 'yellow',
};


export default withTranslation()(StaffForm);
