import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import RequestHelper from '../../RequestHelper';
import { serverAddress } from "../../services/config/EnvConfig";
import InternationalisationService from '../../InternationalisationService';
import StaffService from '../../StaffService';
import { Button, Dropdown, Form, Message, Modal, Table, TableCell, TableHeader, TableHeaderCell, TableRow, Transition } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import ConfirmButtonWithFeedback from '../../components/dashboard/ConfirmButtonWithFeedback';
import StaffPermissionService from '../../services/StaffPermissionService';
import { compose } from 'redux';
import withPermissionWrapper from '../../security/withPermissionWrapper';

const ACCESS_LEVEL = {
  "FULL_ACCESS": "FULL_ACCESS",
  "RESTRICTED_ACCESS": "RESTRICTED_ACCESS"
}

function AdminModuleRoleEditor() {
  const { t } = useTranslation()

  const [modules, setModules] = useState([]);
  const [roles, setRoles] = useState([]);
  const hasLoaded = useMemo(() => modules.length > 0 && roles.length > 0, [modules, roles])

  const [hasError, setHasError] = useState(false);

  const fetchModules = useCallback(async () => {
    const modules = await RequestHelper.send(serverAddress + '/modules',
      {
        "Accept-Language": InternationalisationService.getLanguage(),
      },
      "GET",
      null,
    );
    setModules(modules)
  }, [])
  const fetchRoles = useCallback(async () => {
    const roles = await StaffService.getRoles()
    setRoles(roles)
  }, [])
  useEffect(() => {
    fetchModules()
    fetchRoles()
  }, [fetchModules, fetchRoles])

  const [newModuleLinks, setNewModuleLinks] = useState([])
  useEffect(() => {
    const result = getModuleRoleLinksFromDBModules(modules)
    setNewModuleLinks(result)
  }, [modules])

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleDelete = (moduleLink) => {
    let deleteIndex = -1;
    newModuleLinks.forEach((nML, index) => {
      if (isTheSame(nML, moduleLink)) deleteIndex = index;
    })
    console.assert(deleteIndex !== -1, "Link to delete not found");
    if (deleteIndex === -1) return
    if (moduleLink.id) {
      setNewModuleLinks(prevModuleLinks => {
        const newModuleLinks = [...prevModuleLinks]
        newModuleLinks[deleteIndex].action = "DELETE";
        return newModuleLinks;
      })
    } else {
      setNewModuleLinks(prevModuleLinks => {
        const newModuleLinks = [...prevModuleLinks]
        newModuleLinks.splice(deleteIndex, 1);
        return newModuleLinks;
      })
    }
  }
  const handleUndo = (moduleLink) => {
    let undoIndex = -1;
    newModuleLinks.forEach((nML, index) => {
      if (isTheSame(nML, moduleLink)) undoIndex = index;
    })
    console.assert(undoIndex !== -1, "Link to undo not found");
    if (undoIndex === -1) return
    if (moduleLink.id) {
      setNewModuleLinks(prevModuleLinks => {
        const newModuleLinks = [...prevModuleLinks]
        delete newModuleLinks[undoIndex].action
        return newModuleLinks
      })
    }
  }
  const handleEdit = (moduleLink) => {
    let editIndex = -1;
    newModuleLinks.forEach((nML, index) => {
      if (isTheSame(nML, moduleLink)) editIndex = index;
    })
    console.assert(editIndex !== -1, "Link to undo not found");
    if (editIndex === -1) return
    setNewModuleLinks(prevModuleLinks => {
      const newModuleLinks = [...prevModuleLinks]
      newModuleLinks[editIndex].permission = moduleLink.permission
      newModuleLinks[editIndex].action = moduleLink.id ? "EDIT" : "NEW"
      return newModuleLinks
    })

  }
  const handleSubmit = async (feedbackReason) => {
    const valuesToSubmit = newModuleLinks.filter(nML => typeof nML.action === 'string');
    const { initialRequestBody, initialHeaders: headers } =
      RequestHelper.createInitialRequestObjectsWithFeedback(
        feedbackReason,
        "Module Role Link Edit"
      );
    const response = await RequestHelper.send(serverAddress + '/modules/role-links',
      {
        ...headers,
        "Accept-Language": InternationalisationService.getLanguage(),
      },
      "POST",
      null,
      { payload: valuesToSubmit, ...initialRequestBody }
    );
    if (response !== true) setHasError(true)
    if (response === true) {
      await setModules([])
      fetchModules()
    }
  }


  const [numberRemoved, setNumberRemoved] = useState(0)
  const timeoutRef = useRef();
  const handleCreate = (moduleLinks) => {
    let numberRemoved = 0
    setNewModuleLinks(prevModuleLinks => {
      const newModuleLinks = [...prevModuleLinks]
      const newLinks = moduleLinks
        .filter(newLink => prevModuleLinks.every((prevLink) => !isTheSame(newLink, prevLink)))
        .map(newLink => ({ ...newLink, action: "ADD" }))

      newModuleLinks.unshift(...newLinks)
      numberRemoved += moduleLinks.length - newLinks.length
      return newModuleLinks
    })
    if (numberRemoved > 0) {
      setNumberRemoved(numberRemoved)
      clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => setNumberRemoved(0), 3000)
    }
  }

  return (
    <div>
      {hasError && <Message error content={t("MODULE_LINK_SUBMISSION_ERROR", "Link submission failed, please reload and try again.")} />}
      <div style={{ width: "100%", display: "flex", flex: 1, justifyContent: "space-between" }}>
        <Button primary basic onClick={() => setIsModalOpen(true)}>{t("GLOBAL_BUTTON_NEW", "Create New Link")}</Button>

        <div style={{ width: "200px" }}>
          <ConfirmButtonWithFeedback
            fluid
            onConfirm={handleSubmit}
            buttonText={t("GLOBAL_BUTTON_SUBMIT", "Submit Changes")}
            headerText={t("GLOBAL_BUTTON_SUBMIT", "Submit Changes")}
            contentText={t(
              "ADMIN_MODULE_ROLE_LINK_REASON_PROMPT",
              "Please give a reason why this is being changed and confirm."
            )}
            confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
            cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
            placeholderText={t(
              "ADMIN_MODULE_ROLE_LINK_REASON_PLACEHOLDER_TEXT",
              "Reason"
            )}
            mandatoryValidationText={t(
              "ADMIN_MODULE_ROLE_LINK_REASON_VALIDATION_TEXT",
              "Please supply a reason for the change."
            )}
            color={"orange"}
          />
        </div>

      </div>
      <Transition visible={numberRemoved > 0} animation='fade up' duration={300}>
        <Message content={`${numberRemoved} ${t("REMOVED_MODULE_LINK", " ommited as they were already present.")}`} info />
      </Transition>
      <div style={{ paddingTop: '8px' }}><Table>
        <TableHeader>
          <TableHeaderCell>{t("MODULE_ROLE_LINK_ID", "Id")}</TableHeaderCell>
          <TableHeaderCell>{t("MODULE_ROLE_LINK_MODULE", "Module")}</TableHeaderCell>
          <TableHeaderCell>{t("MODULE_ROLE_LINK_ROLE", "Role")}</TableHeaderCell>
          <TableHeaderCell>{t("MODULE_ROLE_LINK_PERMISSION", "Permission")}</TableHeaderCell>
          <TableHeaderCell></TableHeaderCell>
        </TableHeader>
        {newModuleLinks.map(mR => <LinkDisplay moduleLink={mR} handleDelete={handleDelete} handleUndo={handleUndo} handleEdit={handleEdit} />)}
      </Table>
      </div>
      {hasLoaded && <NewLinkModal
        modules={modules}
        roles={roles}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        handleDelete={handleDelete}
        handleUndo={handleUndo}
        handleEdit={handleEdit}
        handleCreate={handleCreate}
      />}


    </div>
  )
}

const LinkDisplay = ({ moduleLink, handleDelete, handleUndo, handleEdit }) => {
  const { t } = useTranslation()

  return <TableRow >
    <TableCell>
      {moduleLink.id}
    </TableCell>
    <TableCell>
      {moduleLink.module}
    </TableCell>
    <TableCell>
      {moduleLink.role}
    </TableCell>
    <TableCell>
      <Dropdown fluid
        selection
        options={Object.keys(ACCESS_LEVEL).map((level, index) => {
          return {
            key: index,
            text: level,
            value: level,
          }
        })}
        value={moduleLink.permission}
        onChange={(_e, data) => {
          const mL = { ...moduleLink }
          mL.permission = data.value
          handleEdit(mL)
        }} />
    </TableCell>
    <TableCell>
      {moduleLink.action !== "DELETE" && <Button negative basic fluid onClick={() => handleDelete(moduleLink)} >{t("GLOBAL_DELETE", "Delete")}</Button>}
      {moduleLink.action === "DELETE" && <Button primary fluid onClick={() => handleUndo(moduleLink)} >{t("GLOBAL_UNDO", "Undo")}</Button>}
    </TableCell>
  </TableRow>
}

const NewLinkModal = ({ modules, roles, isOpen, setIsOpen, handleCreate }) => {
  const { t } = useTranslation()
  const [moduleArray, setModuleArray] = useState([]);
  const [roleArray, setRoleArray] = useState([]);
  const [permission, setPermission] = useState(ACCESS_LEVEL.FULL_ACCESS);
  const reset = useCallback(() => {
    setModuleArray([])
    setRoleArray([])
    setPermission(ACCESS_LEVEL.FULL_ACCESS)
  }, [])
  const handleSubmit = useCallback(() => {
    const newLinks = []
    moduleArray.forEach(m => roleArray.forEach(r => newLinks.push({ module: m, role: r, permission, id: null })));
    handleCreate(newLinks)
    setIsOpen(false)
    reset()
  }, [moduleArray, roleArray, handleCreate, reset, setIsOpen, permission]);

  const isSubmissionAllowed = modules.length > 0 && roles.length > 0;

  return <Modal
    open={isOpen}
    onClose={() => setIsOpen(false)}
    closeIcon
  >
    <Modal.Header>{t("SIGNATURE_MODAL_HEADER_TITLE")}</Modal.Header>
    <Modal.Content>
      <Form
        onSubmit={() => handleSubmit()}
      >
        <div style={{ padding: '4px' }}><Dropdown fluid
          selection
          multiple
          placeholder={t("PLACEHOLDER_SELECT_MODULES", "Select MODULES")}
          options={modules.map((level, index) => {
            return {
              key: index,
              text: level.code,
              value: level.code,
            }
          })}
          value={moduleArray}
          onChange={(_e, data) => {
            setModuleArray(data.value)
          }} /></div>

        <div style={{ padding: '4px' }}><Dropdown fluid
          selection
          multiple
          placeholder={t("PLACEHOLDER_SELECT_ROLES", "Select Roles")}
          options={roles.map((level, index) => {
            return {
              key: index,
              text: level.code,
              value: level.code,
            }
          })}
          value={roleArray}
          onChange={(_e, data) => {
            setRoleArray(data.value)
          }} /></div>

        <div style={{ padding: '4px' }}><Dropdown fluid
          selection
          options={Object.keys(ACCESS_LEVEL).map((level, index) => {
            return {
              key: index,
              text: level,
              value: level,
            }
          })}
          value={permission}
          onChange={(_e, data) => {
            setPermission(data.value)
          }} /></div>
        <Button
          type="submit"
          primary
          disabled={!isSubmissionAllowed}
        >
          {t("GLOBAL_BUTTON_ADD", "Add")}
        </Button>
      </Form>
    </Modal.Content>
  </Modal>
}

const getModuleRoleLinksFromDBModules = (moduleArray) => {
  const result = [];
  moduleArray.forEach(module => {
    module.moduleRolesMappings.forEach(mRM => {
      result.push({ id: mRM.id, module: module.code, role: mRM.role.code, permission: mRM.permission })
    })
  })
  return result;
}

const isTheSame = (a, b) => a.module === b.module && a.role === b.role;

const withEnhancements = (options) => compose(
  withPermissionWrapper(options)
);
export default withEnhancements({ permissionFunctionDelegate: StaffPermissionService.canManageModules })(AdminModuleRoleEditor);
