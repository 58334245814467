import React, {
  useCallback, useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from 'react-router-dom';
import Page from "../../components/page/Page";
import { useTranslation } from "react-i18next";
import QuestionnaireDefinitionService from "../../services/admin/QuestionnaireDefinitionService";
import "./AdminQuestionnaireDefinitionCreatePage.css";
import {
  Card,
  Grid,
  Loader,
  Modal,
  Message
} from "semantic-ui-react";
import AccordianReveal from "../../components/generic/AccordianReveal";
import QuestionnaireEditorCardComponent from "../../components/admin/questionnaireEditor/QuestionnaireEditorCardComponent";
import LanguageSelectionGeneric from "../../components/languageselection/LanguageSelectionGeneric";
import QuestionnaireTranslationCardComponent from "../../components/admin/questionnaireEditor/QuestionnaireTranslationCardComponent";
import QuestionEditorCardComponent from "../../components/admin/questionnaireEditor/QuestionEditorCardComponent";
import SecondaryButton from "../../components/generic/SecondaryButton";
import NewQuestionCardComponent from "../../components/admin/questionnaireEditor/NewQuestionCardComponent";
import InternationalisationService from "../../InternationalisationService";
import ConfirmButtonWithFeedback from "../../components/dashboard/ConfirmButtonWithFeedback";
import useCompleteQuestionnaireDefinitionInformation, { ACTION_TYPES } from "../../hooks/useCompleteQuestionnaireDefinitionInformation";
import ResponseErrorPanel from "../../components/errors/ResponseErrorPanel";
import WorkflowService from "../../services/admin/WorkflowService";
import AceEditor from "react-ace";
import StaffPermissionService from "../../services/StaffPermissionService";
import { compose } from "redux";
import withPermissionWrapper from "../../security/withPermissionWrapper";
import AdminQuestionOrderModal from "../../components/admin/AdminQuestionOrderModal";
import UnsavedChangesPrompt from "../../components/admin/UnsavedChangesPrompt"

function AdminQuestionnaireDefinitionFullEditPage() {
  const { t } = useTranslation();
  const history = useHistory()

  const [hasLoaded, state, dispatch] = useCompleteQuestionnaireDefinitionInformation();

  const [shouldShowJson, setShouldShowJson] = useState(false);
  const toggleShouldShowJson = () => setShouldShowJson(!shouldShowJson);

  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false)

  const [isNewQuestionModalOpen, setIsNewQuestionModalOpen] = useState(false);
  const toggleModal = () => {
    setIsNewQuestionModalOpen(!isNewQuestionModalOpen)
  }
  const [isReordering, setIsReordering] = useState(false);

  // response error checking and validation for redirect on success
  const [errorObject, setErrorObject] = useState(null);
  const doesErrorObjectHaveErrors = useMemo(() => {
    if (errorObject === null) return null;
    return [
      ...Object.keys(errorObject?.errors || {}),
    ].length > 0;
  }, [errorObject]);
  useEffect(() => {
    if (doesErrorObjectHaveErrors === null) return;
    if (doesErrorObjectHaveErrors === true) return;
    history.push("/app/utils/questionnaire-definitions");
  }, [doesErrorObjectHaveErrors, errorObject, history])

  // submit edited information
  const handleConfirm = useCallback(async (reason) => {
    await setErrorObject(null);
    setShouldBlockNavigation(false)

    // Only send back changed translations
    const questionnaireAndTranslationObject = {
      questionnaireDefinition: state.questionnaireDefinition,
      translationArray: state.changedTranslationArray
    }
    try {
      setErrorObject(await QuestionnaireDefinitionService.submitQuestionnaireDefinitionAndTranslationChanges(questionnaireAndTranslationObject, reason));
    } catch (e) {
      setErrorObject(e)
    }
  }, [state.changedTranslationArray, state.questionnaireDefinition]);

  // block navigaton on unsaved changes
  const handleBlockNavigation = () => {
    setShouldBlockNavigation(true)
  }

  // language selection
  const [selectedLanguage, setSelectedLanguage] = useState(InternationalisationService.getLanguage());
  const setSelectedLanguageCallback = useCallback((value) => {
    setSelectedLanguage(value);
  }, []);

  const [jsonParseError, setJsonParseError] = useState(false)
  const [jsonCodeError, setJsonCodeError] = useState(false)
  const [jsonQuestionnaireCodeError, setJsonQuestionnaireCodeError] = useState(false)
  const [tempValue, setTempValue] = useState("")
  const onChange = async (v) => {
    try {
      const value = JSON.parse(v);
      setJsonParseError(false);

      const questionnaireCodeMatch = value.code === questionnaireDefinition.code;
      const questionnaireIdMatch = value.id === questionnaireDefinition.id;

      const codesMatch = value.questions.every((v, index) =>
        v.code === questionnaireDefinition.questions[index].code
      );
      const idsMatch = value.questions.every((v, index) =>
        v.id === questionnaireDefinition.questions[index].id
      );

      if (!questionnaireCodeMatch || !questionnaireIdMatch) {
        setJsonQuestionnaireCodeError(true);
        return;
      } else {
        setJsonQuestionnaireCodeError(false);
      }

      if (!codesMatch || !idsMatch) {
        setJsonCodeError(true);
        return;
      } else {
        setJsonCodeError(false);
      }
      handleBlockNavigation();
      dispatch({
        type: ACTION_TYPES.EDIT_QUESTIONNAIRE_DEFINITION,
        payload: value,
      });
    } catch (e) {
      await setTempValue(v);
      setJsonParseError(true);
    }
  };

  const [workflowTasks, setWorkflowTasks] = useState([]);
  const [workflowBehaviour, setWorkflowBehaviour] = useState([]);
  const workflowDefinitionCode = state?.questionnaireDefinition?.questionnaireWorkflowDefinitionId;
  useEffect(() => {
    const getWorkflowInformation = async () => {
      await Promise.all([setWorkflowBehaviour([]), setWorkflowTasks([])])
      try {
        const workflowTasks = workflowDefinitionCode ? await WorkflowService.fetchWorkflowDefinitionTasks(workflowDefinitionCode) : [];
        setWorkflowTasks(workflowTasks);
        const behaviours = await WorkflowService.fetchWorkflowBehaviorTypes();
        setWorkflowBehaviour(behaviours);
      } catch (e) {
        console.error("[AdminQuestionnaireDefinitionFullEditPage] Failed to fetch questionnaire behaviour type with error: ", e)
        setWorkflowTasks([]);
        setWorkflowBehaviour([]);
      }
    }
    getWorkflowInformation();
  }, [workflowDefinitionCode])

  // display values should update based on changes to translations
  const questionnaireTitleObject = useMemo(
    () =>
      state?.translationArray?.find(
        (sT) =>
          sT.code ===
          `questionnaire_${state?.questionnaireDefinition?.code}_label`
      ),
    [state.questionnaireDefinition, state.translationArray]
  );

  const {
    questionnaireDefinition,
    translationArray,
    changedTranslationArray,
    focusedQuestions
  } = state;

  if (!hasLoaded) return <Loader active={true} />;

  const onCloseJson = () => {
    setShouldShowJson(false)
    setJsonParseError(false)
    setJsonCodeError(false)
    setJsonQuestionnaireCodeError(false)
  };

  return (
    <Page
      name="Edit Full QuestionnaireDefinition"
      header={t(
        "ADMIN_QUESTIONNAIREDEFINITION_FULLEDIT_HEADER",
        "Questionnaire Definition Full Editor"
      )}
    >
      <UnsavedChangesPrompt
        history={history}
        shouldBlockNavigation={shouldBlockNavigation}
        headerText={t("ADMIN_UNSAVED_CHANGES_TEXT", "YOUR CHANGES WILL BE LOST")}
        contentText={t(
          "ADMIN_UNSAVED_CHANGES_PROMPT",
          "You are navigating away without saving your changes, your changes will be lost . Are you sure you want to leave?"
        )}
        confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
        cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
      />
      <Grid>
        <Grid.Column width={8}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <h4>{`${questionnaireDefinition.type} - ${questionnaireDefinition.code}`}</h4>
            <ConfirmButtonWithFeedback
              buttonText={t("GLOBAL_SAVE_BUTTON", "Save")}
              headerText={t("ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_PLACEHOLDER_TEXT", "Submit Questionnaire and Translation Changes")}
              contentText={t(
                "ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_PROMPT",
                "Please give a reason why this is being changed and confirm."
              )}
              confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
              cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
              onConfirm={handleConfirm}
              placeholderText={t(
                "ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_PLACEHOLDER_TEXT",
                "Reason"
              )}
              mandatoryValidationText={t(
                "ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_VALIDATION_TEXT",
                "Please supply a reason for the change."
              )}
              color={"orange"}
            />
          </div>
          <ResponseErrorPanel
            errorResponse={errorObject}
            showErrors={doesErrorObjectHaveErrors}
            title={t("ADMIN_QUESTIONNAIREDEFINITION_VALIDATIONFAILED", "Validation failed, please expand to show errors")}
          />
          <h2>{questionnaireTitleObject.translation}</h2>
          <Card fluid>
            <AccordianReveal displayText={t("QUESTIONNAIRE_EDIT_SHOW", "Show Questionnaire Information")} >
              <QuestionnaireEditorCardComponent
                errorResponse={errorObject}
                dispatch={dispatch}
                questionnaireDefinition={questionnaireDefinition}
                blockNavigation={setShouldBlockNavigation}
              />
            </AccordianReveal>
          </Card>
          <div style={{ display: "flex", margin: "2rem 0 0 0", alignItems: "baseline", justifyContent: "space-between" }}>
            <h3>{t("ADMIN_QUESTIONNAIREDEFINITION_Questions", "Questions")}</h3>
            <div style={{ display: "flex"}}>
            <SecondaryButton style={{marginRight: '5px'}}onClick={() => setIsReordering(true)}>{t("ADMIN_QUESTIONNAIRE_EDIT_SEQUENCE", "Edit Sequence")}</SecondaryButton>
            <SecondaryButton onClick={toggleModal}>{t("QUESTIONNAIRE_EDIT_NEW_QUESTION", "Add New Question")}</SecondaryButton>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <SecondaryButton
              noPadding
              rounded
              height={"30px"}
              width={"30px"}
              onClick={toggleShouldShowJson}>
              <span>{"{}"}</span>
            </SecondaryButton>
          </div>

          <Modal open={shouldShowJson} size='fullscreen'>
            <Modal.Content >
              <Modal.Description>
                <AceEditor
                  mode="json"
                  height="100%"
                  theme="github"
                  name="fullTriggerEditor"
                  editorProps={{ $blockScrolling: true }}
                  value={jsonParseError ? tempValue : JSON.stringify(questionnaireDefinition, null, 2)}
                  style={{ minHeight: "600px", width: '100%' }}
                  onChange={onChange}
                />
                <SecondaryButton style={{
                  position: 'absolute',
                  top: '-10px',
                  right: '-10px',
                  cursor: 'pointer',
                  borderRadius: '20px'
                }}
                  noPadding
                  height={"35px"}
                  width={"35px"}
                  onClick={onCloseJson}>
                  <span>{"X"}</span>
                </SecondaryButton>
                {shouldShowJson && jsonParseError && <Message error>
                  <Message.Content >{t("QUESTION_DEFINITION_JSON_ERROR", "There is an issue with the JSON, edits will not save")}</Message.Content>
                </Message>}
                {shouldShowJson && jsonCodeError && <Message warning>
                  <Message.Content >{t("QUESTION_DEFINITION_CODE_ERROR", "The question code or ID has been changed, edits will not save")}</Message.Content>
                </Message>}
                {shouldShowJson && jsonQuestionnaireCodeError && <Message warning>
                  <Message.Content >{t("QUESTIONNAIRE_DEFINITION_CODE_ERROR", "The questionnaire code or ID has been changed, edits will not save")}</Message.Content>
                </Message>}
              </Modal.Description>
            </Modal.Content>
          </Modal>

          {questionnaireDefinition.questions.map((q, i) => <QuestionEditorCardComponent
            key={`questionEditor-${q.code}`}
            dispatch={dispatch}
            questionDefinition={q}
            index={i}
            translations={translationArray}
            changedTranslations={changedTranslationArray}
            questionnaireDefinitionCode={questionnaireDefinition.code}
            workflowTasks={workflowTasks}
            workflowBehaviour={workflowBehaviour}
            isFocused={state?.focusedQuestions?.includes(q.code)}
            blockNavigation={handleBlockNavigation}
          />)}
          <div
            style={{
              display: "flex",
              justifyContent: "right"
            }}
          >
            <ConfirmButtonWithFeedback
              buttonText={t("GLOBAL_SAVE_BUTTON", "Save")}
              headerText={t("ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_PLACEHOLDER_TEXT", "Submit Questionnaire and Translation Changes")}
              contentText={t(
                "ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_PROMPT",
                "Please give a reason why this is being changed and confirm."
              )}
              confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
              cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
              onConfirm={handleConfirm}
              placeholderText={t(
                "ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_PLACEHOLDER_TEXT",
                "Reason"
              )}
              mandatoryValidationText={t(
                "ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_VALIDATION_TEXT",
                "Please supply a reason for the change."
              )}
              color={"orange"}
            />
          </div>
        </Grid.Column>
        <Grid.Column width={8} >
          <div style={{ display: "flex" }}>
            <LanguageSelectionGeneric
              inline
              language={selectedLanguage}
              callback={setSelectedLanguageCallback}
            />
          </div>
          <QuestionnaireTranslationCardComponent
            selectedLanguage={selectedLanguage}
            changedTranslationArray={changedTranslationArray}
            questionnaireDefinition={questionnaireDefinition}
            focusedQuestions={focusedQuestions}
            dispatch={dispatch}
            setSelectedLanguageCallback={setSelectedLanguageCallback}
            blockNavigation={handleBlockNavigation}
          />
        </Grid.Column>
      </Grid>
      <Modal open={isNewQuestionModalOpen} onClose={toggleModal}>
        <NewQuestionCardComponent
          dispatch={dispatch}
          questionDefinition={null}
          translations={translationArray}
          questionnaireDefinition={questionnaireDefinition}
          isFocused={false}
          toggleOpen={toggleModal}
          blockNavigation={handleBlockNavigation}
        />
      </Modal>
      <AdminQuestionOrderModal
        questionDefinitions={questionnaireDefinition.questions}
        questionnaireDefinition={questionnaireDefinition}
        serverTranslations={translationArray}
        isOpen={isReordering}
        setIsOpen={setIsReordering}
        onReordering
      />
    </Page>
  );
}

const withEnhancements = (options) => compose(
  withPermissionWrapper(options)
);
export default withEnhancements({ permissionFunctionDelegate: StaffPermissionService.canManageQuestionnaireDefinitions })(AdminQuestionnaireDefinitionFullEditPage);
