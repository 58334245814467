import React, {useState, memo} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TranslationEditModal from "./TranslationEditModal";
import {ACTION_TYPES} from "../../../hooks/useCompleteQuestionnaireDefinitionInformation";

function TranslationDisplay({ translationObject, dispatch, blockNavigation }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const updateTranslation = (translationObject) => {
    blockNavigation()
    dispatch({type: ACTION_TYPES.ADD_TRANSLATION_EDIT, payload: translationObject})
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        paddingBottom: "1rem",
      }}
    >
      <div
        style={{
          paddingBottom: translationObject?.translation ? "0.5rem" : undefined,
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline"
        }}
      >
        <FontAwesomeIcon icon={"pen"} color={"orange"} style={{marginRight: '0.5rem', cursor: "pointer"}} onClick={toggleOpen} />
        <p
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontWeight: "bold"
          }}
        >
          {translationObject.code}
        </p>
      </div>
      <p style={{whiteSpace: "nowrap", overflow: "hidden",
        textOverflow: "ellipsis",
        }} >{translationObject?.translation}</p>
      <TranslationEditModal
          translationObject={translationObject}
          isOpen={isOpen}
          onClose={toggleOpen}
          onConfirmCallback={updateTranslation}
      />
    </div>
  );
}

export default memo(TranslationDisplay);
