import {Button, Dropdown, Form, Grid, GridColumn, GridRow, Icon, Label, Table} from "semantic-ui-react";
import {ACTION_TYPES} from "../../../hooks/useCompleteQuestionnaireDefinitionInformation";
import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

function QuestionWorkflowBehaviorEditorComponent ({
      questionDefinition,
      isReadOnly = false,
      dispatch,
      workflowTasks,
      workflowBehaviour,
      blockNavigation
}){
    const { t } = useTranslation();

    const [selectedWorkflowBehaviour, setSelectedWorkflowBehaviour] = useState({task:"",taskCode:"",behaviour:""});
    const [behaviourError, setBehaviourError] = useState(false);


    const workflowTaskOptions = useMemo(()=>{
        return workflowTasks
            .map( wft => {
                return {
                    key: wft.code,
                    text: wft.code,
                    value: wft.id,
                };
            }).sort((a, b) => a.key.localeCompare(b.key));
    }, [workflowTasks])

    const workflowBehaviourOptions = useMemo(()=>{
        return workflowBehaviour
            .map( behavior => {
                return {
                    key: behavior,
                    text: behavior,
                    value: behavior,
                };
            }).sort((a, b) => a.key.localeCompare(b.key));
    }, [workflowBehaviour])

    const addWorkflowBehavior = ()=> {
        function checkForBehaviourDuplicates(listToCheck) {
            const taskNamesMap = new Set();
            return listToCheck.some(item => {
                return taskNamesMap.has(item.behaviour) || !taskNamesMap.add(item.behaviour);
            });
        }
        //Check we have values
        if (!selectedWorkflowBehaviour.taskCode || !selectedWorkflowBehaviour.behaviour) {
            setBehaviourError(true);
            setTimeout(()=>{setBehaviourError(false);},1000);
            return
        }

        const newWorkflowBehaviour = {behaviour: selectedWorkflowBehaviour.taskCode + '://' + selectedWorkflowBehaviour.behaviour};
        //Add our new behaviour to existing list or as a new list
        const wfBehaviours = questionDefinition.workflowBehaviour
            ? [...questionDefinition.workflowBehaviour, newWorkflowBehaviour]
            : [newWorkflowBehaviour];
        //If not a duplicate wf_task add to our state object
        if (!checkForBehaviourDuplicates(wfBehaviours)) {
            updateBehavior(wfBehaviours);
            setBehaviourError(false);
        } else {
            setBehaviourError(true);
            setTimeout(()=>{setBehaviourError(false);},1000);
        }
    }
    const updateBehavior = (behaviours) => {
        blockNavigation()
        dispatch({
            type: ACTION_TYPES.EDIT_QUESTION_DEFINITION,
            payload: {...questionDefinition, workflowBehaviour:behaviours},
        });
    }

    const getBehaviourTableRows = (questionDefinition?.workflowBehaviour || []).map( b => {
        return (
            <Table.Row key={b.behaviour}>
                <Table.Cell>
                    <Label>{b.behaviour}</Label>
                </Table.Cell>
                <Table.Cell>
                    <Icon style={{cursor: "pointer"}} color="orange" onClick={(e) => {
                        e.stopPropagation();
                        const newBehaviorList = questionDefinition.workflowBehaviour.filter(item => item.behaviour !== b.behaviour);
                        updateBehavior(newBehaviorList);
                        console.log(questionDefinition.workflowBehaviour)
                    }} name='delete' />
                </Table.Cell>
            </Table.Row>
        )
    })

    return (
        <Grid columns={3}>
            <GridRow>
                <Grid.Column width={6}>
                    <Form.Field>
                        <label>
                            {t("QUESTIONNAIRE_DEFINITION_WF_TASK", "Workflow Task")}
                        </label>
                        {workflowTaskOptions.length === 0 && (
                          <p style={{marginTop:'10px'}}>
                            {t("QUESTIONNAIRE_DEFINITION_WF_SELECT_WORKFLOW", "Assign workflow to questionnaire above")}
                          </p>
                        )}
                        {workflowTaskOptions.length !== 0 && (
                          <Dropdown
                            selection
                            fluid
                            error={behaviourError}
                            options={workflowTaskOptions}
                            value={selectedWorkflowBehaviour.task}
                            name={"workflowTask"}
                            onChange={(_e, v) => {
                                const taskOption = v.options.find((opt)=> opt.value === v.value);
                                setSelectedWorkflowBehaviour( previousState => {
                                    return {
                                        task: v.value,
                                        taskCode: taskOption?.text,
                                        behaviour: previousState.behaviour
                                    };
                                })
                            } }
                            disabled={isReadOnly}
                          />)}
                    </Form.Field>
                </Grid.Column>
                <Grid.Column width={6}>
                    <Form.Field>
                        <label>
                            {t("QUESTIONNAIRE_DEFINITION_WF_BEHAVIOUR", "Behavior")}
                        </label>
                        {workflowTaskOptions.length === 0 && (
                            <p style={{marginTop:'10px'}}>
                                {t("QUESTIONNAIRE_DEFINITION_WF_SELECT_WORKFLOW", "Assign workflow to questionnaire above")}
                            </p>
                        )}
                        {workflowTaskOptions.length !== 0 && (
                            <Dropdown
                                fluid
                                selection
                                error={behaviourError}
                                options={workflowBehaviourOptions}
                                value={selectedWorkflowBehaviour.behaviour}
                                name={"behaviour"}
                                onChange={(_e, v) => {
                                    setSelectedWorkflowBehaviour( previousState => {
                                        return {
                                            task: previousState.task,
                                            taskCode: previousState.taskCode,
                                            behaviour: v.value
                                        };
                                    })
                                }}
                                disabled={isReadOnly}
                            />
                        )}
                    </Form.Field>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Form.Field>
                        <label>
                            {t("QUESTIONNAIRE_DEFINITION_WF_ACTION", "Action")}
                        </label>
                        {workflowTaskOptions.length !== 0 && (
                            <Button primary onClick={addWorkflowBehavior}>
                                {t("QUESTIONNAIRE_DEFINITION_WF_BEHAVIOUR_ADD", "Add")}
                            </Button>
                        )}
                    </Form.Field>
                </Grid.Column>
            </GridRow>
            <GridRow>
                <GridColumn width={16}>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t("QUESTIONNAIRE_DEFINITION_WF_BEHAVIOURS", "Workflow behaviours")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("QUESTIONNAIRE_DEFINITION_WF_BEHAVIOURS_REMOVE", "Remove")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {getBehaviourTableRows}
                        </Table.Body>
                    </Table>

                </GridColumn>
            </GridRow>
        </Grid>
    )
}

export default QuestionWorkflowBehaviorEditorComponent;
