import React, { useContext, useEffect, useRef } from "react";
import NavBar from "./NavBar";
import NavLeft from "./NavLeft";
import TrialContext from "../../context/TrialContext";
import UserContext from "../../context/UserContext";
import ConfigContext from "../../context/ConfigContext";
import GroupsContext from "../../context/GroupsContext";
import Footer from "../../footer/Footer";
import { useHistory, useLocation } from "react-router-dom";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import NotificationPermissionBanner from "../notification/NotificationPermissionBanner";
import useForceRerender from "../../hooks/useForceRerender";
import DashboardContext from "../../context/DashboardContext";
import ThemeService from "../../services/ThemeService";
import { Header } from "semantic-ui-react";
import {CollapseNavProvider} from "../../hooks/useCollapseNavInfo";
import QuestionnaireSavedDisplay from "../popovers/QuestionnaireSavedDisplay";
import PermissionsService from "../../services/PermissionsService";

const Dashboard = (props) => {
  const { t } = props;
  const {trial} = useContext(TrialContext);
  const config = useContext(ConfigContext);
  const user = useContext(UserContext);
  const groups = useContext(GroupsContext);

  const forceRerender = useForceRerender();

  const location = useLocation();
  const history = useHistory();
  const isWideView =
    /home|subject|staff|login|activate|reset-password|translations|queries|utils|notifications|search|dashboard-report|attachments|visitDates|screenshots|groups|licenses/.test(
      location.pathname
    );
  const isFrontPage = location.pathname === "/";

  const contentWidth = isWideView || isFrontPage ? {} : { maxWidth: "800px" };
  const contentPush =
    user.isLoggedIn || isWideView || isFrontPage ? {} : { marginLeft: "260px" };

  const isPrimarySuperAdmin = PermissionsService.isPrimarySuperAdmin(user?.profile);
  const showPrimarySuperAdminWarning = !trial?.isTest && isPrimarySuperAdmin;

  const contentRef = useRef();
  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  };
  history.listen(() => {
    scrollToTop();
  });

  useEffect(() => {
    return () => {
      history.listen = null;
    };
  }, [history]);

  return (
      <div>
        <DashboardContext.Provider
            value={{rerenderDashboard: forceRerender, scrollToTop}}
        >
          <CollapseNavProvider>
            <div id="app-container" style={styles.container}>
              <NavBar trial={trial} config={config} user={user}/>
              <div id="app-main" style={styles.main}>
                <NavLeft user={user} trial={trial} groups={groups}/>
                <div
                    style={{...styles.contentContainer, ...ThemeService.getStyle()}}
                    ref={contentRef}
                >
                  <div
                      id="app-content"
                      style={{...styles.content, ...contentWidth, ...contentPush}}
                  >
                    {showPrimarySuperAdminWarning && (
                        <Header
                            as="h1"
                            style={{
                              display: 'inline',
                              paddingLeft: 8,
                              paddingRight: 8,
                              paddingTop: 2,
                              paddingBottom: 2,
                              border: `3px solid #ff0000`,
                              color: "#ff0000",
                              borderRadius: 20,
                              margin: 20,
                              textAlign: 'center'
                            }}
                        >
                          {t("LOGGED_IN_AS_PRIMARY_SUPERADMIN_WARNING", "!!!! YOU ARE LOGGED IN AS A RESTRICTED ACCOUNT !!!!")}
                        </Header>
                    )}
                    <NotificationPermissionBanner/>
                    {props.children}
                  </div>
                  <Footer/>
                </div>
              </div>
            </div>
            <QuestionnaireSavedDisplay />
          </CollapseNavProvider>
        </DashboardContext.Provider>
      </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#f9f9f9",
    display: "flex",
    flex: 1,
    maxHeight: "100vh",
    flexDirection: "column",
    alignItems: "stretch",
    overflowY: "hidden",
  },
  nav: {
    flexGrow: 0,
  },
  main: {
    display: "flex",
    flex: 1,
    alignItems: "stretch",
  },
  contentContainer: {
    width: "100%",
    flexDirection: "column",
    position: "relative",
    height: "100vh",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  content: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    minHeight: "100vh",
    width: "100%",
    paddingTop: 80,
  },
};

const enhance = compose(withTranslation());

export default enhance(Dashboard);
