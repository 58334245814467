import { typeHelper } from "atom5-branching-questionnaire";
import { useEffect, useMemo, useState } from "react";
import AuthService from "../services/AuthService";

const PERMISSION_STATE = {
  UNKNOWN: 'UNKNOWN',
  GRANTED: 'GRANTED',
  DENIED: 'DENIED'
};

const PermissionWrapper = ({
  children,
  options = {}
}) => {
  const {
    profile: passedProfile,
    permissionFunctionDelegate,
    onDenied,
    onUnknown,
    componentForDenied,
    componentForUnknown
  } = options;

  const [permissionState, setPermissionState] = useState(PERMISSION_STATE.UNKNOWN);
  const [profile, setProfile] = useState(passedProfile);

  useEffect(() => {
    const populateProfile = async () => {
      setProfile(await AuthService.getMyProfile());
    }

    if (profile == null) {
      populateProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (profile == null) {
      setPermissionState(PERMISSION_STATE.UNKNOWN);
      return;
    }

    if (permissionFunctionDelegate == null || typeof permissionFunctionDelegate !== "function") {
      console.warn('[PermissionWrapper] permissionFunctionDelegate is null or is not a function')
      setPermissionState(PERMISSION_STATE.UNKNOWN);
      return;
    }

    const hasPerm = typeHelper.parseBool(permissionFunctionDelegate(profile));
    setPermissionState(hasPerm === true ? PERMISSION_STATE.GRANTED : PERMISSION_STATE.DENIED);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, permissionFunctionDelegate])

  const permissionHandlerSummary = useMemo(
    () => {
      const functionFriendlyString = permissionFunctionDelegate?.name ?? permissionFunctionDelegate?.toString() ?? 'Not specified';
      return `Permission handled by: ${functionFriendlyString}`;
    },
    [permissionFunctionDelegate]
  );

  switch (permissionState) {
    case PERMISSION_STATE.GRANTED:
      return children;
    case PERMISSION_STATE.DENIED:
      console.warn(`[PermissionWrapper] DENIED for ${permissionHandlerSummary}`);
      onDenied && onDenied(`DENIED for ${permissionHandlerSummary}`);
      return componentForDenied ?? null;
    default:
      onUnknown && onUnknown(`UNKNOWN for ${permissionHandlerSummary}`);
      return componentForUnknown ?? null;
  }
}

export default PermissionWrapper;
