import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import { Button, Modal } from "semantic-ui-react";
import QuestionnaireDefinitionService from "../../services/admin/QuestionnaireDefinitionService";
import ConfirmButtonWithFeedback from "../dashboard/ConfirmButtonWithFeedback";
import Sortable from 'sortablejs/modular/sortable.core.esm.js'; 

function AdminQuestionOrderModal({
  t,
  isOpen,
  setIsOpen,
  questionnaireDefinition,
  questionDefinitions,
  serverTranslations,
}) {

  // While this will keep the original order, it will override the sequence to be sequential
  // and starting from 0.
  // e.g 2,4,7,8,9 -> 0,1,2,3,4
  const [originalQuestionDefinitions, setOriginalQuestionDefinitions] = useState(
    questionDefinitions
      .sort((a, b) => a.sequence - b.sequence)
      .map((q, i) => {
        return { ...q, sequence: i };
      })
  );
  const [orderedQuestionDefinitions, setOrderedQuestionDefinitions] = useState([...originalQuestionDefinitions]);
  const [showComparison, setShowComparison] = useState(false);

  const listRef = useRef(null);

  useEffect(() => {
    setOriginalQuestionDefinitions(
      questionDefinitions
        .sort((a, b) => a.sequence - b.sequence)
        .map((q, i) => {
          return { ...q, sequence: i };
        })
    );
  }, [questionDefinitions]);

  const getQuestionOrderChangeButton = (
    onComplete,
    buttonText,
    isPositive = true
  ) => {
    return (
      <ConfirmButtonWithFeedback
        fluid
        size="small"
        buttonText={t(buttonText)}
        headerText={t(buttonText)}
        contentText={t(
          "ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_PROMPT",
          "Please give a reason why this is being changed and confirm."
        )}
        confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
        cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
        onConfirm={onComplete}
        placeholderText={t(
          "ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_PLACEHOLDER_TEXT",
          "Reason"
        )}
        mandatoryValidationText={t(
          "ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_VALIDATION_TEXT",
          "Please supply a reason for the change."
        )}
        color={!isPositive ? "negative" : "orange"}
      />
    );
  };

  const reset = () => {
    setOrderedQuestionDefinitions(
      questionDefinitions.map((q, i) => {
        return { ...q, sequence: i };
      })
    );
    setShowComparison(false);
  };

  const save = async (feedback) => {
    await QuestionnaireDefinitionService.editQuestionOrderingForQuestionnaire(
      questionnaireDefinition.id,
      orderedQuestionDefinitions.map((qD) => {
        return { ...qD, questionnaireDefinition };
      }),
      questionDefinitions,
      feedback
    );
    window.location.reload(false);
    setShowComparison(false);
  };

  const handleComparison = () => {
    setShowComparison(true);
  };

  useEffect(() => {
    if (listRef.current) {
      const sortable = Sortable.create(listRef.current, {
        animation: 150,
        onEnd: (evt) => {
          const newQuestions = [...orderedQuestionDefinitions];
          const [movedItem] = newQuestions.splice(evt.oldIndex, 1);
          newQuestions.splice(evt.newIndex, 0, movedItem);

          const updatedOrder = newQuestions.map((item, index) => ({
            ...item,
            sequence: index
          }));
          setOrderedQuestionDefinitions(updatedOrder);
        },
      });

      return () => {
        if (sortable) {
          sortable.destroy();
        }
      };
    }
  }, [orderedQuestionDefinitions, isOpen]);


  return (
    <Modal size={"medium"} open={isOpen} onClose={() => setIsOpen(false)}>
      <Modal.Content scrolling>
        <Modal.Description>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <h4 style={{ paddingRight: "1rem" }}>
              {t("ADMIN_QUESTION_QUESTIONS", "Questions")}
            </h4>
            <Button size="small" onClick={reset}>
              {t("ADMIN_QUESTION_ORDER_RESET", "Reset")}
            </Button>
            {showComparison ?
              <div>
                {getQuestionOrderChangeButton(
                  save,
                  t("ADMIN_QUESTION_ORDER_SAVE", "Save")
                )}
              </div>
            :
              <div>
                <Button primary type="button" onClick={handleComparison}>
                  {t("ORDER_COMPARISON_CONFIRM", "Confirm")}
                </Button>
              </div>
            }
          </div>
          {showComparison ?
            <div>
              <div style={{
                display: 'inline-block',
                width: '50%',
                padding: '0.5em'
              }}>
                <h4 style={{ paddingRight: "1rem" }}>
                  {t("ADMIN_QUESTION_ORIGINAL_ORDER", "Original Order")}
                </h4>
                {originalQuestionDefinitions.map((item) => (
                  <div key={item.id} className="question-row">
                     {`${item.sequence} | ${item.code} | ${item.type}`}
                  </div>
                ))}
              </div>
              <div style={{
                display: 'inline-block',
                width: '50%',
                padding: '0.5em'
              }}>
                <h4 style={{ paddingRight: "1rem" }}>
                  {t("ADMIN_QUESTION_NEW_ORDER", "New Order")}
                </h4>
                {orderedQuestionDefinitions.map((item) => (
                  <div key={item.id} className="question-row">
                     {`${item.sequence} | ${item.code} | ${item.type}`}
                  </div>
                ))}
              </div>
            </div>
          :
            <div ref={listRef}>
              {orderedQuestionDefinitions.map((item, index) => (
                <div key={item.id} className="question-row">
                  <span style={{ float: 'right' }} className="drag-handle">::</span>
                  {`${item.sequence} | ${item.code} | ${item.type} | ${
                    serverTranslations.find(
                      (sT) =>
                        sT.code ===
                        `questionnaire_${questionnaireDefinition.code}_questions_${item.code}_label`
                    )?.translation
                  }`}
                </div>
              ))}
            </div>
          }
          {!questionDefinitions && (
            <p>
              {t(
                "QUESTIONNAIRE_DEFINITION_QUESTIONS_EMPTY",
                "This questionnaire has no questions."
              )}
            </p>
          )}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

export default withTranslation()(AdminQuestionOrderModal);
