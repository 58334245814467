import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Loader, Message, Table } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import Page from "../../components/page/Page";
import QuestionnaireDefinitionService from "../../services/admin/QuestionnaireDefinitionService";
import AdminQuestionnaireDefinitionEditComponent from "../../components/admin/AdminQuestionnaireDefinitionEditComponent";
import { useHistory } from "react-router-dom";
import AdminQuestionOrderModal from "../../components/admin/AdminQuestionOrderModal";
import AdminQuestionDefinitionViewModal from "../../components/admin/AdminQuestionDefinitionViewModal";
import { Link } from "react-router-dom";
import { QUESTION_DEFINITION_CALCULATED_WHEN } from "atom5-branching-questionnaire";
import StaffPermissionService from "../../services/StaffPermissionService";
import { compose } from "redux";
import withPermissionWrapper from "../../security/withPermissionWrapper";

function AdminQuestionnaireDefinitionCreateEditPage({ t }) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [questionnaireDefinitionData, setQuestionnaireDefinitionData] =
    useState(null);
  const [questionnaireDefinition, setQuestionnaireDefinition] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const [selected, setSelected] = useState(null);

  const history = useHistory();
  const params = useParams();
  const { questionnaireDefinitionId } = params;
  const isEditing = !!questionnaireDefinitionId;

  const [isReordering, setIsReordering] = useState(false);
  const [hasQuestionOrderChanged, setHasQuestionOrderChanged] = useState(false);
  useEffect(() => {
    if (hasQuestionOrderChanged) {
      getQuestionnaireDefinitionData();
      setHasQuestionOrderChanged(false);
    }    
  }, [hasQuestionOrderChanged]);

  const getQuestionnaireDefinitionData = async (questionnaireDefinitionId) => {
    const questionnaireDefinitionData =
      await QuestionnaireDefinitionService.getQuestionnaireDefinitionData(
        questionnaireDefinitionId
      );
    if (questionnaireDefinitionData) {
      setQuestionnaireDefinitionData(questionnaireDefinitionData);
      setQuestionnaireDefinition(
        questionnaireDefinitionData.questionnaireDefinition
      );
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const onChangeQuestionnaireDefinition = (_e, data) => {
    let value = data.type === "checkbox" ? data.checked : data.value;
    setQuestionnaireDefinition((staleQuestionnaireDefinition) => {
      return { ...staleQuestionnaireDefinition, [data.name]: value };
    });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitQuestionnaireDefinition = async (feedback) => {
    setIsSubmitting(true);
    let response = [];
    // AT-1149 Only edit implemented for now
    // Please see AT-1150 ticket
    try {
      if (isEditing) {
        response =
          await QuestionnaireDefinitionService.editQuestionnaireDefinition(
            questionnaireDefinition,
            feedback
          );
      }
      setIsSubmitting(false);

      if (!Array.isArray(response)) {
        setError(true);
        return;
      }

      if (response.length > 0) {
        setValidationErrors(response);
        return;
      }
    } catch (error) {
      setIsSubmitting(false); 
      console.error('[AdminQuestionnaireDefinitionCreateEditPage][submitQuestionnaireDefinition] Error editing questionnaire', error);
    }
    history.goBack();
  };

  const createQuestionTable = (questions) => {
    if (!questions) {
      return;
    }

    const headers = [
      {
        title: ["ADMIN_QUESTION_ID", "ID"],
      },
      {
        title: ["ADMIN_QUESTION_CODE", "Code"],
      },
      {
        title: ["ADMIN_QUESTION_TYPE", "Type"],
      },
      {
        title: ["ADMIN_QUESTION_HIDDEN", "Hidden"],
      },
      {
        title: ["ADMIN_QUESTION_CALCULATED", "Calculated"],
      },
      {
        title: ["ADMIN_QUESTION_CONDITIONS", "Conditions"],
      },
    ];

    const headerRow = (
      <Table.Header>
        {headers.map((header) => (
          <Table.HeaderCell>{t(header.title)}</Table.HeaderCell>
        ))}
      </Table.Header>
    );
    const questionRows = questions.map((question) => {
      const calculatedWhen = question.calculatedWhen != null ? question.calculatedWhen : QUESTION_DEFINITION_CALCULATED_WHEN.QUESTIONNAIRE_CREATION_AND_SUBMISSION.value;
      return (
        <Table.Row
          active={question.id === selected}
          onClick={loading ? () => {} : () => setSelected(question.id)}
        >
          <Table.Cell>{question.id}</Table.Cell>
          <Table.Cell>{question.code}</Table.Cell>
          <Table.Cell>{question.type}</Table.Cell>
          <Table.Cell>{JSON.stringify(question.hidden)}</Table.Cell>
          <Table.Cell>
            {JSON.stringify(question.calculated)}
            {question.calculated && (
              <div>
                {t(QUESTION_DEFINITION_CALCULATED_WHEN[calculatedWhen].translationKey, QUESTION_DEFINITION_CALCULATED_WHEN[calculatedWhen].fallbackText)}
              </div>
            )}
          </Table.Cell>
          <Table.Cell>{JSON.stringify(question.conditions)}</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <>
        {headerRow}
        {questionRows}
      </>
    );
  };

  useEffect(() => {
    // AT-1149 Only edit implemented for now
    // Please see AT-1150 ticket
    if (!isEditing) {
      history.goBack();
    }
    if (isEditing) {
      getQuestionnaireDefinitionData(questionnaireDefinitionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireDefinitionId, isEditing]);

  return (
    <Page
      name="Admin Questionnaire Definition Utilities"
      header={t(
        "ADMIN_QUESTIONNAIRE_DEFINITOIN_HEADER",
        "Admin Questionnaire Definitions"
      )}
    >
      {error && (
        <Message
          error
          header={t("GLOBAL_ERROR_TITLE", "Error")}
          content={"Error, if it persist contact support"}
        />
      )}
      {loading && <Loader active={loading} />}
      {!loading &&
        questionnaireDefinition &&
        questionnaireDefinitionData && (
          <>
            <AdminQuestionnaireDefinitionEditComponent
              questionnaireDefinition={questionnaireDefinition}
              serverTranslations={
                questionnaireDefinitionData.serverTranslations
              }
              onChange={onChangeQuestionnaireDefinition}
              onSubmit={submitQuestionnaireDefinition}
              validationErrors={validationErrors}
              isSubmitting={isSubmitting}
            />
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <h4 style={{ paddingRight: "1rem" }}>
                {t("ADMIN_QUESTION_QUESTIONS", "Questions")}
              </h4>
              <Button
                primary
                size="small"
                onClick={() => setIsReordering(true)}
              >
                {t("ADMIN_QUESTION_EDIT_SEQUENCE", "Edit Sequence")}
              </Button>
              <Button
                primary
                size="small"
                as={Link}
                to={
                  "/app/utils/questionnaire-definitions/edit/" +
                  questionnaireDefinitionId +
                  "/new-question"
                }
              >
                {t("ADMIN_QUESTION_CREATE", "Add New Question")}
              </Button>
            </div>
            <Table>
              {createQuestionTable(
                questionnaireDefinitionData.questionDefinitions
              )}
            </Table>
            <AdminQuestionOrderModal
              questionDefinitions={
                questionnaireDefinitionData.questionDefinitions
              }
              questionnaireDefinition={questionnaireDefinition}
              serverTranslations={
                questionnaireDefinitionData.serverTranslations
              }
              isOpen={isReordering}
              setIsOpen={setIsReordering}
              onReordering
            />
            <AdminQuestionDefinitionViewModal
              questionDefinitionId={selected}
              isOpen={selected !== null}
              setIsOpen={() => setSelected(null)}
            />
          </>
        )}
    </Page>
  );
}

const withEnhancements = (options) => compose(
  withPermissionWrapper(options),
  withTranslation()
);
export default withEnhancements({ permissionFunctionDelegate: StaffPermissionService.canManageQuestionnaireDefinitions })(AdminQuestionnaireDefinitionCreateEditPage);
