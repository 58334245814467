import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Loader, Modal, Table } from "semantic-ui-react";
import QuestionnaireDefinitionService from "../../services/admin/QuestionnaireDefinitionService";
import AdminQuestionnaireDefinitionEditComponent from "./AdminQuestionnaireDefinitionEditComponent";
import { QUESTION_DEFINITION_CALCULATED_WHEN } from "atom5-branching-questionnaire";
import StaffPermissionService from "../../services/StaffPermissionService";
import { compose } from "redux";
import withPermissionWrapper from "../../security/withPermissionWrapper";

function AdminQuestionnaireDefinitionViewModal({
  t,
  isOpen,
  setIsOpen,
  questionnaireDefinitionId,
}) {
  const [questionnaireDefinitionData, setQuestionnaireDefinitionData] =
    useState(null);

  const getQuestionnaireDefinitionData = async () => {
    setQuestionnaireDefinitionData(null);
    const questionnaireDefinitionData =
      await QuestionnaireDefinitionService.getQuestionnaireDefinitionData(
        questionnaireDefinitionId
      );
    setQuestionnaireDefinitionData(questionnaireDefinitionData);
  };

  useEffect(() => {
    if (questionnaireDefinitionId) {
      getQuestionnaireDefinitionData(questionnaireDefinitionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireDefinitionId]);

  const createQuestionTable = (questions) => {
    if (!questions) {
      return;
    }

    const headers = [
      {
        title: ["ADMIN_QUESTION_ID", "ID"],
      },
      {
        title: ["ADMIN_QUESTION_CODE", "Code"],
      },
      {
        title: ["ADMIN_QUESTION_TYPE", "Type"],
      },
      {
        title: ["ADMIN_QUESTION_SEQUENCE", "Sequence"],
      },
      {
        title: ["ADMIN_QUESTION_HIDDEN", "Hidden"],
      },
      {
        title: ["ADMIN_QUESTION_CALCULATED", "Calculated"],
      },
      {
        title: ["ADMIN_QUESTION_CONDITIONS", "Conditions"],
      },
    ];

    const headerRow = (
      <Table.Header>
        {headers.map((header) => (
          <Table.HeaderCell>{t(header.title)}</Table.HeaderCell>
        ))}
      </Table.Header>
    );
    const questionRows = questions.map((question) => {
      const calculatedWhen = question.calculatedWhen != null ? question.calculatedWhen : QUESTION_DEFINITION_CALCULATED_WHEN.QUESTIONNAIRE_CREATION_AND_SUBMISSION.value;
      return (
        <Table.Row>
          <Table.Cell>{question.id}</Table.Cell>
          <Table.Cell>{question.code}</Table.Cell>
          <Table.Cell>{question.type}</Table.Cell>
          <Table.Cell>{question.sequence}</Table.Cell>
          <Table.Cell>{JSON.stringify(question.hidden)}</Table.Cell>
          <Table.Cell>
            {JSON.stringify(question.calculated)}
            {question.calculated && (
              <div>
                {t(QUESTION_DEFINITION_CALCULATED_WHEN[calculatedWhen].translationKey, QUESTION_DEFINITION_CALCULATED_WHEN[calculatedWhen].fallbackText)}
              </div>
            )}
          </Table.Cell>
          <Table.Cell>{JSON.stringify(question.conditions)}</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <>
        {headerRow}
        {questionRows}
      </>
    );
  };

  const createCompletedQuestionnaireTable = (questionnaires) => {
    if (!questionnaires) {
      return;
    }

    const headers = [
      {
        title: ["ADMIN_COMPLETED_QUESTIONNAIRE_SUBJECT", "Subject"],
      },
      {
        title: [
          "ADMIN_COMPLETED_QUESTIONNAIRE_OCCURENCE_DATE",
          "Date Completed",
        ],
      },
      {
        title: [
          "ADMIN_COMPLETED_QUESTIONNAIRE_OCCURENCE_NUMBER",
          "Auth Reference",
        ],
      },
    ];

    const headerRow = (
      <Table.Header>
        {headers.map((header) => (
          <Table.HeaderCell>{t(header.title)}</Table.HeaderCell>
        ))}
      </Table.Header>
    );
    const questionnaireRows = questionnaires.map((questionnaire) => (
      <Table.Row>
        <Table.Cell>{questionnaire.subjectId}</Table.Cell>
        <Table.Cell>{questionnaire.completionDate}</Table.Cell>
        <Table.Cell>{questionnaire.questionnaireAuthReference}</Table.Cell>
      </Table.Row>
    ));
    return (
      <>
        {headerRow}
        {questionnaireRows}
      </>
    );
  };

  const hasQuestions =
    questionnaireDefinitionData &&
    questionnaireDefinitionData.questionDefinitions &&
    questionnaireDefinitionData.questionDefinitions.length > 0;

  const hasCompletedQuestionnaires =
    questionnaireDefinitionData &&
    questionnaireDefinitionData.completedQuestionnaires &&
    questionnaireDefinitionData.completedQuestionnaires.length > 0;

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Modal.Content scrolling>
        <Modal.Description>
          {!questionnaireDefinitionData && <Loader />}
          {questionnaireDefinitionData &&
            questionnaireDefinitionData.questionnaireDefinition && (
              <AdminQuestionnaireDefinitionEditComponent
                questionnaireDefinition={
                  questionnaireDefinitionData.questionnaireDefinition
                }
                serverTranslations={
                  questionnaireDefinitionData.serverTranslations
                }
                isReadOnly={true}
              />
            )}

          <h4>{t("ADMIN_QUESTION_QUESTIONS", "Questions")}</h4>
          {hasQuestions && (
            <Table>
              {createQuestionTable(
                questionnaireDefinitionData.questionDefinitions
              )}
            </Table>
          )}
          {!hasQuestions && (
            <p>
              {t(
                "QUESTIONNAIRE_DEFINITION_QUESTIONS_EMPTY",
                "This questionnaire has no questions."
              )}
            </p>
          )}

          <h4>{t("ADMIN_QUESTIONNAIRE_DEFINITIONS_COMPLETED", "Completed")}</h4>
          {hasCompletedQuestionnaires && (
            <Table>
              {createCompletedQuestionnaireTable(
                questionnaireDefinitionData.completedQuestionnaires
              )}
            </Table>
          )}
          {!hasCompletedQuestionnaires && (
            <p>
              {t(
                "QUESTIONNAIRE_DEFINITION_COMPLETED_EMPTY",
                "This questionnaire has not been completed."
              )}
            </p>
          )}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

const withEnhancements = (options) => compose(
  withPermissionWrapper(options),
  withTranslation()
);
export default withEnhancements({ permissionFunctionDelegate: StaffPermissionService.canManageQuestionnaireDefinitions })(AdminQuestionnaireDefinitionViewModal);
