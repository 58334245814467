import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Loader, Message } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import Page from "../../components/page/Page";
import QuestionDefinitionService from "../../services/admin/QuestionDefinitionService";
import AdminQuestionDefinitionEditComponent from "../../components/admin/AdminQuestionDefinitionEditComponent";
import { useHistory } from "react-router-dom";
import { QUESTION_TYPES } from "atom5-branching-questionnaire";
import { compose } from "redux";
import withPermissionWrapper from "../../security/withPermissionWrapper";
import StaffPermissionService from "../../services/StaffPermissionService";

const DEFAULT_BLANK_QUESTION = {
  type: QUESTION_TYPES.PARAGRAPH,
  scoreOffset: 0,
  scoreMultiplier: 1,
};

function AdminQuestionDefinitionCreateEditPage({ t }) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [questionDefinitionData, setQuestionDefinitionData] = useState(null);
  const [questionDefinition, setQuestionDefinition] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);

  const history = useHistory();
  const params = useParams();
  const { questionDefinitionId, questionnaireDefinitionId } = params;
  const isEditing = !!questionDefinitionId;
  const isCreating = !!questionnaireDefinitionId;

  const getQuestionDefinitionData = async (questionDefinitionId) => {
    const questionDefinitionData =
      await QuestionDefinitionService.getQuestionDefinitionData(
        questionDefinitionId
      );
    if (questionDefinitionData) {
      setQuestionDefinitionData(questionDefinitionData);
      setQuestionDefinition(questionDefinitionData.questionDefinition);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const onChangeQuestionDefinition = (_e, data) => {
    let value = data.type === "checkbox" ? data.checked : data.value;
    if (value === "") {
      value = null;
    }
    setQuestionDefinition((staleQuestionDefinition) => {
      return { ...staleQuestionDefinition, [data.name]: value };
    });
  };

const [isSubmitting, setIsSubmitting] = useState(false)

  const submitQuestionDefinition = async (feedback) => {
    setIsSubmitting(true);
    let response = [];
    try {
      if (!isCreating) {
        response = await QuestionDefinitionService.editQuestionDefinition(
          questionDefinition,
          feedback
        );
      }

      if (isCreating) {
        response = await QuestionDefinitionService.createQuestionDefinition(
          questionnaireDefinitionId,
          questionDefinition,
          feedback
        );
      }
      setIsSubmitting(false);

      if (!Array.isArray(response)) {
        setError(true);
        return;
      }

      if (response.length > 0) {
        setValidationErrors(response);
        return;
      }
    } catch (error) {
      setIsSubmitting(false); 
      console.error('[AdminQuestionDefinitionCreateEditPage][submitQuestionDefinition] Error editing questionnaire', error);
    }
    history.goBack();
  };

  useEffect(() => {
    if (isEditing) {
      getQuestionDefinitionData(questionDefinitionId);
    } else {
      setQuestionDefinition(DEFAULT_BLANK_QUESTION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionDefinitionId, isEditing]);

  return (
    <Page
      name="Admin Question Definition Utilities"
      header={t(
        "ADMIN_QUESTIONNAIRE_DEFINITOIN_HEADER",
        "Admin Question Definitions"
      )}
    >
      {error && (
        <Message
          error
          header={t("GLOBAL_ERROR_TITLE", "Error")}
          content={"Error, if it persist contact support"}
        />
      )}
      {loading && <Loader active={loading} />}
      {!loading && questionDefinition && (
        <>
          <AdminQuestionDefinitionEditComponent
            questionDefinition={questionDefinition}
            serverTranslations={
              questionDefinitionData?.serverTranslations || []
            }
            onChange={onChangeQuestionDefinition}
            onSubmit={submitQuestionDefinition}
            validationErrors={validationErrors}
            isCreating={isCreating}
            isSubmitting={isSubmitting}
          />
        </>
      )}
    </Page>
  );
}

const withEnhancements = (options) => compose(
  withPermissionWrapper(options),
  withTranslation()
);
export default withEnhancements({ permissionFunctionDelegate: StaffPermissionService.canManageQuestionnaireDefinitions })(AdminQuestionDefinitionCreateEditPage);
