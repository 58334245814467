import { Prompt } from "react-router-dom"
import { useState, useEffect } from "react"
import { Confirm } from "semantic-ui-react";
import KeyboardHotkeyMappings from "../../../src/utility/KeyboardHotkeyMappings";


function UnsavedChangesPrompt(props) {

    const {
        history,
        shouldBlockNavigation,
        headerText,
        contentText,
        confirmButtonText,
        cancelButtonText

    } = props

    const [ confirmVisible, setConfirmVisible ] = useState(false)
    const [ blockNavigation, setBlockNavigation ] = useState(true)
    const [ nextLocation, setNextLocation ] = useState()

    useEffect(() => {
        if (!blockNavigation) {
            history.push(nextLocation.pathname)
            setBlockNavigation(true)
        }
    }, [blockNavigation, history, nextLocation])

    useEffect(() => {
        KeyboardHotkeyMappings.unAssignKeys();
        return () => {
          KeyboardHotkeyMappings.assignKeys();
        }
      }, []);

    const content = (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "2em",
            padding: "3em"
          }}
        >
            <p>{contentText}</p>
        </div>
    )

    const showConfirm = location => {
        setConfirmVisible(true)
        setNextLocation(location)
    }

    const handleBlockedNavigation = nextLocation => {
        if(blockNavigation) {
            showConfirm(nextLocation)
            return false 
        }
        return true
    }

    const handleCancel = () => {
        setConfirmVisible(false)
    }

    const handleConfirm = () => {
        handleCancel()
        setBlockNavigation(false)
    }

    return (
        <>
            <Prompt when={shouldBlockNavigation} message={handleBlockedNavigation} />
            <Confirm
                open={confirmVisible}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
                header={headerText}
                content={content}
                confirmButton={confirmButtonText}
                cancelButton={{ content: cancelButtonText, primary: true, basic: true }}
            />
        </>
    )
}

export default UnsavedChangesPrompt;