import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  Header,
  Loader,
  Message,
  Segment,
  Table,
} from "semantic-ui-react";
import Page from "../../components/page/Page";
import AdminAttachmentService from "../../services/admin/AdminAttachmentService";
import SubjectService from "../../SubjectService";
import StaffPermissionService from "../../services/StaffPermissionService";
import withPermissionWrapper from "../../security/withPermissionWrapper";
import { compose } from "redux";

function AdminAttachmentRandomisationPage(props) {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState([]);
  const [error, setError] = useState({});

  const { t } = props;

  function parseError(e, fatal) {
    if (e.message) {
      setError({ message: e.message, fatal: fatal });
    } else if (typeof e == "string") {
      setError({ message: e, fatal: fatal });
    } else {
      setError({ message: JSON.stringify(e), fatal: fatal });
    }
  }

  const getQuestionnairesConfiguredForRandomisation = async () => {

    const definitions = await SubjectService.getQuestionnaireDefinitions("pro");
    let questionnairesConfiguredForRandomisation = [];
    definitions.forEach(questionDefinition => {
      questionDefinition.questions.forEach(question => {
        if (question.config && question.config.percentageToRandomise) {
          let entry = {
            questionnaireCode: questionDefinition.code,
            questionCode: question.code,
            attachmentCountThreshold: question.config.attachmentCountThreshold,
            percentageToRandomise: question.config.percentageToRandomise
          };
          questionnairesConfiguredForRandomisation.push(entry);
        }
      });
    });
    return questionnairesConfiguredForRandomisation;
  }

  useEffect(() => {
    const init = async () => {
      try {
          const data = await getQuestionnairesConfiguredForRandomisation();

          const submittedAttachmentsForRandomisation = await AdminAttachmentService.getSubmittedAttachmentsConfiguredForRandomising();

          if (submittedAttachmentsForRandomisation.totals === undefined) {
            console.log('[AdminAttachmentRandomisationPage] No totals found, nothing to display');
          } else {
            const tableRows = [];

            data.forEach(item => {
              let questionnaireCode = item.questionnaireCode;
              let questionCode = item.questionCode;
              const key = questionnaireCode + "_questions_" + questionCode;
              if (submittedAttachmentsForRandomisation.totals[key] === undefined) {
                console.log('[AdminAttachmentRandomisationPage] No matching key found for: '+ key);
              } else {
                let totals = submittedAttachmentsForRandomisation.totals[key];
                let result = [];
                for (let i in totals) {
                  result.push([i, totals[i]]);
                }

                if (result.length === 0) {
                  result.push([t("ADMIN_RANDOMISATION_NO_PROS", "Nothing submitted")]);
                }

                result.sort(function (a, b) {
                  let statusA = a[0].toLowerCase(), statusB = b[0].toLowerCase();
                  if (statusA < statusB) {
                    return -1;
                  }
                  if (statusA > statusB) {
                    return 1;
                  }
                  return 0;
                });

                let prettyTotals = result.map(item => {
                  return (
                      <Grid columns={"equal"}>
                        <Grid.Row>
                          <Grid.Column>{item[0]}</Grid.Column>
                          <Grid.Column>{item[1]}</Grid.Column>
                        </Grid.Row>
                      </Grid>)
                });

                let row = {
                  questionnaireCode: item.questionnaireCode,
                  questionCode: item.questionCode,
                  attachmentCountThreshold: item.attachmentCountThreshold,
                  percentageToRandomise: item.percentageToRandomise,
                  totals: prettyTotals
                };
                tableRows.push(row);
              }
            });
            setResults(tableRows);
          }
      } catch (e) {
        console.log("Error fetching attachments", e);
        parseError(e, true);
      } finally {
        setLoading(false);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const table = () => {
    return (
      <Table selectable columns={12}>
        <Table.Header>
          <Table.Row key={"header"}>
            <Table.HeaderCell width={1}>
              {t("QUESTIONNAIRE_CODE", "Questionnaire Code")}
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              {t("QUESTION_CODE", "Question Code")}
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              {t("ADMIN_RANDOMISATION_THRESHOLD_COUNT", "Threshold count")}
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              {t("ADMIN_RANDOMISATION__PERCENTAGE_TO_RANDOMISE", "Percentage To Randomise")}
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              {t("ADMIN_RANDOMISATION_TOTALS", "Totals")}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {results.map((line) => {
            return (
              <Table.Row key={line.questionnaireCode + "_questionnaire_code_row"}>
                <Table.Cell key={line.questionnaireCode + "_questionnaire_code"} width={4}>
                  {line.questionnaireCode}
                </Table.Cell>
                <Table.Cell key={line.questionCode + "_question_code"} width={2}>
                  {line.questionCode}
                </Table.Cell>
                <Table.Cell key={line.attachmentCountThreshold + "_threshold"} width={2}>
                  {line.attachmentCountThreshold}
                </Table.Cell>
                <Table.Cell key={line.percentageToRandomise + "_percent"} width={2}>
                  {line.percentageToRandomise}
                </Table.Cell>
                <Table.Cell key={line.totals + "_percent"} width={4}>
                  {line.totals}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  return (
    <Page
      name="Admin Attachment Randomisation"
      header={t("ADMIN_ATTACHMENT_RANDOMISATION", "Admin Attachment Randomisation")}
    >
      {error && error.fatal && (
        <Message
          error
          header={t("GLOBAL_ERROR_TITLE", "Error")}
          content={
            "Fatal error, if it persist contact support:" + error.message
          }
        />
      )}
      {loading && <Loader active={loading} />}

      {!loading && (
        <>
          <Segment style={{ overflow: "auto" }}>
            <Header as="h4">{t("ADMIN_QUESTIONS_CONFIGURED_FOR_RANDOMISATION", "Configured For Randomisation")}</Header>
            {table()}
          </Segment>
        </>
      )}
    </Page>
  );
}

const withEnhancements = (options) => compose(
  withPermissionWrapper(options),
  withTranslation()
);
export default withEnhancements({ permissionFunctionDelegate: StaffPermissionService.canViewAttachmentsForRandomising })(AdminAttachmentRandomisationPage);
