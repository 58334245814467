import React, { useContext, useState } from "react";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import 'ace-builds/webpack-resolver';

import Page from "../../components/page/Page";
import { withTranslation } from "react-i18next";
import { Button, Form, Grid } from "semantic-ui-react";
import QuestionnaireDefinitionService from "../../services/admin/QuestionnaireDefinitionService";
import ConfirmButtonWithFeedback from "../../components/dashboard/ConfirmButtonWithFeedback";
import ConfigContext from "../../context/ConfigContext";
import ConfirmButton from "../../components/dashboard/ConfirmButton";
import "./AdminQuestionnaireDefinitionCreatePage.css"
import { useHistory, Link } from "react-router-dom";
import ResponseErrorPanel from "../../components/errors/ResponseErrorPanel";
import StaffPermissionService from "../../services/StaffPermissionService";
import { compose } from "redux";
import withPermissionWrapper from "../../security/withPermissionWrapper";

function AdminQuestionnaireDefinitionCreatePage ({ t }) {

    const [jsonText,setJsonText] = useState("{\n \"code\": \"InsertCodeHere\",\n" +
    "  \"type\": \"PRO|EVENT|DATA|SUBJECT_RECORD|VISIT|CONTENT|CHART\",\n" +
    "  \"label\": {\n" +
    "    \"en_GB\": \"Translation For This Questionnaire\"\n" +
    "  },\n" +
    "  \"config\": {\n" +
    "    \n" +
    "  },\n" +
    "  \"questions\": [\n" +
    "    {\n" +
    "      \"code\": \"SampleParagraphQuestion\",\n" +
    "      \"type\": \"PARAGRAPH\",\n" +
    "      \"label\": {\n" +
    "        \"en_GB\": \"This is an example PARAGRAPH question.\"\n" +
    "      }\n" +
    "    }\n" +
    "  ]\n" +
    "}");
    //const [panelState,setPanelState] = useState()
    const [hasErrors,setHasErrors] = useState(null);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorResponse,setErrorResponse] = useState();

    const config = useContext(ConfigContext);
    let showFeedbackOnSubmit = config.audit?.captureGroupAlternationFeedback;

    const history = useHistory();

    const selectFile = (e) => {
        document.getElementById('file').click();
    }

    function onEditorChange(newValue) {
        setJsonText(newValue);
        resetHasValidated();
      }

      function resetHasValidated(){
        setValidated(false);
        setHasErrors(null);
      }

    const validateJson = async () => {
        let json 
        try {
            json =  JSON.parse(jsonText);
            await QuestionnaireDefinitionService.validateQuestionnaireDefinitionJson(json);
            setHasErrors(false);
            setValidated(true);
            
        } catch (response){
            setHasErrors(true);
            setValidated(false);
            setErrorResponse(response);
        }
    }

    const addNewQuestionnaireDef = async (feedback) => {
        try{
            setLoading(true);
            await validateJson(); //revalidate before submission
            const json =  JSON.parse(jsonText);
            await QuestionnaireDefinitionService.createQuestionnaireDefinition(json,feedback);
            history.push("/app/utils/questionnaire-definitions");
        }catch(response){
            setLoading(false);
            setHasErrors(true);
            setValidated(false);
            setErrorResponse(response);
        }

    }

    const isValid = () => {
        return validated && (!hasErrors) && !loading;
    }

    const showFile = async (e) => {
        e.preventDefault()
        const reader = new FileReader();
        reader.onload = async (e) => { 
          const text = (e.target.result)
          setJsonText(text);
        };
        reader.readAsText(e.target.files[0])
      }
    return (
        <Page
            name="Create New QuestionnaireDefinition"
            header={t("ADMIN_QUESTIONNAIREDEFINITION_HEADER", "New Questionnaire Definition")}
        >
        <Form>
            <Grid >
                <Grid.Row className="new_questionnair_row">
                    <Grid.Column width={14}>
                        <Button onClick={selectFile} primary>
                            {t("ADMIN_QUESTIONNAIREDEFINITION_UPLOADJSON", "Select QuestionnaireDefinition JSON file")}
                        </Button>
                        <input style={{display:"none"}} id="file" type="file" onChange={(e) => showFile(e)}/>
                        <ResponseErrorPanel errorResponse={errorResponse} showErrors={hasErrors} title={t("ADMIN_QUESTIONNAIREDEFINITION_VALIDATIONFAILED", "Validation failed, please expand to show errors")}/>
                        {isValid() && <div className="validated">{t("ADMIN_QUESTIONNAIREDEFINITION_VALIDATIONOK", "Validated, OK!")}</div>}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="new_questionnair_row">
                    <Grid.Column width={14}>
                    <AceEditor
                            mode="json"
                            theme="monokai"
                            value={jsonText}
                            onChange={onEditorChange}
                            onInput={resetHasValidated}
                            showPrintMargin={false}
                            highlightActiveLine={true}
                            name="QD_JSON"
                            width="100%"
                            showGutter={true}
                            editorProps={{ $blockScrolling: true }}
                            setOptions={{
                            enableBasicAutocompletion: false,
                            enableLiveAutocompletion: false,
                            enableSnippets: false
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        { !validated && (hasErrors || hasErrors === null) &&
                        <Button disabled={!(jsonText.length > 0)} primary={jsonText.length > 0} onClick={validateJson} >
                            {t("ADMIN_QUESTIONNAIREDEFINITION_VALIDATE", "Validate JSON")}
                        </Button>
                        }
                        {showFeedbackOnSubmit ? (
                            <ConfirmButtonWithFeedback
                            buttonText={t("GLOBAL_BUTTON_SUBMIT", "Submit")}
                            headerText={t("GLOBAL_BUTTON_SUBMIT", "Submit")}
                            contentText={t(
                                "ADMIN_QUESTIONNAIREDEFINITION_NEW_DETAILED",
                                "Please supply a reason why a new QuestionnareDefinition is being added"
                            )}
                            confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
                            cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                            onConfirm={addNewQuestionnaireDef}
                            placeholderText={t("ADMIN_QUESTIONNAIREDEFINITION_NEW_PLACEHOLDER_TEXT", "Supply reason here.")}
                            mandatoryValidationText={t(
                                "ADMIN_QUESTIONNAIREDEFINITION_NEW_VALIDATION_TEXT",
                                "Please supply a reason for the change."
                            )}
                            disabled={!isValid()}
                            color={isValid() ? "orange" : "grey"}
                            />
                        ) : (
                            <ConfirmButton
                            buttonText={t("GLOBAL_BUTTON_SUBMIT", "Submit")}
                            headerText={t("GLOBAL_BUTTON_SUBMIT", "Submit")}
                            contentText={t(
                                "ADMIN_QUESTIONNAIREDEFINITION_NEW_DETAILED_NO_FEEDBACK",
                                "Please confirm that you want to make this change"
                            )}
                            confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
                            cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                            onConfirm={addNewQuestionnaireDef}
                            disabled={!isValid()}
                            color={isValid() ? "orange" : "grey"}
                            />
                        )}
                        <Button as={Link}
                            to="/app/utils/questionnaire-definitions">
                            {t("ADMIN_QUESTIONNAIREDEFINITION_CANCEL", "Cancel")}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
      </Page>);
}

const withEnhancements = (options) => compose(
    withPermissionWrapper(options),
    withTranslation()
);
export default withEnhancements({ permissionFunctionDelegate: StaffPermissionService.canManageQuestionnaireDefinitions })(AdminQuestionnaireDefinitionCreatePage);
